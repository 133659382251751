import React, { useEffect, useState } from "react";
import "./ourStudioTimings.css";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import StudioTimingModel from "../studioTimingModel/StudioTimingModel";

const OurStudioTimings = (props) => {
  // const { studioName, studioUrl } = useSelector((state) => state.showPopUp);

  //   const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("SuperFast");
  const [activeStudio, setActiveStudio] = useState([]);
  const [studioBatches, setStudioBatches] = useState([]);
  const [editTimeModel, setEditTimeModel] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleSelectTab = (tab) => {
    setActiveTab(tab);
  };

  const functionTimeModel = (item) => {
    setEditTimeModel(true);
    setSelectedItem(item);
  };
  useEffect(() => {
    axios
      .get(
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/studioBatchTiming?studioName=${
          props.studioName == "Emirates Golf Club"
            ? "emirates"
            : props.studioName == "Bur Dubai"
            ? "burDubai"
            : props.studioName == "Motor City"
            ? "motorCity"
            : props.studioName == "Al Qusais" && "alQusais"
        }`
      )
      .then((res) => {
        console.log("Studio Batches", res);
        setStudioBatches(res?.data?.data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.studioName]);

  useEffect(() => {
    if (
      activeTab == "SuperFast" ||
      activeTab == "Rapid" ||
      activeTab == "Red"
    ) {
      setActiveStudio(studioBatches?.red);
    } else if (activeTab == "AY Classic") {
      setActiveStudio(studioBatches?.ayClassic);
    }
  }, [activeTab, studioBatches]);

  return (
    <div className="OurStudioTimings">
      <Modal
        {...props}
        className="timingModal studioTimingModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered

        // backdrop
        // style={{
        //   backgroundColor: "rgba(0, 0, 0, 0.5)",
        // }}
      >
        <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <div className="ProgramTimingContainer">
            <div className="timingHeader studioTimingHeader">
              <h2>{props.studioName}</h2>
            </div>

            <div className="studioTimingTabsContainer">
              <div className="studioTimingTabs">
                <div
                  className={
                    activeTab == "SuperFast"
                      ? "activeProgramTab studioTabContainer"
                      : "studioTabContainer"
                  }
                  onClick={() => handleSelectTab("SuperFast")}
                >
                  <h4>SuperFast</h4>
                </div>

                <div
                  className={
                    activeTab == "Red"
                      ? "activeProgramTab studioTabContainer"
                      : "studioTabContainer"
                  }
                  onClick={() => handleSelectTab("Red")}
                >
                  <h4>Red</h4>
                </div>

                <div
                  className={
                    activeTab == "Rapid"
                      ? "activeProgramTab studioTabContainer"
                      : "studioTabContainer"
                  }
                  onClick={() => handleSelectTab("Rapid")}
                >
                  <h4>Rapid</h4>
                </div>

                <div
                  className={
                    activeTab == "AY Classic"
                      ? "activeProgramTab studioTabContainer"
                      : "studioTabContainer"
                  }
                  onClick={() => handleSelectTab("AY Classic")}
                >
                  <h4>AY Classic</h4>
                </div>
              </div>
            </div>

            <div className="redTimings">
              <h6>{activeTab} | 60 minutes</h6>
              <div className="timeSlotsContainer">
                {activeStudio &&
                  activeStudio.map((item) => (
                    <div className="timeSlots">
                      <span
                        className={
                          item.gender == "ladies" ? "ladiesTime" : "all"
                        }
                      >
                        {item.time} | {item.days}
                        <EditIcon
                          onClick={() => functionTimeModel(item)}
                          style={{
                            cursor: "pointer",
                            color: "black",
                            height: "18px",
                          }}
                        />
                      </span>
                    </div>
                  ))}
              </div>
            </div>

            <div className="note">
              <h5>Note:</h5>
              <ul>
                <li className="ladiesTime">Pink Color Denotes Ladies batch</li>
              </ul>
            </div>
            <StudioTimingModel
              studioName={props.studioName}
              activeTab={activeTab}
              show={editTimeModel}
              onHide={() => setEditTimeModel(false)}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OurStudioTimings;
