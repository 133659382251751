import React, { useState, useEffect } from "react";
import "./qnaDisplay.css";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import Table from "react-bootstrap/Table";
import EditIcon from "@mui/icons-material/Edit";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import DeleteComment from "../../popup/deleteComment/DeleteComment";
import { MillisecondsToIndianTime } from "../../../util/millisecToTime/MilliSecToTime";

const QnaDisplay = () => {
  const [qna, setQna] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [answeredOrNotAnswered, setAnsweredOrNotAnswered] = useState("");
  const [qnaSearch, setQnaSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [selectedQNA, setSelectedQNA] = useState(null);

  const setModalShowButton = (type, qna) => {
    setModalShow(true);
    setModalType(type);
    setSelectedQNA(qna);
  };

  const navigate = useNavigate();

  const moreDetails = (id, item) => {
    navigate(id, {
      state: item,
    });
  };

  const getQNA = () => {
    // setLoading(true);
    axios
      .get(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/qna?isAnswered=${answeredOrNotAnswered}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/qna?isAnswered=${answeredOrNotAnswered}`
      )
      .then((res) => {
        console.log("qna", res.data);
        setQna(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getQNA();
  }, [answeredOrNotAnswered]);

  const updateQNA = (docId, deleteValue, markInappropriate) => {
    axios
      .put(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/QNAData?docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/QNA?docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
      )
      .then((res) => {
        console.log("result", res);
        setModalShow(false);
        getQNA();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div className="QNA">
      <div className="qnaParentContainer">
        <div className="qna_header breadcrumbcontainer mb-5">
          <div className="breadcrumb-nav1">
            <span> QNA</span>
          </div>

          <div className="mb-3">
            {/* <label htmlFor="type" className="form-label">
              Answer
            </label> */}
            <select
              class="form-select"
              aria-label="Default select example"
              value={answeredOrNotAnswered}
              onChange={(e) => {
                //   handleNotificationError();
                setAnsweredOrNotAnswered(e.target.value);
              }}
            >
              <option selected value="" disabled>
                Filter
              </option>
              <option value="true">Answered</option>
              <option value="false">Not Answered</option>
            </select>
          </div>
        </div>

        {/* <div className="faqSearchInput">
          <input type="text" placeholder="Search what you are looking for" />
          <img
            className="searchIcon"
            src="/img/search.png"
            alt=""
            style={{ width: "20px" }}
          />
        </div> */}

        <div className="search-bar">
          <input
            type="text"
            placeholder="Search what you are looking for"
            className="search-input"
            value={qnaSearch}
            onChange={(e) => setQnaSearch(e.target.value)}
          />
          {/* <button className="search-button">
            <img
              src="/img/search.png"
              alt="Search Icon"
              className="search-icon"
            />
          </button> */}
        </div>

        <div className="table-container">
          <table className="table">
            <thead className="table-header">
              <tr>
                <th className="table-cell">Created At</th>
                <th className="table-cell">Question</th>
                <th className="table-cell">Created By</th>
                <th className="table-cell">Answer</th>
                <th className="table-cell">Details</th>
                <th className="table-cell">Delete</th>
                <th className="table-cell">Inappropriate</th>
                {/* <th className="table-cell">id</th> */}
              </tr>
            </thead>
            <tbody>
              {qna
                .filter((item) => {
                  if (qnaSearch === "") {
                    return item;
                  } else if (
                    item?.qna?.content
                      .toLowerCase()
                      .indexOf(qnaSearch.toLowerCase()) > -1 ||
                    item?.qna?.senderName
                      .toLowerCase()
                      .indexOf(qnaSearch.toLowerCase()) > -1
                  ) {
                    return item;
                  }
                })
                .map((item, index) => (
                  <tr
                    className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
                    key={index}
                    style={{ height: "50px" }}
                  >
                    {/* <td className="table-cell">{index + 1}</td> */}
                    <td className="table-cell">
                      {MillisecondsToIndianTime(item?.qna?.timestamp)}
                    </td>
                    <td className="table-cell" style={{ width: "36rem" }}>
                      {item?.qna?.content}
                    </td>
                    <td className="table-cell">{item?.qna?.senderName}</td>

                    <td className="table-cell">
                      {item?.qna?.answered ? (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/img/correct.png"
                          alt="image"
                          width={"20px"}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/img/sand-clock.png"
                          alt="image"
                          width={"20px"}
                        />
                      )}
                    </td>

                    <td className="table-cell">
                      {/* <Link to={`/qnaDetails/${item.docId}`}> */}
                      {/* <EditIcon
                      onClick={() =>
                        moreDetails(`/qnaDetails/${item.docId}`, item)
                      }
                      style={{ cursor: "pointer", color: "black" }}
                    /> */}
                      {/* </Link> */}
                      <img
                        onClick={() =>
                          moreDetails(`/qnaDetails/${item.docId}`, item)
                        }
                        style={{ cursor: "pointer" }}
                        src="/img/eye.png"
                        alt="image"
                        width={"30px"}
                        title="QNA Details"
                        // height={"20px"}
                      />
                    </td>
                    <td className="table-cell">
                      {item?.qna?.isDelete ? (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/img/restore.png"
                          alt="image"
                          width={"20px"}
                          title="Restore QNA"
                          onClick={() => {
                            setModalShowButton("restoreQNA", item?.qna);
                          }}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/img/delete.png"
                          title="Delete QNA"
                          alt="image"
                          width={"20px"}
                          onClick={() => {
                            setModalShowButton("deleteQNA", item?.qna);
                          }}
                        />
                      )}

                      {modalType === "deleteQNA" && selectedQNA && (
                        <DeleteComment
                          actionType="deleteQNA"
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          comment={selectedQNA}
                          updateFunction={updateQNA}
                          updateParams={[selectedQNA.docId, true, null]}
                        />
                      )}

                      {modalType === "restoreQNA" && selectedQNA && (
                        <DeleteComment
                          actionType="restoreQNA"
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          comment={selectedQNA}
                          updateFunction={updateQNA}
                          updateParams={[selectedQNA.docId, false, null]}
                        />
                      )}
                    </td>
                    <td className="table-cell">
                      {item?.qna?.markInappropriate ? (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/img/checked.png"
                          title="Mark Appropriate QNA"
                          alt="image"
                          width={"20px"}
                          onClick={() => {
                            setModalShowButton("appropriateQNA", item?.qna);
                          }}
                        />
                      ) : (
                        <img
                          style={{ cursor: "pointer" }}
                          src="/img/report.png"
                          title="Mark Inappropriate QNA"
                          alt="image"
                          width={"20px"}
                          onClick={() => {
                            setModalShowButton("inAppropriateQNA", item?.qna);
                          }}
                        />
                      )}
                      {modalType === "appropriateQNA" && selectedQNA && (
                        <DeleteComment
                          actionType="appropriateQNA"
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          comment={selectedQNA}
                          updateFunction={updateQNA}
                          updateParams={[selectedQNA.docId, null, false]}
                        />
                      )}
                      {modalType === "inAppropriateQNA" && selectedQNA && (
                        <DeleteComment
                          actionType="inAppropriateQNA"
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          comment={selectedQNA}
                          updateFunction={updateQNA}
                          updateParams={[selectedQNA.docId, null, true]}
                        />
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        {/* <Table striped hover>
          <thead>
            <tr>
              <th>id</th>
              <th>Question</th>
              <th>Created By</th>
            </tr>
          </thead>
          <tbody>
            {qna.map((item, index) => {
              console.log("item", item);
              return (
                <>
                  <tr>
                    <td>{index + 1}</td>
                    <td>{item?.qna?.content}</td>
                    <td>{item?.qna?.senderName}</td>
                    <td>
                    <div className="quickReadsSwitch">
                      <label class="switch">
                        <div
                          className="checkbox_container"
                          onChange={() => {
                            onChangeChecked(item);
                          }}
                        >
                          <input
                            type="checkbox"
                            // onChange={() => {
                            //   onChangeChecked(item);
                            // }}
                          />
                          <span
                            class="slider round"
                            style={
                              item?.isActive == true
                                ? { backgroundColor: "green" }
                                : { backgroundColor: "red" }
                            }
                          ></span>
                          <div
                            className="round_circle"
                            style={
                              item?.isActive == true
                                ? {
                                    right: "4px",
                                    transition: "all 4s ease-in-out 1s",
                                  }
                                : {
                                    left: "4px",
                                    transition: "all 4s ease-in-out 1s",
                                  }
                            }
                          ></div>
                        </div>
                      </label>
                      <span style={{ color: "black" }}>
                        {item?.isActive == true ? "is Active" : "not Active"}
                      </span>

                    </div>
                  </td>
                    <td>
                    <Link to={`/updateQuickRead/${item.id}`}>
                      <EditIcon style={{ cursor: "pointer", color: "black" }} />
                    </Link>
                  </td>
                  </tr>
                </>
              );
            })}
          </tbody>
        </Table> */}
      </div>
    </div>
  );
};

export default QnaDisplay;
