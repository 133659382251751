import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import "./inAppPurchasePopup.css";

const InAppPurchasePopup = (props) => {
  const [packageId, setPackageId] = useState(null);
  const [packageDetails, setPackageDetails] = useState(null);
  const [iosProductId, setIOSProductId] = useState(null);
  const [androidProductId, setAndroidProductId] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const createSubscriptionPackage = async (e) => {
    e.preventDefault();

    if (!packageId) {
      setErrorMessage("Enter all details");
      return;
    }
    const obj = {
      docId: "subscription",
      packageId: parseInt(packageId),
      iosProductId,
      androidProductId,
    };
    console.log("obj", obj);
    // setDisableLoginButton(true);
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/create/programInAppMapping/subscription",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/create/programInAppMapping/subscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        if (result.status === "success") {
          setPackageDetails(null);
          setPackageId(null);
          setAndroidProductId(null);
          setIOSProductId(null);
          props.setModalShow(false);
          props.subscriptionPackageData();
        }
        setErrorMessage("PackageId already exists");
        // setDisableLoginButton(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const checkSubscriptionPackage = async (e) => {
    e.preventDefault();

    if (!packageId) {
      setErrorMessage("Enter all details");
      return;
    }
    const obj = {
      docId: "subscription",
      packageId: parseInt(packageId),
      iosProductId,
      androidProductId,
    };
    console.log("obj", obj);
    // setDisableLoginButton(true);
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/create/checkprogramInAppMapping/subscription",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/create/checkprogramInAppMapping/subscription",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        if (result.status === "success") {
          getPakageDetails();
        } else {
          setErrorMessage("PackageId already exists");
        }
        // setDisableLoginButton(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getPakageDetails = () => {
    if (!packageId) {
      setErrorMessage("Enter all details");
      return;
    }
    axios
      .get(
        `https://artisticyogaikriya.com/rest/packages/app/${packageId}/detail.html`
      )
      .then((res) => {
        console.log("res", res.data.data);
        setPackageDetails(res.data.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleNotificationError = () => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const clearData = () => {
    setPackageId(null);
    setPackageDetails(null);
    props.onHide(false);
    handleNotificationError();
  };
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={clearData}
      //   backdrop
      //   style={{
      //     backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.type === "subscription"
            ? "Record New Subscription"
            : "Record New Program"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <>
          <div className="formContainer">
            <form>
              <div className="mb-3">
                <label htmlFor="subtitle" className="form-label">
                  Package id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="subtitle"
                  aria-describedby="subtitle"
                  value={packageId}
                  onChange={(e) => {
                    handleNotificationError();
                    setPackageId(e.target.value);
                  }}
                />
              </div>
              <p className="Login_error">{errorMessage}</p>
              <Button onClick={checkSubscriptionPackage}>Fetch Details</Button>
            </form>
            {packageDetails && (
              <div className="paymentDetails">
                <div className="programPaymentDetails">
                  {/* <h4>Package details</h4> */}

                  <div className="paymentDetailsContainer">
                    <div className="paymentAmount">
                      <h4>Name</h4>

                      <h4 className="name_h4">{packageDetails?.name}</h4>
                    </div>

                    <div
                      className="paymentAmount"
                      style={{ margin: "13px 0px" }}
                    >
                      <h4>Price</h4>

                      <h4>{packageDetails?.price}</h4>
                    </div>

                    <div className="paymentAmount">
                      <h4>validity</h4>
                      <h4>
                        {packageDetails?.validity}{" "}
                        {packageDetails?.validityUnit}
                      </h4>
                    </div>

                    {/* <div className="paymentDetailsDivider"></div> */}
                  </div>
                </div>
              </div>
            )}
          </div>

          {packageDetails && (
            <>
              <div className="paymentGateWay">
                <div className="paymentGateWay_card">
                  <div className="paymentGateWay_content">
                    <h3>IOS</h3>
                    <label className="form-label">Payment Gateway</label>
                    <select>
                      <option selected disabled>
                        Select Payment Gateway
                      </option>
                      <option>Revenuecat</option>
                    </select>

                    <div style={{ marginBottom: "2px" }}>
                      <label htmlFor="subtitle" className="form-label">
                        IOS Product Id
                      </label>
                      <input
                        // placeholder="Ex. artyoga_538_59_1m"
                        type="text"
                        className="form-control"
                        id="subtitle"
                        aria-describedby="subtitle"
                        value={iosProductId}
                        onChange={(e) => {
                          handleNotificationError();
                          setIOSProductId(e.target.value);
                        }}
                      />
                    </div>
                    <span style={{ fontSize: "13px" }}>
                      Ex. artyoga_538_59_1m
                    </span>
                  </div>
                </div>

                <div className="paymentGateWay_card">
                  <div className="paymentGateWay_content">
                    <h3>Android</h3>
                    <label className="form-label">Payment Gateway</label>
                    <select>
                      <option selected disabled>
                        Select Payment Gateway
                      </option>
                      <option>Revenuecat</option>
                    </select>

                    <div style={{ marginBottom: "2px" }}>
                      <label htmlFor="subtitle" className="form-label">
                        Android Product Id
                      </label>
                      <input
                        // placeholder="Ex. artyoga_538_59_1m:base-1month"
                        type="text"
                        className="form-control"
                        id="subtitle"
                        aria-describedby="subtitle"
                        value={androidProductId}
                        onChange={(e) => {
                          handleNotificationError();
                          setAndroidProductId(e.target.value);
                        }}
                      />
                    </div>
                    <span style={{ fontSize: "13px" }}>
                      Ex. artyoga_538_59_1m:base-1month
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "3rem",
                }}
              >
                <p className="Login_error">{errorMessage}</p>
                <Button onClick={createSubscriptionPackage}>Submit</Button>
              </div>
            </>
          )}
        </>
      </Modal.Body>
      {/* <Modal.Footer>
        <Button onClick={getPakageDetails}>Fetch Data</Button>
      </Modal.Footer> */}
    </Modal>
  );
};

export default InAppPurchasePopup;
