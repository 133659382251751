import React, { useEffect, useState, useRef } from "react";
// import Slider from "react-slick";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/css";
// import "swiper/css/navigation";
// import { Navigation } from "swiper";
import "./ourStudios.css";
import Image from "./Image";
import OurStudioTimings from "./ourStudioTimings/OurStudioTimings";
// import { setShowVideo, setStudioName, setStudioTimingPopUp, setStudioUrl } from "../../ReduxToolkit/PopUpSlice";
// import { useDispatch } from "react-redux";

const OurStudios = ({ videoInfo, homevidRef }) => {
  const [studioTimingModal, setStudioTimingModal] = useState(false);
  const [studioName, setStudioName] = useState("");

  //   const dispatch = useDispatch();
  //   const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  const functionStudioTimingModal = (item) => {
    setStudioTimingModal(true);
    setStudioName(item.name);
  };
  const programs = [
    {
      image: "/assets/OurStudios/burDubai.png",
      webpImage: "/assets/OurStudios/burDubai.webp",
      name: "Bur Dubai",
      location: "https://goo.gl/maps/J7j8KsERmxDpD5U38",
      address: "Four Point Sheraton, Burdubai",
      playIcon: "/assets/playIcon.svg",
      url: "https://btay-videos.s3.ap-southeast-1.amazonaws.com/testimonialVideos/studio/Sneha+lokhandwala.mp4&ngsw-bypass=true",
    },
    {
      image: "/assets/OurStudios/motorCity.png",
      webpImage: "/assets/OurStudios/motorCity.webp",
      name: "Motor City",
      location: "https://goo.gl/maps/3z3vVhDSWQ2L1vQ5A?coh=178572&entry=tt",
      address: "Office no 411, Detroit House",
      playIcon: "/assets/playIcon.svg",
      url: "https://btay-videos.s3.ap-southeast-1.amazonaws.com/testimonialVideos/studio/Sneha+lokhandwala.mp4&ngsw-bypass=true",
    },
    {
      image: "/assets/OurStudios/alQuasis.png",
      webpImage: "/assets/OurStudios/alQuasis.webp",
      name: "Al Qusais",
      location: "https://goo.gl/maps/hjdvEdBrcZMWb6o58?coh=178572&entry=tt",
      address: "Time Grand hotel,congress 3, Damascus Street",
      playIcon: "/assets/playIcon.svg",
      url: "https://btay-videos.s3.ap-southeast-1.amazonaws.com/testimonialVideos/studio/Sneha+lokhandwala.mp4&ngsw-bypass=true",
    },
    {
      image: "/assets/OurStudios/emirates.png",
      webpImage: "/assets/OurStudios/emirates.webp",
      name: "Emirates Golf Club",
      location: "https://goo.gl/maps/Q9nHY7XRiFtJeJAL9?coh=178572&entry=tt",
      address: "Below Fitlab Gym,Inside Emirates Golf Club",
      playIcon: "/assets/playIcon.svg",
      url: "https://btay-videos.s3.ap-southeast-1.amazonaws.com/testimonialVideos/studio/Sneha+lokhandwala.mp4&ngsw-bypass=true",
    },
  ];

  //   const handleStudioTimings = (studioName, studioUrl) => {
  //     dispatch(setStudioName(studioName));
  //     dispatch(setStudioUrl(studioUrl));
  //     dispatch(setStudioTimingPopUp(true));
  //     logEvent(analytics, `Clicked on Studio timings for ${studioName}`);
  //   };

  //   const callback = (entries) => {
  //     const [entry] = entries;
  //     setIsVisible(entry.isIntersecting);
  //     // console.log(entry.isIntersecting)
  //     if (entry.isIntersecting == true) {
  //       homevidRef.current.play();
  //     }
  //   };

  const option = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  //   useEffect(() => {
  //     const observer = new IntersectionObserver(callback, option);
  //     if (containerRef.current) {
  //       observer.observe(containerRef.current);
  //     }

  //     return () => {
  //       if (containerRef.current) {
  //         observer.unobserve(containerRef.current);
  //       }
  //     };
  //   }, [containerRef, option]);

  return (
    <div className="OurStudios hidden">
      <div className="ourStudiosHeader">
        <h1>Our Studios</h1>
        {/* <h4>
          We offer four key locations where you can practice and learn Artistic
          Yoga from our most experienced & motivated teachers
        </h4> */}
      </div>

      <div className="ourStudiosContainer">
        {programs.map((item, ind) => (
          <div className="studioCard">
            <div className="studioImgContainer">
              <Image
                width={"100%"}
                height={"auto"}
                className="studioImg"
                webpSrc={item.webpImage}
                otherSrc={item.image}
              />
              <div className="studioDetails">
                <div className="studio-name-container">
                  <h3>{item.name}</h3>
                </div>

                <div
                  className="address"
                  onClick={() => functionStudioTimingModal(item)}
                >
                  <img src="/assets/studioCalendar.svg" alt="" width={"16px"} />
                  <p>View schedule</p>
                </div>
              </div>
            </div>
          </div>
        ))}
        <OurStudioTimings
          show={studioTimingModal}
          onHide={() => setStudioTimingModal(false)}
          studioName={studioName}
        />
      </div>
    </div>
  );
};

export default OurStudios;
