import React, { useState, useEffect } from "react";
import axios from "axios";
import { MillisecondsToIndianTime } from "../../../util/millisecToTime/MilliSecToTime";
import QuickReadAndVideoDetails from "../../popup/quickreadAndVideoDetails/QuickReadAndVideoDetails";
import DeleteComment from "../../popup/deleteComment/DeleteComment";

const BlogsComment = ({ data, getBlogsAndVideosCommentsAdmin }) => {
  const [quickRead, setQuickRead] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");

  const [modalShowQuickReadDetail, setModalShowQuickReadDetail] =
    useState(false);
  const [modalTypeQuickRead, setModalTypeQuickRead] = useState("");
  const [selectedComment, setSelectedComment] = useState(null); // Store the selected comment for the modal

  const [commentData, setCommentData] = useState([]);

  const ModalFunction = (type) => {
    setModalShowQuickReadDetail(true);
    setModalTypeQuickRead(type);
  };

  const setModalShowButton = (type, comment) => {
    console.log("comment", comment);
    setModalShow(true);
    setModalType(type);
    setSelectedComment(comment);
  };

  const getQuickread = () => {
    axios
      .get(
        `https://qrs.btartisticyoga.com/magicFood/getQuickReadDetails?Id=${
          data.parentDocId.split(["_"])[0]
        }`
      )
      .then((res) => {
        console.log("quickReadData", res.data.QuickReads);
        setQuickRead(res.data.QuickReads);

        // setSlideImgs(res.data.QuickReads.imageAssets[0]["Slide 1.jpg"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBlogsAndVideosComments = () => {
    axios
      .get(
        // `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosComments?docId=8_b&userId=4194`
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/blogsAndVideosComments?docId=${id}_b&userId=4194`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosComments?docId=${
          data.parentDocId.split(["_"])[0]
        }_b&userId=4194`
      )
      .then((res) => {
        // console.log("commentData", res.data);
        setCommentData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getBlogsAndVideosComments();
  }, []);

  useEffect(() => {
    getQuickread();
  }, []);

  const updateBlogsAndVideosComments = (
    parentDocId,
    docId,
    deleteValue,
    markInappropriate
  ) => {
    console.log("data", parentDocId, docId, deleteValue, markInappropriate);
    axios
      .put(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/blogsAndVideosComments?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/blogsAndVideosComments?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
      )
      .then((res) => {
        console.log("result", res);
        setModalShow(false);
        getBlogsAndVideosComments();
        getBlogsAndVideosCommentsAdmin();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <tbody>
        <tr
          className={`table-row ${1 % 2 === 0 ? "even" : "odd"}`}
          key={1}
          style={{ height: "50px" }}
        >
          <td className="table-cell">{quickRead?.id}</td>
          <td className="table-cell">{data?.message}</td>
          <td className="table-cell">{data?.userName}</td>
          <td className="table-cell">{quickRead?.name}</td>
          <td className="table-cell">
            {MillisecondsToIndianTime(data?.timestamp)}
          </td>

          <td className="table-cell">
            <img
              onClick={() => ModalFunction("quickRead")}
              style={{ cursor: "pointer" }}
              src="/img/eye.png"
              alt="image"
              width={"30px"}
              // title="QNA Details"
              // height={"20px"}
            />
          </td>

          <td className="table-cell">
            <div>
              {data?.isDelete ? (
                <>
                  <img
                    style={{ cursor: "pointer" }}
                    src="/img/restore.png"
                    title="Restore Comment"
                    alt="image"
                    width={"20px"}
                    onClick={() => {
                      setModalShowButton("restore", data);
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    style={{ cursor: "pointer" }}
                    src="/img/delete.png"
                    alt="image"
                    title="Delete Comment"
                    width={"15px"}
                    onClick={() => {
                      setModalShowButton("delete", data);
                    }}
                  />
                </>
              )}
              {modalType === "delete" && selectedComment && (
                <DeleteComment
                  actionType="delete"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    true,
                    null,
                  ]}
                />
              )}

              {modalType === "restore" && selectedComment && (
                <DeleteComment
                  actionType="restore"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    false,
                    null,
                  ]}
                />
              )}
            </div>
          </td>

          <td>
            {" "}
            <div>
              {data?.markInappropriate ? (
                <img
                  style={{ cursor: "pointer" }}
                  src="/img/checked.png"
                  // title="Mark Appropriate QNA"
                  alt="image"
                  width={"20px"}
                  onClick={() => {
                    setModalShowButton("appropriate", data);
                  }}
                />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  src="/img/report.png"
                  // title="Mark Inappropriate QNA"
                  alt="image"
                  width={"20px"}
                  onClick={() => {
                    setModalShowButton("inAppropriate", data);
                  }}
                />
              )}
              {modalType === "appropriate" && selectedComment && (
                <DeleteComment
                  actionType="appropriate"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    null,
                    false,
                  ]}
                />
              )}
              {modalType === "inAppropriate" && selectedComment && (
                <DeleteComment
                  actionType="inAppropriate"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    null,
                    true,
                  ]}
                />
              )}
            </div>
          </td>
        </tr>
      </tbody>
      <QuickReadAndVideoDetails
        activeType={modalTypeQuickRead}
        state={quickRead}
        show={modalShowQuickReadDetail}
        commentData={commentData}
        getBlogsAndVideosComments={getBlogsAndVideosComments}
        onHide={() => setModalShowQuickReadDetail(false)}
      />
    </>
  );
};

export default BlogsComment;
