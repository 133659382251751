import React, { useState, useEffect } from "react";
import Loader from "react-loader-spinner";
import axios from "axios";
import InAppPurchasePopup from "../../popup/inAppPurchasePopup/InAppPurchasePopup";

const Subsciption = () => {
  const [Loading, setLoading] = useState(true);
  const [subscriptionList, setSubscriptionList] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  const subscriptionPackageData = () => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/programInAppMapping/subscription"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/programInAppMapping/subscription"
      )
      .then((res) => {
        console.log("res", res.data.subscription);
        setSubscriptionList(res.data.subscription);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    subscriptionPackageData();
  }, []);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }
  return (
    <div className="ay__programBatchesDisplay ">
      <div className="ay__programBatchesDisplay-header">
        <span>Packages</span>

        <button
          onClick={() => {
            setModalShow(true);
          }}
          className="addQuickRead"
        >
          New Subscription
        </button>
      </div>

      <div className="table-container">
        <table className="table">
          <thead className="table-header">
            <tr>
              <th className="table-cell">Pakage Name</th>
              <th className="table-cell">Duration</th>
              <th className="table-cell">Price</th>
              <th className="table-cell">Package Id</th>
              <th className="table-cell">Action</th>
              {/* <th className="table-cell">Edit</th> */}
            </tr>
          </thead>
          <tbody>
            {subscriptionList.map((item, index) => (
              <tr
                className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
                key={index}
                style={{ height: "50px" }}
              >
                <td className="table-cell">{item?.name}</td>

                <td className="table-cell">
                  {item?.validity} {item?.validityUnit}
                </td>
                <td className="table-cell">{item?.price}</td>
                <td className="table-cell">{item?.packageId}</td>

                <td className="table-cell">
                  <div className="quickReadsSwitch batchesSwitchs">
                    <label class="switch">
                      <div
                        className="checkbox_container"
                        // onChange={() => {
                        //   onChangeChecked(teacher);
                        // }}
                      >
                        <input type="checkbox" />
                        <span
                          class="slider round"
                          style={
                            item?.isActive === true
                              ? { backgroundColor: "green" }
                              : { backgroundColor: "red" }
                          }
                        ></span>
                        <div
                          className="round_circle"
                          style={
                            item?.isActive === true
                              ? { right: "4px", transition: "2s ease-in" }
                              : { left: "4px", transition: "2s ease-in" }
                          }
                        ></div>
                      </div>
                    </label>
                    <span style={{ color: "black" }}>
                      {true ? "is Active" : "not Active"}
                    </span>
                  </div>
                </td>

                {/* <td>
                  <EditIcon
                    onClick={() => {
                      setModalShowButton("update", teacher);
                    }}
                    style={{ cursor: "pointer", color: "black" }}
                  />
                </td> */}
              </tr>
            ))}
          </tbody>
        </table>

        <InAppPurchasePopup
          type="subscription"
          show={modalShow}
          onHide={() => setModalShow(false)}
          setModalShow={setModalShow}
          subscriptionPackageData={subscriptionPackageData}
          // comment={selectedComment}
          //   updateFunction={updateTeacherNew}
          //   updateParams={[teacher, teacher.docId]}
        />
      </div>
    </div>
  );
};

export default Subsciption;
