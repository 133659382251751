import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function CreateAppProgram() {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [disableLoginButton, setDisableLoginButton] = useState(false);

  const [programId, setProgramId] = useState("");
  const [customPackageId, setCustomPackageId] = useState("");
  const [packageId, setPackageId] = useState("");
  const [validity, setValidity] = useState("");

  const [videoUrl, setVideoUrl] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [token, setToken] = useState("");

  const getAccessToken = async () => {
    let values = new URLSearchParams({
      username: "91-9953822945",
      password: "Saman@123",
      grant_type: "password",
    });
    console.log(values);
    let response;

    try {
      response = await axios.post(
        "https://artisticyogaikriya.com/ikriya-user-service/oauth/token",
        values.toString(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic JDJ5JDEyJDFRbnVCQmZiOWdMSlUvcU9OY21vcC5qSkJxVU5iN1NEVWNNYmVtdVdjaTZ4S1RsVXljZEU2OiQyeSQxMiRJYmlUbVFhZVF5ZURQTjBCOGRWVm8uYjJ2US95MmxXNk5QZ0wwM1RQT1M5VGhMNkEuRmQwaQ==",
          },
        }
      );
    } catch (e) {
      const {
        response: { data },
      } = e;

      if (data?.code === "UNAUTHORIZED") {
        console.log("UNAUTHORIZED");
      } else {
        console.log("Unknown server error");
      }
      return;
    }
    let acc_token = response.data.access_token;
    let token = response;
    console.log("token", acc_token, token);
    setToken(acc_token);
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const submitAppProgram = async (e) => {
    e.preventDefault();

    if (
      !programId ||
      !customPackageId ||
      !packageId ||
      !videoUrl ||
      !imageUrl
    ) {
      setErrorMessage("Enter all details");
      return;
    }

    const obj = {
      docId: "programs",
      token: token,
      programId: programId,
      customPackageId: customPackageId,
      packageId: packageId,
      validity: validity,
      imageUrl: imageUrl,
      videoUrl: videoUrl,
    };
    setDisableLoginButton(true);
    // await fetch("http://3.110.120.103:9007/magicFood/createQuickReads/", {
    await fetch(
      "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/create/programInAppMapping/programs",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "success") {
          console.log("result", result);
          setProgramId("");
          setCustomPackageId("");
          setPackageId("");
          setValidity("");
          setVideoUrl("");
          setImageUrl("");
          navigate("/home");
          setDisableLoginButton(false);
        } else {
          setErrorMessage(result.response);
        }

        // navigate("/home");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleNotificationError = () => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  return (
    <>
      <div className="Apps">
        <div className="breadcrumbcontainer">
          <div className="breadcrumb-nav">
            <img src="/assets/rightchevron.png" alt="" />
            <span>Post App Program</span>
          </div>
        </div>

        <form>
          <div className="mb-3">
            <label htmlFor="Name" className="form-label">
              Program Id
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              aria-describedby="name"
              value={programId}
              onChange={(e) => {
                handleNotificationError();
                setProgramId(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="subtitle" className="form-label">
              Custom Package Id
            </label>
            <input
              type="text"
              className="form-control"
              id="subtitle"
              aria-describedby="subtitle"
              value={customPackageId}
              onChange={(e) => {
                handleNotificationError();
                setCustomPackageId(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              Package Id
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              aria-describedby="category"
              value={packageId}
              onChange={(e) => {
                handleNotificationError();
                setPackageId(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              Validity
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              aria-describedby="category"
              value={validity}
              onChange={(e) => {
                handleNotificationError();
                setValidity(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              Image Url
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              aria-describedby="category"
              value={imageUrl}
              onChange={(e) => {
                handleNotificationError();
                setImageUrl(e.target.value);
              }}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              Video Url
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              aria-describedby="category"
              value={videoUrl}
              onChange={(e) => {
                handleNotificationError();
                setVideoUrl(e.target.value);
              }}
            />
          </div>

          <p className="Login_error">{errorMessage}</p>

          <button
            disabled={disableLoginButton}
            type="submit"
            className="btn btn-primary"
            onClick={submitAppProgram}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}

export default CreateAppProgram;
