import React, { useState } from "react";
import "./programDetailCard.css";
import { Link } from "react-router-dom";
import ReactModel from "../../popup/reactModel/ReactModel";

const ProgramDetailCard = ({ program, type }) => {
  const [pricingModal, setPricingModal] = useState(false);

  return (
    <>
      {type == "web" ? (
        <div className="ay__programDetailCard">
          <div className="ay__programDetailCard-img">
            <img alt="img" src={program?.image_url} />
          </div>

          <div className="ay__programDetailCard-content">
            <div>
              <h5>{program.name}</h5>
            </div>
            <div className="ay__programDetailCard-content_img">
              <button
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  borderRadius: "20px",
                }}
                onClick={() => setPricingModal(true)}
                className="submitBtn"
              >
                View Package
              </button>
            </div>
          </div>
          <ReactModel
            show={pricingModal}
            onHide={() => setPricingModal(false)}
            name={program.name}
            setPricingModal={setPricingModal}
            // price={price}
            // setUpdatePackageId={setUpdatePackageId}
            // updatePackageId={updatePackageId}
            // updateProgramFunction={updateProgramFunction}
          />
        </div>
      ) : (
        <div className="ay__programDetailCard">
          <div className="ay__programDetailCard-img">
            <img alt="img" src={program?.videoDetails?.imageUrl} />
          </div>

          <div
            className="ay__programDetailCard-content"
            style={{ position: "initial" }}
          >
            <div>
              <h3>{program.name}</h3>
              <p>Validity: {program?.validity}</p>
            </div>

            <div className="ay__programDetailCard-content_img">
              {/* <img
                src="/img/edit.png"
                alt="edit"
                onClick={() => setPricingModal(true)}
              /> */}
            </div>
          </div>
          <ReactModel
            show={pricingModal}
            onHide={() => setPricingModal(false)}
            name={program.name}
            // price={price}
            // setUpdatePackageId={setUpdatePackageId}
            // updatePackageId={updatePackageId}
            // updateProgramFunction={updateProgramFunction}
          />
        </div>
      )}
    </>
  );
};

export default ProgramDetailCard;
