import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import Table from "react-bootstrap/Table";
import "./quickReadDisplay.css";
import EditIcon from "@mui/icons-material/Edit";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import QuickReadEdit from "../popup/quickReadEdit/QuickReadEdit";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const QuickReadDisplay = () => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [quickReadId, setQuickReadId] = useState("");
  const [displayRank, setDisplayRank] = useState(null);
  const [quickReads, setQuickReads] = useState([]);
  const [checked, setChecked] = useState(false);
  const [Loading, setLoading] = useState(true);

  const moreDetails = (id, item) => {
    navigate(id, {
      state: item,
    });
  };

  const setModalShowButton = (id) => {
    setModalShow(true);
    setQuickReadId(id);
  };

  const onChangeChecked = (item) => {
    console.log("item", item);
    setChecked(!checked);
    updateQuickRead(item);
  };

  const updateQuickRead = async (item) => {
    // e.preventDefault();
    const obj = {
      quickReadId: item.id,
      name: item.name,
      desc: item.desc,
      isActive: checked,
      subtitle: item.subtitle,
      imageAssets: item.imageAssets,
      subtitle: item.subTitle,
      category: item.category,
      thumbnail: item.thumbnail,
    };
    // await fetch("http://127.0.0.1:8000/magicFood/updateQuickReads/", {
    await fetch("https://qrs.btartisticyoga.com/magicFood/updateQuickReads/", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
      redirect: "follow",
    })
      .then((response) => response.json())
      .then((result) => {
        console.log("result2121", result);
        // alert("bannerImage added Successfully");
        getQuickreadData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getQuickreadData = () => {
    axios
      .get(
        // "http://127.0.0.1:8000/magicFood/getAllActiveQuickReadsForAdmin/"
        "https://qrs.btartisticyoga.com/magicFood/getAllActiveQuickReadsForAdmin/"
      )
      .then((res) => {
        console.log("quickRead", res.data.data);
        setQuickReads(res.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getQuickreadData();
  }, []);

  const updateDisplayRank = async (id, rank) => {
    const obj = {
      quickReadId: id,
      displayRank: rank,
    };
    await fetch(
      // "http://127.0.0.1:8000/magicFood/changeDisplayRankingByIdForQuickRead/",
      "https://qrs.btartisticyoga.com/magicFood/changeDisplayRankingByIdForQuickRead/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        getQuickreadData();
        setModalShow(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
    // setModalShow(false);
  };

  //=========================== testing Code===================================

  const notificationFunctionQuickRead = async (title, body, image, data) => {
    const response = await fetch(
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/sendNotificationToUserQuickRead",
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/sendNotificationToUserQuickRead",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          body: body,
          image: image,
          data,
        }),
        redirect: "follow",
      }
    )
      // return await response.json();
      .then((response) => response.json())
      .then((result) => {
        console.log("apiData", result.data);
        // return result.data;
      })
      .catch((error) => console.log("error", error));
  };

  const testingFunction = (quickread) => {
    console.log(
      "New Quick Read Added",
      quickread.name,
      quickread.thumbnail,
      quickread
    );
    notificationFunctionQuickRead(
      "New Quick Read Added",
      quickread.name,
      quickread.thumbnail,
      quickread
    );
  };

  //=========================== testing Code===================================

  // const handleDragEnd = (result) => {
  //   if (!result.destination) return;

  //   console.log("result", result.draggableId);
  //   console.log("destination", result.destination.index);
  //   updateDisplayRank(result.draggableId, result.destination.index);
  //   const reorderedQuickReads = Array.from(quickReads);
  //   const [movedItem] = reorderedQuickReads.splice(result.source.index, 1);
  //   reorderedQuickReads.splice(result.destination.index, 0, movedItem);

  //   const updatedQuickReads = reorderedQuickReads.map((item, index) => {
  //     return {
  //       ...item,
  //       displayRank: index + 1,
  //     };
  //   });

  //   setQuickReads(updatedQuickReads);
  // };

  // const handleDragEnd = (result) => {
  //   console.log("allData", result);
  //   if (!result.destination) return;

  //   const sourceIndex = result.source.index;
  //   const destinationIndex = result.destination.index;

  //   if (sourceIndex === destinationIndex) return;
  //   const startIndex = Math.min(sourceIndex, destinationIndex);
  //   const endIndex = Math.max(sourceIndex, destinationIndex);

  //   const reorderedQuickReads = [...quickReads];
  //   for (let i = startIndex; i <= endIndex; i++) {
  //     reorderedQuickReads[i].displayRank = i + 1;
  //     updateDisplayRank(reorderedQuickReads[i].id, i + 1);
  //   }

  //   setQuickReads(reorderedQuickReads);
  // };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index - 1;
    const destinationIndex = result.destination.index - 1;

    if (sourceIndex === destinationIndex) return;

    const reorderedQuickReads = [...quickReads];

    const tempItem = reorderedQuickReads[sourceIndex];
    reorderedQuickReads[sourceIndex] = reorderedQuickReads[destinationIndex];
    reorderedQuickReads[destinationIndex] = tempItem;

    reorderedQuickReads.forEach((item, index) => {
      updateDisplayRank(item.id, index + 1);
    });

    setQuickReads(reorderedQuickReads);
  };

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <>
      <div className="QuickReads">
        <div className="quickReadsParentContainer">
          {/* ==================BreadCrumb Nav=================== */}
          <div className="breadcrumbcontainer mb-5">
            <div className="breadcrumb-nav1">
              {/* <img src="/assets/rightchevron.png" alt="" /> */}
              <span> Quick Reads</span>
              <Link to={"/quickRead"}>
                <button className="addQuickRead">Post QuickRead</button>
              </Link>
            </div>
          </div>
          {/* <TableData data={quickReads} /> */}
          <Table striped hover>
            <thead>
              <tr>
                <th>Rank</th>
                <th>Image</th>
                <th>Name</th>
                <th>Actions</th>
                <th>Edit</th>
                <th>Details</th>
              </tr>
            </thead>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="quickReads">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {quickReads.map((item, index) => (
                      <Draggable
                        key={item.id.toString()}
                        draggableId={item.id.toString()}
                        index={index + 1}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>{index + 1}</td>

                            <td>
                              <img width="100px" src={item.thumbnail} alt="" />
                            </td>
                            <td>{item.name}</td>
                            <td>
                              <div className="quickReadsSwitch">
                                <label class="switch">
                                  <div
                                    className="checkbox_container"
                                    onChange={() => {
                                      onChangeChecked(item);
                                    }}
                                  >
                                    <input type="checkbox" />
                                    <span
                                      class="slider round"
                                      style={
                                        item?.isActive == true
                                          ? { backgroundColor: "green" }
                                          : { backgroundColor: "red" }
                                      }
                                    ></span>
                                    <div
                                      className="round_circle"
                                      style={
                                        item?.isActive == true
                                          ? {
                                              right: "4px",
                                              transition:
                                                "all 4s ease-in-out 1s",
                                            }
                                          : {
                                              left: "4px",
                                              transition:
                                                "all 4s ease-in-out 1s",
                                            }
                                      }
                                    ></div>
                                  </div>
                                </label>
                                <span style={{ color: "black" }}>
                                  {item?.isActive == true
                                    ? "is Active"
                                    : "not Active"}
                                </span>
                              </div>
                            </td>
                            <td>
                              <Link to={`/updateQuickRead/${item.id}`}>
                                <EditIcon
                                  style={{ cursor: "pointer", color: "black" }}
                                />
                              </Link>
                            </td>

                            <td>
                              <img
                                onClick={() =>
                                  moreDetails(
                                    `/quickReadDetails/${item.id}`,
                                    item
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                src="/img/eye.png"
                                alt="image"
                                width={"30px"}
                                // height={"20px"}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
            {/* <tbody>
              {quickReads.map((item, index) => {
                return (
                  <>
                    <tr>
                      <td>
                        {item.displayRank}
                        <EditIcon
                          onClick={() => {
                            setModalShowButton(item.id);
                          }}
                          style={{
                            cursor: "pointer",
                            color: "black",
                            marginLeft: "24px",
                            fontSize: "18px",
                          }}
                        />
                      </td>

                      <td>
                        <img width="100px" src={item.thumbnail} alt="" />
                      </td>
                      <td>{item.name}</td>
                      <td>
                        <div className="quickReadsSwitch">
                          <label class="switch">
                            <div
                              className="checkbox_container"
                              onChange={() => {
                                onChangeChecked(item);
                              }}
                            >
                              <input type="checkbox" />
                              <span
                                class="slider round"
                                style={
                                  item?.isActive == true
                                    ? { backgroundColor: "green" }
                                    : { backgroundColor: "red" }
                                }
                              ></span>
                              <div
                                className="round_circle"
                                style={
                                  item?.isActive == true
                                    ? {
                                        right: "4px",
                                        transition: "all 4s ease-in-out 1s",
                                      }
                                    : {
                                        left: "4px",
                                        transition: "all 4s ease-in-out 1s",
                                      }
                                }
                              ></div>
                            </div>
                          </label>
                          <span style={{ color: "black" }}>
                            {item?.isActive == true
                              ? "is Active"
                              : "not Active"}
                          </span>
                        </div>
                      </td>
                      <td>
                        <Link to={`/updateQuickRead/${item.id}`}>
                          <EditIcon
                            style={{ cursor: "pointer", color: "black" }}
                          />
                        </Link>
                      </td>

                      <td>
                        <img
                          onClick={() =>
                            moreDetails(`/quickReadDetails/${item.id}`, item)
                          }
                          style={{ cursor: "pointer" }}
                          src="/img/eye.png"
                          alt="image"
                          width={"30px"}
                          // height={"20px"}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody> */}
          </Table>
          <QuickReadEdit
            show={modalShow}
            onHide={() => setModalShow(false)}
            setDisplayRank={setDisplayRank}
            updatefunction={updateDisplayRank}
            updateParams={[quickReadId]}
          />
        </div>
      </div>
    </>
  );
};

export default QuickReadDisplay;
