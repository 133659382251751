import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import TimePicker from "react-time-picker";
import Clock from "react-clock";
import "react-time-picker/dist/TimePicker.css"; // Import TimePicker styles
import "react-clock/dist/Clock.css"; // Import Clock styles

const StudioTimingModel = (props) => {
  const [updatedTime, setUpdatedTime] = useState("");
  const [updatedDays, setUpdatedDays] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  console.log("props", props.selectedItem);
  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
  };
  useEffect(() => {
    setSelectedTime(props?.selectedItem?.time);
  }, [props?.selectedItem?.time]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // const updatedData = {
    //   time: updatedTime,
    //   days: updatedDays,
    // };
    const queryParams = new URLSearchParams();
    queryParams.append("studioName", props.studioName);
    queryParams.append("option", props.selectedItem);
    queryParams.append("updatedTime", updatedTime);
    queryParams.append(
      "activeTab",
      props.activeTab === "AY Classic" ? "ayClassic" : "red"
    );
    console.log("queryParams", queryParams);

    // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/studioBatchTiming?studioName=${
    //   props.studioName
    // }&option=${props.selectedItem}&updatedTime=${updatedTime}&activeTab=${
    //   props.activeTab === "AY Classic" ? "ayClassic" : "red"
    // }`
    axios
      .post(
        `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/studioBatchTiming`,
        queryParams
      )
      .then((response) => {
        console.log("Data updated successfully:", response.data);
        props.onHide();
      })
      .catch((error) => {
        console.error("Error updating data:", error);
      });
  };

  //   const clearData = () => {
  //     props.setSelectedItem(null);
  //   };
  return (
    <div className="OurStudioTimings">
      <Modal
        {...props}
        className="timingModal studioTimingModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.7)",
        }}
        // onHide={clearData}
      >
        <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
        <Modal.Body className="p-0">
          <h2>{props.activeTab}</h2>
          <form onSubmit={handleFormSubmit}>
            <div className="form-groups">
              <label htmlFor="time">Time:</label>
              {/* <input
                type="text"
                id="time"
                className="form-control"
                value={updatedTime}
                onChange={(e) => setUpdatedTime(e.target.value)}
              /> */}
              <TimePicker
                onChange={handleTimeChange}
                value={selectedTime}
                format="hh:mm a"
                clearIcon={null} // Hide the clear icon
                disableClock={true} // Disable the clock view
              />
            </div>
            <div className="analog-clock">
              <Clock value={selectedTime} size={150} renderNumbers={true} />
            </div>
            {/* <div className="form-group">
              <label htmlFor="days">Days:</label>
              <input
                type="text"
                id="days"
                className="form-control"
                value={updatedDays}
                onChange={(e) => setUpdatedDays(e.target.value)}
              />
            </div> */}
            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default StudioTimingModel;
