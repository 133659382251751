import React, { useEffect, useState } from "react";
import "./qnaDetails.css";
import axios from "axios";
import { useLocation, useParams } from "react-router-dom";
import { MillisecondsToIndianTime } from "../../../util/millisecToTime/MilliSecToTime";
import DeleteComment from "../../popup/deleteComment/DeleteComment";

const commentData = [
  {
    comments: {
      content: "nice",
      senderName: "Saman",
      type: "teacher",
    },
    docId: "1",
  },
  {
    comments: {
      content: "nice good",
      senderName: "Saman",
      type: "teacher",
    },
    docId: "2",
  },
  {
    comments: {
      content: "nice ome",
      senderName: "sam",
      type: "student",
    },
    docId: "3",
  },
];
const QnaDetails = () => {
  const [comments, setComments] = useState([]);
  const [showComments, setShowComments] = useState(true);
  const [showOtherComments, setShowOtherComments] = useState(true);

  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null); // Track the type of modal (delete/restore)
  const [selectedComment, setSelectedComment] = useState(null); // Store the selected comment for the modal

  const setModalShowButton = (type, comment) => {
    setModalShow(true);
    setModalType(type);
    setSelectedComment(comment);
  };
  const { id } = useParams();
  const { state } = useLocation();

  // console.log("state", state);

  const getQnaComments = () => {
    axios
      .get(
        `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/comments?docId=${id}`
        // `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/comments?docId=${id}`
      )
      .then((res) => {
        // console.log("comment", res.data);
        setComments(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getQnaComments();
  }, []);

  const updateQNAComments = (
    parentDocId,
    docId,
    deleteValue,
    markInappropriate
  ) => {
    // console.log("data", parentDocId, docId, deleteValue, markInappropriate);
    axios
      .put(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/qnaComment?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/qnaComment?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
      )
      .then((res) => {
        console.log("result", res);
        setModalShow(false);
        getQnaComments();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const teacherComments = comments.filter(
    (comment) => comment.comments.userType === "teacher"
  );
  const otherComments = comments.filter(
    (comment) => comment.comments.userType == "student"
  );

  return (
    <>
      <div
        className="quickReadsPopupContainer"
        style={{ margin: "3rem", flexDirection: "column" }}
      >
        <div className="qnaDetails_title">
          <span>Question</span>
        </div>

        <div className="QrImageSlider" style={{ height: "auto" }}>
          <h4>Question: {state.qna.content}</h4>
          <p>Created By: {state.qna.senderName}</p>
          <p>Created At: {MillisecondsToIndianTime(state.qna.timestamp)}</p>
        </div>

        <div className="QrDetails">
          <div className="QRDetailsContainer">
            {/* <div className="QrDetailsTitleDate">
              <h3>state?.title"</h3>
              <span>April 5, 2021</span>
            </div> */}

            {/* =====================View Comments==================== */}

            <div
              className="commentsParentcontainer"
              style={{ marginBottom: "20px" }}
            >
              <div
                className="commentSection"
                onClick={() => setShowComments(!showComments)}
                style={
                  showComments == true
                    ? {
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }
                    : {
                        borderRadius: "5px",
                      }
                }
              >
                <h4>View All Teachers Comments ({teacherComments.length})</h4>
                <img src="/assets/chevronUp.png" alt="" />
              </div>

              {showComments == true && (
                <div className="commentSectionContainer">
                  {teacherComments.map((item) => (
                    <div className="comments">
                      <div className="userProfile">
                        <img
                          src="/img/user.png"
                          alt=""
                          style={{ width: "20px" }}
                        />
                      </div>
                      <div className="userComment">
                        <h4 className="username">{item.comments.senderName}</h4>
                        <h4>{item.comments.content}</h4>
                        <p>
                          {MillisecondsToIndianTime(item.comments.timestamp)}
                        </p>
                      </div>

                      <div style={{ textAlign: "right" }}>
                        {item.comments.isDelete ? (
                          <>
                            <img
                              style={{ cursor: "pointer" }}
                              src="/img/restore.png"
                              alt="image"
                              width={"20px"}
                              onClick={() => {
                                setModalShowButton("restore", item.comments);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              style={{ cursor: "pointer" }}
                              src="/img/delete.png"
                              alt="image"
                              width={"15px"}
                              onClick={() => {
                                setModalShowButton("delete", item.comments);
                              }}
                            />
                          </>
                        )}
                        {modalType === "delete" && selectedComment && (
                          <DeleteComment
                            actionType="delete"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              true,
                              null,
                            ]}
                          />
                        )}

                        {modalType === "restore" && selectedComment && (
                          <DeleteComment
                            actionType="restore"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              false,
                              null,
                            ]}
                          />
                        )}
                      </div>

                      <div style={{ textAlign: "right" }}>
                        {item?.comments.markInappropriate ? (
                          <img
                            style={{ cursor: "pointer" }}
                            src="/img/checked.png"
                            // title="Mark Appropriate QNA"
                            alt="image"
                            width={"20px"}
                            onClick={() => {
                              setModalShowButton("appropriate", item);
                            }}
                          />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            src="/img/report.png"
                            // title="Mark Inappropriate QNA"
                            alt="image"
                            width={"20px"}
                            onClick={() => {
                              setModalShowButton("inAppropriate", item);
                            }}
                          />
                        )}
                        {modalType === "appropriate" && selectedComment && (
                          <DeleteComment
                            actionType="appropriate"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              null,
                              false,
                            ]}
                          />
                        )}
                        {modalType === "inAppropriate" && selectedComment && (
                          <DeleteComment
                            actionType="inAppropriate"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              null,
                              true,
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div
              className="commentsParentcontainer"
              style={{ marginBottom: "20px" }}
            >
              <div
                className="commentSection"
                onClick={() => setShowOtherComments(!showOtherComments)}
                style={
                  showOtherComments == true
                    ? {
                        borderBottomLeftRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }
                    : {
                        borderRadius: "5px",
                      }
                }
              >
                <h4>View All Student Comments ({otherComments.length})</h4>
                <img src="/assets/chevronUp.png" alt="" />
              </div>

              {showOtherComments == true && (
                <div className="commentSectionContainer">
                  {otherComments.map((item) => (
                    <div className="comments">
                      <div className="userProfile">
                        <img
                          src="/img/user.png"
                          alt=""
                          style={{ width: "20px" }}
                        />
                      </div>
                      <div className="userComment">
                        <h4 className="username">{item.comments.senderName}</h4>
                        <h4>{item.comments.content}</h4>
                        <p>
                          {MillisecondsToIndianTime(item.comments.timestamp)}
                        </p>
                      </div>

                      <div style={{ textAlign: "right" }}>
                        {item.comments.isDelete ? (
                          <>
                            <img
                              style={{ cursor: "pointer" }}
                              src="/img/restore.png"
                              alt="image"
                              width={"20px"}
                              onClick={() => {
                                setModalShowButton("restore", item);
                              }}
                            />
                          </>
                        ) : (
                          <>
                            <img
                              style={{ cursor: "pointer" }}
                              src="/img/delete.png"
                              alt="image"
                              width={"15px"}
                              onClick={() => {
                                setModalShowButton("delete", item);
                              }}
                            />
                          </>
                        )}
                        {modalType === "delete" && selectedComment && (
                          <DeleteComment
                            actionType="delete"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              true,
                              null,
                            ]}
                          />
                        )}

                        {modalType === "restore" && selectedComment && (
                          <DeleteComment
                            actionType="restore"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              false,
                              null,
                            ]}
                          />
                        )}
                      </div>

                      <div style={{ textAlign: "right" }}>
                        {item?.comments.markInappropriate ? (
                          <img
                            style={{ cursor: "pointer" }}
                            src="/img/checked.png"
                            // title="Mark Appropriate QNA"
                            alt="image"
                            width={"20px"}
                            onClick={() => {
                              setModalShowButton("appropriate", item);
                            }}
                          />
                        ) : (
                          <img
                            style={{ cursor: "pointer" }}
                            src="/img/report.png"
                            // title="Mark Inappropriate QNA"
                            alt="image"
                            width={"20px"}
                            onClick={() => {
                              setModalShowButton("inAppropriate", item);
                            }}
                          />
                        )}
                        {modalType === "appropriate" && selectedComment && (
                          <DeleteComment
                            actionType="appropriate"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              null,
                              false,
                            ]}
                          />
                        )}
                        {modalType === "inAppropriate" && selectedComment && (
                          <DeleteComment
                            actionType="inAppropriate"
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            comment={selectedComment}
                            updateFunction={updateQNAComments}
                            updateParams={[
                              selectedComment.docId,
                              selectedComment.id,
                              null,
                              true,
                            ]}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="qnaDetails">
        <div className="qnaDetails_title">
          <span>Question</span>
        </div>

        <div className="qnaDetails_Data">
          <h6>Question: {state.qna.content}</h6>
          <p>Created By: {state.qna.senderName}</p>
        </div>

        <div className="qnaDetails_comments">
          <h4>Teacher Comments</h4>
          {teacherComments.map((comment, index) => (
            <p key={index}>Comments: {comment.comments.content}</p>
          ))}
        </div>

        <div className="qnaDetails_comments">
          <h4>Other Comments</h4>
          {otherComments.map((comment, index) => (
            <p key={index}>Comments: {comment.comments.content}</p>
          ))}
        </div>
      </div>
    </>
  );
};

export default QnaDetails;
