import React, { useState } from "react";
import "./App.css";
import Login from "./components/login/Login";
import QuickRead from "./components/quickread/QuickRead";
import FirebaseNotification from "./components/firebaseNotification/FirebaseNotification";
import AyBannerImage from "./components/ayBannerImage/AyBannerImage";
import Home from "./components/Home/Home";
import Header from "./components/header/Header";
import UpdateQuickRead from "./components/updateQuickRead/UpdateQuickRead";
import UpdateAyBannerImage from "./components/updateAyBannerImage/UpdateAyBannerImage";
import Subscription from "./components/subscription/Subscription";
import DailyClass from "./components/appContent/dailyClass/DailyClass";
import BasicProgramDetails from "./components/updateProgram/basicProgramDetails/BasicProgramDetails";
import ProgramPackages from "./components/updateProgram/programPackages/ProgramPackages";
import ProgramBatchesDisplay from "./components/programBatches/programBatchesDisplay/ProgramBatchesDisplay";
import AyProgramBatches from "./components/programBatches/ayProgramBatches/AyProgramBatches";
import EventSubscription from "./components/eventSubscription/EventSubscription";
import QnaVideoBlogDisplay from "./components/qnaVideoBlogDisplay/QnaVideoBlogDisplay";
import QnaDetails from "./components/qnaVideoBlogDisplay/qnaDetails/QnaDetails";
import QuickReadDetails from "./components/quickReadDetails/QuickReadDetails";
import TeachersDisplay from "./components/teachersDisplay/TeachersDisplay";
import CreateAppProgram from "./components/updateProgram/createAppProgram/CreateAppProgram";
import Comments from "./components/comments/Comments";
import Users from "./components/users/Users";
import UserDetail from "./components/userDetail/UserDetail";
import OurStudios from "./components/ourStudios/OurStudios";
import CreateFaq from "./components/appContent/faq/createFaq/CreateFaq";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const App = () => {
  const [localStorage, setLocalStorage] = useState(null);

  return (
    <>
      <Router>
        <Header localStorage={localStorage} setLocalStorage={setLocalStorage} />
        {/* <Header /> */}
        <Routes>
          <Route path="/home" element={<Home localStorage={localStorage} />} />
          <Route path="/notification" element={<FirebaseNotification />} />
          <Route path="/quickRead" element={<QuickRead />} />
          <Route path="/AyBannerImage" element={<AyBannerImage />} />
          <Route path="/updateQuickRead/:id" element={<UpdateQuickRead />} />
          <Route
            path="/updateAyBannerImage/:id"
            element={<UpdateAyBannerImage />}
          />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/dailyClass" element={<DailyClass />} />
          {/* <Route path="/programs" element={<BasicProgramDetails />} /> */}
          <Route path="/programPackages/:name" element={<ProgramPackages />} />
          {/* <Route
            path="/programBatchesDisplay"
            element={<ProgramBatchesDisplay />}
          /> */}
          <Route path="/programBatches" element={<AyProgramBatches />} />
          <Route path="/eventData" element={<EventSubscription />} />
          <Route
            path="/qnaVideoBlogDisplay"
            element={<QnaVideoBlogDisplay />}
          />
          <Route path="/teachers" element={<TeachersDisplay />} />
          <Route path="/appProgram" element={<CreateAppProgram />} />
          <Route path="/Comments" element={<Comments />} />
          <Route path="/users" element={<Users />} />

          <Route path="/userDetail" element={<UserDetail />} />

          <Route path="/qnaDetails/:id" element={<QnaDetails />} />
          <Route path="/quickReadDetails/:id" element={<QuickReadDetails />} />
          <Route path="/OurStudios" element={<OurStudios />} />
          <Route path="/faq" element={<CreateFaq />} />
          <Route
            path="/"
            element={<Login setLocalStorage={setLocalStorage} />}
          />
        </Routes>
      </Router>
    </>
  );
};

export default App;
