import React, { useState, useEffect } from "react";
import firebase from "../../Firebase/FirebaseConfig";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Country } from "country-state-city";
import axios from "axios";

const storage = firebase.storage();
// const auth = firebase.auth();
const projectFireStore = firebase.firestore();

const UpdateAyBannerImage = () => {
  const navigate = useNavigate();
  // coverImageIdParams
  const { id } = useParams();
  const { state } = useLocation();
  console.log("state", state);
  const [coverImageId, setCoverImageId] = useState("");
  const [coverImageText, setCoverImageText] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [url, setUrl] = useState("");
  const [type, setType] = useState("");
  const [metaDataId, setMetaDataId] = useState("");
  const [metaDataName, setMetaDataName] = useState("");

  const [isGlobal, setIsGlobal] = useState(true);
  const [city, setCity] = useState("India");
  const [metaDataDescription, setMetaDataDescription] = useState("");
  const [metaDataConfirmationURL, setMetaDataConfirmationURL] = useState("");
  const [metaDataAndroidPackageId, setMetaDataAndroidPackageId] = useState("");
  const [metaDataIOSPackageId, setMetaDataIOSPackageId] = useState("");
  const [metaDataRevenueCatPackageId, setMetaDataRevenueCatPackageId] =
    useState("");

  const countries = Country.getAllCountries();

  const [quickReads, setQuickReads] = useState([]);
  const [programData, setProgramData] = useState([]);

  const getQuickreadData = () => {
    axios
      // .get
      // "http://127.0.0.1:8000/magicFood/getAllActiveQuickReadsForAdmin/"
      // ()
      .get(
        "https://qrs.btartisticyoga.com/magicFood/getAllActiveQuickReadsForAdmin/"
      )
      .then((res) => {
        // console.log("quickRead", res.data.data);
        setQuickReads(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProgramData = () => {
    axios
      // .get
      // "http://127.0.0.1:8000/magicFood/getAllActiveQuickReadsForAdmin/"
      // ()
      .get(
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/programInAppMapping"
      )
      .then((res) => {
        console.log("programs", res?.data[0].data[2].classes);
        setProgramData(res?.data[0].data[2].classes);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // Ensure that state exists and has the expected properties
    if (state) {
      setCoverImageId(state.coverImageId);
      setCoverImageText(state.coverImageText);
      setType(state.type);
      setImageUrl(state.imageUrl);

      if (state.type === "quickReads") {
        setMetaDataId(state.metaData.id);
      } else if (state.type === "program") {
        setMetaDataId(state.metaData.id);
        setMetaDataName(state.metaData.name);
        setMetaDataDescription(state.metaData.description);
      } else if (state.type === "event") {
        setMetaDataId(state.metaData.id);
        setMetaDataName(state.metaData.name);
        setMetaDataDescription(state.metaData.description);
        setMetaDataConfirmationURL(state.metaData.confirmationURL);
      } else if (state.type === "video") {
        setMetaDataId(state.metaData.id);
        setMetaDataName(state.metaData.name);
      } else {
        setMetaDataId("");
        setMetaDataName("");
      }
    }

    getQuickreadData();
    getProgramData();
  }, []);

  const handleChange = async (e) => {
    if (e.target.files[0]) {
      setImageUrl(e.target.files[0]);
    }
  };
  console.log("imageUrl", imageUrl);

  // const handleUpload = async (e) => {
  //   e.preventDefault();

  //   // Reference to the document using docId
  //   const documentRef = projectFireStore.collection("bannerCoverImagesTest").doc(state.docId);

  //   const uploadTask = storage.ref(`files/${imageUrl.name}`).put(imageUrl);

  //   uploadTask.on(
  //     "state_changed",
  //     (snapshot) => {
  //       const progress = Math.round(
  //         (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //       );
  //       // setProgress(progress);
  //     },
  //     (error) => {
  //       console.log(error);
  //     },
  //     () => {
  //       storage
  //         .ref("files")
  //         .child(imageUrl.name)
  //         .getDownloadURL()
  //         .then((url) => {
  //           setUrl(url);

  //           // Define the data you want to update
  //           const updatedData = {
  //             coverImageId: coverImageId,
  //             coverImageText: coverImageText,
  //             imageUrl: url,
  //             isActive: true,
  //             isGlobal: true,
  //             type: type,
  //             metaData:
  //               type === "quickReads"
  //                 ? {
  //                     id: metaDataId,
  //                   }
  //                 : type === "program"
  //                 ? {
  //                     id: metaDataId,
  //                     name: metaDataName,
  //                     description: metaDataDescription,
  //                   }
  //                 : type === "event"
  //                 ? {
  //                     id: metaDataId,
  //                     name: metaDataName,
  //                     description: metaDataDescription,
  //                     confirmationURL: metaDataConfirmationURL,
  //                   }
  //                 : { id: metaDataId, name: metaDataName },
  //           };

  //           // Update the document using documentRef
  //           return documentRef.update(updatedData);
  //         })
  //         .then(() => {
  //           setCoverImageId("");
  //           setCoverImageText("");
  //           setImageUrl("");
  //           setType("");
  //           setUrl("");
  //           setMetaDataId("");
  //           setMetaDataName("");
  //           alert("Banner Image Updated Successfully");
  //           navigate("/home");
  //         });
  //     }
  //   );
  // };

  // const handleUpload = (e) => {
  //   e.preventDefault();
  // };

  const handleUpload = async (e) => {
    e.preventDefault();

    // Reference to the document using docId
    const documentRef = projectFireStore
      .collection("bannerCoverImages")
      .doc(id);

    if (state.imageUrl == "") {
      // const uploadTask = storage.ref(`files/${imageUrl.name}`).put(imageUrl);
      const uploadTask = storage.ref(`files/${imageUrl.name}`).put(imageUrl);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          // setProgress(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          storage
            .ref("files")
            .child(imageUrl.name)
            .getDownloadURL()
            .then((url) => {
              setUrl(url);

              // Define the data you want to update
              const updatedData = {
                coverImageId: coverImageId,
                coverImageText: coverImageText,
                imageUrl: url,
                isActive: true,
                isGlobal: true,
                type: type,
                metaData:
                  type === "quickReads"
                    ? {
                        id: metaDataId,
                      }
                    : type === "program"
                    ? {
                        id: metaDataId,
                        name: metaDataName,
                        description: metaDataDescription,
                      }
                    : type === "event"
                    ? {
                        id: metaDataId,
                        name: metaDataName,
                        description: metaDataDescription,
                        confirmationURL: metaDataConfirmationURL,
                      }
                    : { id: metaDataId, name: metaDataName },
              };

              // Update the document using documentRef
              return documentRef.update(updatedData);
            })
            .then(() => {
              setCoverImageId("");
              setCoverImageText("");
              setImageUrl("");
              setType("");
              setUrl("");
              setMetaDataId("");
              setMetaDataName("");
              alert("Banner Image Updated Successfully");
              navigate("/home");
            });
        }
      );
    } else {
      const updatedData = {
        coverImageId: coverImageId,
        coverImageText: coverImageText,
        imageUrl: state.imageUrl,
        isActive: true,
        isGlobal: true,
        type: type,
        metaData:
          type === "quickReads"
            ? {
                id: metaDataId,
              }
            : type === "program"
            ? {
                id: metaDataId,
                name: metaDataName,
                description: metaDataDescription,
              }
            : type === "event"
            ? {
                id: metaDataId,
                name: metaDataName,
                description: metaDataDescription,
                confirmationURL: metaDataConfirmationURL,
              }
            : { id: metaDataId, name: metaDataName },
      };
      return documentRef.update(updatedData).then(() => {
        alert("Banner Image updated Successfully");
        navigate("/home");
      });
    }
  };

  return (
    <>
      {/* <Header className="mb-3" /> */}

      <div className="Apps mb-3">
        <div className="breadcrumbcontainer">
          <div className="breadcrumb-nav">
            <img src="/assets/rightchevron.png" alt="" />
            <span>Update Banner Images</span>
          </div>
        </div>

        <form className="mb-3">
          <div className="mb-3">
            <label htmlFor="coverImageId" className="form-label">
              coverImageId
            </label>
            <input
              type="text"
              className="form-control"
              id="coverImageId"
              aria-describedby="coverImageId"
              value={coverImageId}
              onChange={(e) => {
                setCoverImageId(e.target.value);
              }}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="coverImageText" className="form-label">
              coverImageText
            </label>
            <input
              type="text"
              className="form-control"
              id="coverImageText"
              aria-describedby="coverImageText"
              value={coverImageText}
              onChange={(e) => {
                setCoverImageText(e.target.value);
              }}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="file" className="form-label">
              Cover image
            </label>
            <input
              type="file"
              className="form-control"
              id="file"
              aria-describedby="file"
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="type" className="form-label">
              Category
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              value={type}
              onChange={(e) => {
                // handleNotificationError();
                setType(e.target.value);
              }}
            >
              <option selected value="" disabled>
                Open this select Category
              </option>
              <option value="quickReads">Quick Read</option>
              <option value="program">Program</option>
              <option value="video">Video</option>
              <option value="event">Event</option>
              <option value="personalise">personalize</option>
              <option value="offer">Offers</option>
            </select>
          </div>

          {type == "quickReads" && (
            <div>
              <div className="mb-3">
                <label htmlFor="metaDataId" className="form-label">
                  {type}
                </label>
                <select
                  id="metaDataId"
                  class="form-select"
                  aria-label="metaDataId"
                  value={metaDataId}
                  onChange={(e) => {
                    setMetaDataId(e.target.value);
                  }}
                >
                  <option selected disabled value="">
                    Open this select QuickReads
                  </option>
                  {quickReads.map((item, index) => {
                    return (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}

          {type == "program" && (
            <div>
              <div className="mb-3">
                <label htmlFor="metaDataId" className="form-label">
                  {type}
                </label>
                <select
                  id="metaDataId"
                  class="form-select"
                  aria-label="metaDataId"
                  value={metaDataId}
                  onChange={(e) => {
                    setMetaDataId(e.target.value);
                  }}
                >
                  <option value="" selected disabled>
                    Open this select Programs
                  </option>
                  {programData.map((item, index) => {
                    return (
                      <option key={index} value={`${item.id} ${item.context}`}>
                        {item.context}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="mb-3">
                <label htmlFor="metaDataDescription" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataDescription"
                  aria-describedby="metaDataDescription"
                  value={metaDataDescription}
                  onChange={(e) => {
                    setMetaDataDescription(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          )}

          {type == "event" && (
            <div>
              <div className="mb-3">
                <label htmlFor="metaDataName" className="form-label">
                  {type} name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataName"
                  aria-describedby="metaDataName"
                  value={metaDataName}
                  onChange={(e) => {
                    setMetaDataName(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="metaDataDescription" className="form-label">
                  Description
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataDescription"
                  aria-describedby="metaDataDescription"
                  value={metaDataDescription}
                  onChange={(e) => {
                    setMetaDataDescription(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="confirmationURL" className="form-label">
                  Confirmation URL
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="confirmationURL"
                  aria-describedby="confirmationURL"
                  value={metaDataConfirmationURL}
                  onChange={(e) => {
                    setMetaDataConfirmationURL(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="type" className="form-label">
                  Country
                </label>
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                >
                  {/* <option selected>Open this select Category</option> */}
                  {countries.map((item, index) => {
                    return (
                      <option selected value={item.name}>
                        {item.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}

          {type == "offer" && (
            <div>
              <div className="mb-3">
                <label
                  htmlFor="metaDataAndroidPackageId"
                  className="form-label"
                >
                  Android Package Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataAndroidPackageId"
                  aria-describedby="metaDataAndroidPackageId"
                  value={metaDataAndroidPackageId}
                  onChange={(e) => {
                    setMetaDataAndroidPackageId(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="metaDataIOSPackageId" className="form-label">
                  IOS Package Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataIOSPackageId"
                  aria-describedby="metaDataIOSPackageId"
                  value={metaDataIOSPackageId}
                  onChange={(e) => {
                    setMetaDataIOSPackageId(e.target.value);
                  }}
                  required
                />
              </div>

              <div className="mb-3">
                <label
                  htmlFor="metaDataRevenueCatPackageId"
                  className="form-label"
                >
                  Revenue Cat Package Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataRevenueCatPackageId"
                  aria-describedby="metaDataRevenueCatPackageId"
                  value={metaDataRevenueCatPackageId}
                  onChange={(e) => {
                    setMetaDataRevenueCatPackageId(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          )}
          {type == "video" && (
            <div>
              <div className="mb-3">
                <label
                  htmlFor="metaDataAndroidPackageId"
                  className="form-label"
                >
                  Video Id
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataAndroidPackageId"
                  aria-describedby="metaDataAndroidPackageId"
                  value={metaDataId}
                  onChange={(e) => {
                    setMetaDataId(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="metaDataIOSPackageId" className="form-label">
                  Video Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="metaDataIOSPackageId"
                  aria-describedby="metaDataIOSPackageId"
                  value={metaDataName}
                  onChange={(e) => {
                    setMetaDataName(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
          )}

          <button
            type="submit"
            className="btn btn-primary"
            onClick={handleUpload}
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

export default UpdateAyBannerImage;
