import React, { useEffect, useState } from "react";
import axios from "axios";
import ProgramCard from "../programCard/ProgramCard";
import Loader from "react-loader-spinner";
import "../viewAllPackageHistory/ViewAllPackageHistory.css";

const ProgramHistory = () => {
  const [allProgramHistory, setAllProgramHistory] = useState([]);
  const [selectCategory, setSelectCategory] = useState("all");
  const [Loading, setLoading] = useState(true);
  const getAllPackageDetails = () => {
    setLoading(true);
    axios
      .get(
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/customers"
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/bannerCoverImagesForAdmin"
      )
      .then((res) => {
        console.log("data", res.data);
        setAllProgramHistory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllPackageDetails();
  }, []);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div
      className="ay_viewAllPackageHistory"
      style={{ width: "70vw", margin: "40px auto" }}
    >
      <div className="ay_viewAllPackageHistory-select">
        <h2>Program History</h2>
      </div>
      {allProgramHistory.map((item) => {
        return (
          <>
            <ProgramCard item={item} />
          </>
        );
      })}
    </div>
  );
};

export default ProgramHistory;
