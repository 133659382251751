import React, { useState, useEffect } from "react";
import axios from "axios";
import { MillisecondsToIndianTime } from "../../../util/millisecToTime/MilliSecToTime";
import DeleteComment from "../../popup/deleteComment/DeleteComment";
import QuickReadAndVideoDetails from "../../popup/quickreadAndVideoDetails/QuickReadAndVideoDetails";
// import querystring from "querystring";

const VideosComment = ({ data, token, getBlogsAndVideosCommentsAdmin }) => {
  const [video, setVideo] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState("");
  const [selectedComment, setSelectedComment] = useState(null); // Store the selected comment for the modal

  const [modalShowVideoDetail, setModalShowVideoDetail] = useState(false);
  const [modalTypeVideo, setModalTypeVideo] = useState("");

  const [commentData, setCommentData] = useState([]);

  const ModalFunction = (type) => {
    setModalShowVideoDetail(true);
    setModalTypeVideo(type);
  };

  const setModalShowButton = (type, comment) => {
    console.log("comment", comment);
    setModalShow(true);
    setModalType(type);
    setSelectedComment(comment);
  };

  // const getVideoData = () => {
  //   axios
  //     .get(
  //       `https://artisticyogaikriya.com/ikriya-video-service/video/detail/${data?.parentDocId}`
  //     )
  //     .then((res) => {
  //       console.log("oldVideo", res.data.data);
  //       setVideo(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const videoDetails = async () => {
    await fetch(
      "https://artisticyogaikriya.com/ikriya-video-service/video/detail/" +
        data?.parentDocId,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },

        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((Videodetails) => {
        if (Videodetails.status === "Success") {
          console.log("newVideoData", Videodetails.data);
          setVideo(Videodetails.data);
          // setPlayVideo(Videodetails.data);
          // setLoading(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    // getVideoData();
    videoDetails();
  }, []);

  const getBlogsAndVideosComments = () => {
    axios
      .get(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/blogsAndVideosComments?docId=${id}_b&userId=4194`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosComments?docId=${data.parentDocId}&userId=4194`
      )
      .then((res) => {
        // console.log("commentData", res.data);
        setCommentData(res.data);
        // setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    // getVideoData();
    videoDetails();
    getBlogsAndVideosComments();
  }, []);

  const updateBlogsAndVideosComments = (
    parentDocId,
    docId,
    deleteValue,
    markInappropriate
  ) => {
    console.log("data", parentDocId, docId, deleteValue, markInappropriate);
    axios
      .put(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/blogsAndVideosComments?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/blogsAndVideosComments?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
      )
      .then((res) => {
        console.log("result", res);
        setModalShow(false);
        getBlogsAndVideosComments();
        getBlogsAndVideosCommentsAdmin();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <>
      <tbody>
        <tr
          className={`table-row ${1 % 2 === 0 ? "even" : "odd"}`}
          key={1}
          style={{ height: "50px" }}
        >
          <td className="table-cell">{video?.id}</td>
          <td className="table-cell">{data?.message}</td>
          <td className="table-cell">{data?.userName}</td>
          <td className="table-cell">{video?.name}</td>
          <td className="table-cell">
            {MillisecondsToIndianTime(data?.timestamp)}
          </td>

          <td>
            <img
              onClick={() => ModalFunction("video")}
              style={{ cursor: "pointer" }}
              src="/img/eye.png"
              alt="image"
              width={"30px"}
              // title="QNA Details"
              // height={"20px"}
            />
          </td>

          <td className="table-cell">
            <div>
              {data?.isDelete ? (
                <>
                  <img
                    style={{ cursor: "pointer" }}
                    src="/img/restore.png"
                    title="Restore Comment"
                    alt="image"
                    width={"20px"}
                    onClick={() => {
                      setModalShowButton("restore", data);
                    }}
                  />
                </>
              ) : (
                <>
                  <img
                    style={{ cursor: "pointer" }}
                    src="/img/delete.png"
                    alt="image"
                    title="Delete Comment"
                    width={"15px"}
                    onClick={() => {
                      setModalShowButton("delete", data);
                    }}
                  />
                </>
              )}
              {modalType === "delete" && selectedComment && (
                <DeleteComment
                  actionType="delete"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    true,
                    null,
                  ]}
                />
              )}

              {modalType === "restore" && selectedComment && (
                <DeleteComment
                  actionType="restore"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    false,
                    null,
                  ]}
                />
              )}
            </div>
          </td>

          <td>
            {" "}
            <div>
              {data?.markInappropriate ? (
                <img
                  style={{ cursor: "pointer" }}
                  src="/img/checked.png"
                  // title="Mark Appropriate QNA"
                  alt="image"
                  width={"20px"}
                  onClick={() => {
                    setModalShowButton("appropriate", data);
                  }}
                />
              ) : (
                <img
                  style={{ cursor: "pointer" }}
                  src="/img/report.png"
                  // title="Mark Inappropriate QNA"
                  alt="image"
                  width={"20px"}
                  onClick={() => {
                    setModalShowButton("inAppropriate", data);
                  }}
                />
              )}
              {modalType === "appropriate" && selectedComment && (
                <DeleteComment
                  actionType="appropriate"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    null,
                    false,
                  ]}
                />
              )}
              {modalType === "inAppropriate" && selectedComment && (
                <DeleteComment
                  actionType="inAppropriate"
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  comment={selectedComment}
                  updateFunction={updateBlogsAndVideosComments}
                  updateParams={[
                    selectedComment.parentDocId,
                    selectedComment.docId,
                    null,
                    true,
                  ]}
                />
              )}
            </div>
          </td>
        </tr>
      </tbody>
      <QuickReadAndVideoDetails
        activeType={modalTypeVideo}
        state={video}
        show={modalShowVideoDetail}
        commentData={commentData}
        onHide={() => setModalShowVideoDetail(false)}
      />
    </>
  );
};

export default VideosComment;
