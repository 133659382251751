import React, { useState, useEffect } from "react";
import "./programPackages.css";
import axios from "axios";
import CreateUpdatePackage from "../../popup/createUpdatePackage/CreateUpdatePackage";
import Loader from "react-loader-spinner";
import { useParams, useNavigate } from "react-router-dom";

const ProgramPackages = ({ name, setPricingModal }) => {
  const navigate = useNavigate();
  // const { name } = useParams();

  const [Loading, setLoading] = useState(true);
  const [programPricing, setProgramPricing] = useState([]);
  const [activeProgramTab, setActiveProgramTab] = useState("Studio");
  const [activeIndex, setActiveIndex] = useState(0);
  const [updatePackageId, setUpdatePackageId] = useState("");
  const [sa, setSa] = useState("");
  const [activeCard, setActiveCard] = useState(null);

  const [oldPackageId, setOldPackageId] = useState(null);
  const [newPackageId, setNewPackageId] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [checked, setChecked] = useState(false);
  const [existPackage, setExistPackage] = useState([]);

  const [packageDetails, setPackageDetails] = useState(null);

  const onChangeChecked = (item) => {
    console.log("item", item);
    setChecked(!checked);
    updatePackage(item);
  };

  const deleteModal = (type, item) => {
    setModalShow(true);
    setModalType(type);
    setExistPackage(item);
    setOldPackageId(item.packageId);
  };

  const updatePackage = async (item, value) => {
    // console.log("delete", item, value);
    const obj = {
      programName: name.toLowerCase(),
      mode: item.mode,
      packageId: item.packageId,
      isActive: value != undefined ? true : checked,
      isDelete: value != undefined && value,
    };
    console.log("obj", obj);
    await fetch(
      "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/programPricingDetails",
      // "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/programPricingDetails",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log("result2121", result);
        pricingData();
        setModalShow(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const updatePackageWithNewData = async (item) => {
    console.log("item18", item);
    const obj = {
      programName: name.toLowerCase(),
      mode: activeProgramTab,
      newPackageId: item.id,
      oldPackageId: oldPackageId,
      isActive: true,
      price: item.price,
      validity: item.validity,
      validityUnit: item.validityUnit,
    };
    await fetch(
      "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/programPricingDetailsWithNewData",
      // "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/programPricingDetailsWithNewData",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result2121", result);
        pricingData();
        setModalShow(false);
        setPackageDetails(null);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleActiveTab = (tabName, index) => {
    setActiveProgramTab(tabName);
    setActiveIndex(index);
  };

  const setModalShowButton = (type, id) => {
    setModalShow(true);
    setModalType(type);
    setOldPackageId(id);
    // setUpdateUserId(userId);
    // setUpdateName(name);
  };

  // console.log("showInput", showInput);
  const updateProgramFunction = (id) => {
    // console.log("log", id);
    setSa(id);
    setModalShow(false);
  };

  const addNewPackages = async () => {
    // console.log("names", name, activeProgramTab, newPackageId);
    const response = await fetch(
      "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/create/programPricingDetails1",
      // "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/create/programPricingDetails1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          programName: name.toLowerCase(),
          mode: activeProgramTab,
          packageId: newPackageId,
        }),
        redirect: "follow",
      }
    )
      // return await response.json();
      .then((response) => response.json())
      .then((result) => {
        console.log("success", result);
        pricingData();
        setModalShow(false);
        setPackageDetails(null);

        // return result.data;
      })
      .catch((error) => console.log("error", error));
  };

  const pricingData = () => {
    axios
      .get(
        `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/programPricingDetails?programName=${
          name === "SuperFast"
            ? "superfast"
            : name === "Rapid"
            ? "rapid"
            : "red"
        }`
        // `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/programPricingDetails?programName=${
        //   name === "SuperFast"
        //     ? "superfast"
        //     : name === "Rapid"
        //     ? "rapid"
        //     : "red"
        // }`
      )
      .then((res) => {
        // console.log("ProgramDetails", res.data?.pricing);
        setProgramPricing(res.data?.pricing);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    pricingData();
  }, [name]);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <>
      <div className="ay__programPackages">
        <div className="ay__programPackages-heading">
          <span style={{ fontSize: "24px" }}>{name} Pricing</span>
        </div>

        <div className="ay__programPackages-priceCards-red">
          <div className="program-price-container2">
            {programPricing.map((item, id) => (
              <div
                className={
                  activeProgramTab === item.mode
                    ? "activeProgramTab program-tab-container"
                    : "program-tab-container"
                }
                onClick={() => handleActiveTab(item.mode, id)}
              >
                <p>{item.mode} Classes</p>
              </div>
            ))}
          </div>

          <div className="create_Variant">
            <button
              onClick={() => {
                setModalShowButton("create");
              }}
            >
              Create Variant
            </button>
          </div>

          <div className="ay__programPackages-priceCards">
            {programPricing[activeIndex]?.programPricing.length > 0 ? (
              <>
                {programPricing[activeIndex]?.programPricing.map(
                  (price, index) => {
                    const cardIndex = `${index}`;
                    const isCardActive = activeCard === cardIndex;

                    return (
                      <div
                        key={index}
                        className={`ay__programPackages-priceCard ${
                          isCardActive ? "activeCard" : ""
                        }`}
                      >
                        <p>
                          {price?.validity} {price?.validityUnit}
                        </p>
                        <h6>AED {Math.floor(price?.price)}</h6>
                        <p>
                          Package Id: {price?.packageId}
                          <img
                            // onClick={() => setActiveCard(cardIndex)}
                            onClick={() => {
                              setModalShowButton("update", price?.packageId);
                            }}
                            style={{
                              width: "18px",
                              marginLeft: "1rem",
                              cursor: "pointer",
                            }}
                            src="/img/pencil.png"
                            alt="pencel"
                          />
                        </p>

                        <div className="quickReadsSwitch">
                          <label class="switch">
                            <div
                              className="checkbox_container"
                              onChange={() => {
                                onChangeChecked(price);
                              }}
                            >
                              <input type="checkbox" />
                              <span
                                class="slider round"
                                style={
                                  price?.isActive == true
                                    ? { backgroundColor: "green" }
                                    : { backgroundColor: "red" }
                                }
                              ></span>
                              <div
                                className="round_circle"
                                style={
                                  price?.isActive == true
                                    ? {
                                        right: "4px",
                                        transition: "all 4s ease-in-out 1s",
                                      }
                                    : {
                                        left: "4px",
                                        transition: "all 4s ease-in-out 1s",
                                      }
                                }
                              ></div>
                            </div>
                          </label>
                          <span style={{ color: "black" }}>
                            {price?.isActive == true
                              ? "is Active"
                              : "not Active"}
                          </span>
                        </div>

                        {/* {isCardActive && (
                          <div className="inputAnimation">
                            <div className="mb-3">
                              <input
                                placeholder="Update Package Id"
                                type="text"
                                className="form-control"
                                id="subtitle"
                                aria-describedby="subtitle"
                                // ... other input field properties ...
                              />
                            </div>
                          </div>
                        )} */}
                        <div className="ay__programPackages-priceCard_delete">
                          <img
                            style={{ cursor: "pointer" }}
                            src="/img/delete.png"
                            alt="image"
                            width={"20px"}
                            onClick={() => {
                              deleteModal("delete", price);
                            }}
                          />
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <span>No package available</span>
            )}
          </div>
        </div>
      </div>
      {modalType === "create" ? (
        <CreateUpdatePackage
          activeType={modalType}
          show={modalShow}
          onHide={() => setModalShow(false)}
          createUpdateFunction={addNewPackages}
          setNewPackageId={setNewPackageId}
          newPackageId={newPackageId}
          name={name}
          activeProgramTab={activeProgramTab}
          setPackageDetails={setPackageDetails}
          packageDetails={packageDetails}

          // price={price}
          // setUpdatePackageId={setUpdatePackageId}
          // updatePackageId={updatePackageId}
          // updateProgramFunction={updateProgramFunction}
        />
      ) : modalType === "update" ? (
        <CreateUpdatePackage
          activeType={modalType}
          show={modalShow}
          onHide={() => setModalShow(false)}
          createUpdateFunction={updatePackageWithNewData}
          setNewPackageId={setNewPackageId}
          newPackageId={newPackageId}
          name={name}
          activeProgramTab={activeProgramTab}
          oldPackageId={oldPackageId}
          setPackageDetails={setPackageDetails}
          packageDetails={packageDetails}

          // price={price}
          // setUpdatePackageId={setUpdatePackageId}
          // updatePackageId={updatePackageId}
          // updateProgramFunction={updateProgramFunction}
        />
      ) : (
        <CreateUpdatePackage
          activeType={modalType}
          show={modalShow}
          onHide={() => setModalShow(false)}
          createUpdateFunction={updatePackage}
          setNewPackageId={setNewPackageId}
          newPackageId={newPackageId}
          name={name}
          activeProgramTab={activeProgramTab}
          oldPackageId={oldPackageId}
          existPackage={existPackage}
          setPackageDetails={setPackageDetails}
          packageDetails={packageDetails}
        />
      )}
    </>
  );
};

export default ProgramPackages;
