import React from "react";
import Modal from "react-bootstrap/Modal";
import ProgramPackages from "../../updateProgram/programPackages/ProgramPackages";

const ReactModel = (props) => {
  console.log("props", props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // backdrop
      // style={{
      //   backgroundColor: "rgba(0, 0, 0, 0.5)",
      // }}
    >
      <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
      <Modal.Body>
        <ProgramPackages
          name={props.name}
          setPricingModal={props.setPricingModal}
        />
      </Modal.Body>
    </Modal>
  );
};

export default ReactModel;
