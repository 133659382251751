import React, { useEffect, useState } from "react";
import axios from "axios";
import PackageCard from "../packageCard/PackageCard";
import Loader from "react-loader-spinner";
import "./ViewAllPackageHistory.css";

const ViewAllPackageHistory = () => {
  const [allPackageHistory, setAllPackageHistory] = useState([]);
  const [selectCategory, setSelectCategory] = useState("all");
  const [Loading, setLoading] = useState(true);
  const getAllPackageDetails = () => {
    setLoading(true);
    axios
      .get(
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/customers"
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/bannerCoverImagesForAdmin"
      )
      .then((res) => {
        // console.log("data", res.data);
        setAllPackageHistory(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAllPackageDetails();
  }, []);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div
      className="ay_viewAllPackageHistory"
      style={{ width: "70vw", margin: "40px auto" }}
    >
      <div className="ay_viewAllPackageHistory-select">
        <h2>Subscription History</h2>
        {/* <div className="mb-3">
          
          <select
            class="form-select"
            aria-label="Default select example"
            value={selectCategory}
            onChange={(e) => {
              setSelectCategory(e.target.value);
            }}
          >
            <option selected value="" disabled>
              Open this select Category
            </option>
            <option value="all">All</option>
            <option value="package">Package</option>
            <option value="program">Program</option>
          </select>
        </div> */}
      </div>
      {allPackageHistory.map((item) => {
        {
          /* getUserDetails(item.packages.original_app_user_id); */
        }

        return (
          <>
            <PackageCard item={item} />
          </>
        );
      })}
    </div>
  );
};

export default ViewAllPackageHistory;
