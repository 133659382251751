import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  MillisecondsToIndianTime,
  MillisecondsToGMT,
} from "../../util/millisecToTime/MilliSecToTime";
import "./eventSubscriptionCard.css"

const EventSubscriptionCard = ({ event }) => {
  const [userData, setUserData] = useState("");
  const [userNumber, setUserNumber] = useState("");
  const [userCity, setUserCity] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userPackageName, setUserPackageName] = useState("");

  // const filterEvent = event.filter((e) => {
  //   return e.environment == "PRODUCTION";
  // });

  // console.log("filterEvent", filterEvent);
  const getUserDetails = () => {
    // setLoading(true);
    axios
      .get(
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/userDetails?userId=${event?.app_user_id}`
      )
      .then((res) => {
        console.log("user", res?.data[0]?.users);
        setUserData(res?.data[0]?.users?.userName);
        setUserNumber(res?.data[0]?.users?.mobileNo);
        setUserCity(res?.data[0]?.users?.city);
        setUserCountry(res?.data[0]?.users?.country);
        setUserPackageName(
          res?.data[0]?.users?.subscriptionDetails?.packageName
        );
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);
  return (
    <div className="ay__subscriptionCard">
      {event?.product_id?.split("_")[0] === "artyoga" ? (
        <>
          <div className="ay__subscriptionCard_leftSide">
            {/* <h5>
              Artistic Yoga Prerecorded{" "}
              {event?.product_id?.split("_")[3] === "1m:base-1month" &&
                "1 Months"}{" "}
              Subscription <span>{`(${event?.store})`}</span>
            </h5> */}

            <h5>
              {userPackageName} <span>{`(${event?.store})`}</span>
            </h5>
            <p>UserId: {event?.app_user_id} </p>
            <p>Package Id: {event?.product_id?.split("_")[1]}</p>
            <p>Name: {userData}</p>
            <p>Mobile No : {userNumber}</p>
            <p>Location : {`${userCity} , ${userCountry}`}</p>
            {/* <p>Package Id: {event?.product_id?.split("_")[1]} | Paid on</p> */}
            {/* <p>Plan Validity:</p> */}
            {/* <p>Receipt no: ON-104471 | Paid on 12 Apr 23</p> */}
            {/* <p>{`Time : ${MillisecondsToIndianTime(
              event?.event_timestamp_ms
            )}`}</p> */}
            <p>{`Event Time  : ${MillisecondsToGMT(
              event?.event_timestamp_ms
            )}`}</p>
          </div>

          <div className="ay__subscriptionCard_center">
            <p>Country Code : {event?.country_code}</p>
            <p>Currency : {event?.currency}</p>
            <p>Price : {event?.price}</p>
            <p>
              Amount Paid in Customer Currency :{" "}
              {event?.price_in_purchased_currency}
            </p>
            <p>Take Home Percentage : {event?.takehome_percentage}</p>
            <p>Tax Percentage : {event?.tax_percentage}</p>
            <p>
              purchased time in GMT :{" "}
              {`${MillisecondsToGMT(event?.purchased_at_ms)}`}
            </p>
          </div>

          <div className="ay__subscriptionCard_RightSide">
            <p>{event?.product_id?.split("_")[2]} AED</p>
          </div>
        </>
      ) : (
        <>
          <div className="ay__packageCard_leftSide">
            <h5>{event?.product_id?.split("_")[2]} Program</h5>
            <p>UserId: {event?.app_user_id} </p>
            <p>Name: {userData}</p>
            <p>Package Id: {event?.product_id?.split("_")[1]}</p>
            <p>{`Time : ${MillisecondsToIndianTime(
              event?.event_timestamp_ms
            )}`}</p>
            {/* <p>
          Package Id: {event?.product_id?.split("_")[1]} | Paid on
        </p> */}
            {/* <p>Plan Validity:</p>
        <p>Receipt no: ON-104471 | Paid on 12 Apr 23</p>
        <p>Plan Validity: 12 Apr 23 to 19 Apr 23</p> */}
          </div>
          <div className="ay__packageCard_RightSide">
            <p>{event?.product_id?.split("_")[3]} AED</p>
          </div>
        </>
      )}
    </div>
  );
};

export default EventSubscriptionCard;
