import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const UserConvert = (props) => {
  console.log("props", props);
  let modalTitle = "";
  let modalMessage = "";

  if (props.actionType === "User") {
    modalTitle = "Update Teacher";
    modalMessage = "Are you sure you want to change teacher to user?";
  } else if (props.actionType === "Teacher") {
    modalTitle = "Update User";
    modalMessage = "Are you sure you want to change user to teacher?";
  } else {
    modalTitle = "Unknown action type";
    modalMessage = "Unknown action type";
  }
  console.log("props", props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{modalMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.updateFunction(...props.updateParams)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UserConvert;
