import React, { useState, useEffect } from "react";
import "./comments.css";
import axios from "axios";
import CommentCard from "./commentCard/CommentCard";
import BlogsComment from "./blogsComment/BlogsComment";
import VideosComment from "./videosCommnent/VideosComment";
import Loader from "react-loader-spinner";

const Comments = () => {
  const [Loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [activeTab, setActiveTab] = useState(1);
  const [blogsAndVideosComments, setBlogsAndVideosComments] = useState([]);
  //   /get/blogsAndVideosCommentsAdmin

  const getBlogsAndVideosCommentsAdmin = () => {
    axios
      .get(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/blogsAndVideosCommentsAdmin`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosCommentsAdmin`
      )
      .then((res) => {
        console.log("all", res.data);
        setBlogsAndVideosComments(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const getAccessToken = async () => {
    let values = new URLSearchParams({
      username: "91-9953822945",
      password: "Saman@123",
      grant_type: "password",
    });
    console.log(values);
    let response;

    try {
      response = await axios.post(
        "https://artisticyogaikriya.com/ikriya-user-service/oauth/token",
        values.toString(),
        {
          method: "POST",
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization:
              "Basic JDJ5JDEyJDFRbnVCQmZiOWdMSlUvcU9OY21vcC5qSkJxVU5iN1NEVWNNYmVtdVdjaTZ4S1RsVXljZEU2OiQyeSQxMiRJYmlUbVFhZVF5ZURQTjBCOGRWVm8uYjJ2US95MmxXNk5QZ0wwM1RQT1M5VGhMNkEuRmQwaQ==",
          },
        }
      );
    } catch (e) {
      const {
        response: { data },
      } = e;

      if (data?.code === "UNAUTHORIZED") {
        console.log("UNAUTHORIZED");
      } else {
        console.log("Unknown server error");
      }
      return;
    }
    let acc_token = response.data.access_token;
    let token = response;
    console.log("token", acc_token, token);
    setToken(acc_token);
    // localStorage.setItem("auth", JSON.stringify(acc_token));
    // localStorage.setItem("LoginToken", JSON.stringify(token));
  };

  useEffect(() => {
    getBlogsAndVideosCommentsAdmin();
    getAccessToken();
  }, []);

  const quickReadComments = blogsAndVideosComments.filter((comment) =>
    comment.parentDocId.includes("_")
  );
  const VideoComments = blogsAndVideosComments.filter(
    (comment) => !comment.parentDocId.includes("_")
  );

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <>
      <div>
        <div className="tabs-container">
          <button
            className={activeTab === 1 ? "activeBtn tabBtn" : "tabBtn"}
            onClick={() => setActiveTab(1)}
          >
            Quick Read Comments
          </button>
          <button
            className={activeTab === 2 ? "activeBtn tabBtn" : "tabBtn"}
            onClick={() => setActiveTab(2)}
          >
            Video Comments
          </button>
        </div>

        {activeTab === 1 ? (
          <div className="comments">
            <div className="quickReadsParentContainer">
              <div className="breadcrumbcontainer mb-5">
                <div className="breadcrumb-nav1">
                  {/* <img src="/assets/rightchevron.png" alt="" /> */}
                  <span>Blogs Comments</span>
                  {/* <button className="addQuickRead">Post QuickRead</button> */}
                </div>
              </div>

              <div className="table-container">
                <table className="table">
                  <thead className="table-header">
                    <tr>
                      <th className="table-cell">Id</th>
                      <th width="100px" className="table-cell">
                        Comment
                      </th>
                      <th className="table-cell">Comment By</th>
                      <th className="table-cell">Blog Name</th>
                      <th className="table-cell">Created At</th>
                      <th className="table-cell">Details</th>
                      <th className="table-cell">Delete</th>
                      <th className="table-cell">Inappropriate</th>
                    </tr>
                  </thead>
                  {quickReadComments.map((data) => {
                    console.log("data", data);
                    return (
                      <>
                        <BlogsComment
                          data={data}
                          getBlogsAndVideosCommentsAdmin={
                            getBlogsAndVideosCommentsAdmin
                          }
                        />
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        ) : activeTab === 2 ? (
          <div className="comments">
            <div className="quickReadsParentContainer">
              <div className="breadcrumbcontainer mb-5">
                <div className="breadcrumb-nav1">
                  <span>Videos Comments</span>
                </div>
              </div>

              <div className="table-container">
                <table className="table">
                  <thead className="table-header">
                    <tr>
                      <th className="table-cell">Id</th>
                      <th width="100px" className="table-cell">
                        Comment
                      </th>
                      <th className="table-cell">Comment By</th>
                      <th className="table-cell">Video Name</th>
                      <th className="table-cell">Created At</th>
                      <th className="table-cell">Details</th>
                      <th className="table-cell">Delete</th>
                      <th className="table-cell">Inappropriate</th>
                    </tr>
                  </thead>
                  {VideoComments.map((data) => {
                    console.log("data", data);
                    return (
                      <>
                        <VideosComment
                          data={data}
                          token={token}
                          getBlogsAndVideosCommentsAdmin={
                            getBlogsAndVideosCommentsAdmin
                          }
                        />
                      </>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default Comments;
