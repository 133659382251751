import React, { useState, useEffect } from "react";
import ViewAllPackageHistory from "../viewAllPackageHistory/ViewAllPackageHistory";
import ProgramHistory from "../programHistory/ProgramHistory";
import "../Home/home.css";
import axios from "axios";
// import Loader from "react-loader-spinner";

const Subscription = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [userData, setUserData] = useState([]);

  const getUserDetails = (userId) => {
    // setLoading(true);
    axios
      .get(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/userDetails?userId=${userId}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/userDetails?userId=${userId}`
      )
      .then((res) => {
        console.log("user", res.data[0].users.userName);
        setUserData(res.data[0].users);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   getUserDetails();
  // }, []);

  return (
    <div>
      <div className="tabs-container" style={{ marginTop: "50px" }}>
        <button
          className={activeTab == 1 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(1)}
        >
          Package
        </button>
        <button
          className={activeTab == 2 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(2)}
        >
          Program
        </button>
      </div>

      {activeTab == 1 ? <ViewAllPackageHistory /> : <ProgramHistory />}
    </div>
  );
};

export default Subscription;
