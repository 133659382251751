import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
// import Swiper from "swiper";
import "swiper/css";
import "swiper/css/navigation";
// import { Navigation } from "swiper";
import { Navigation } from "swiper/modules";
import parse from "html-react-parser";
import DeleteComment from "../deleteComment/DeleteComment";
import { MillisecondsToIndianTime } from "../../../util/millisecToTime/MilliSecToTime";
import Loader from "react-loader-spinner";

const QuickReadAndVideoDetails = (props) => {
  console.log("propsData", props);
  const [Loading, setLoading] = useState(true);
  const [slideImgs, setSlideImgs] = useState([]);
  const [showTrimedText, setShowTrimedText] = useState(true);
  const [showComments, setShowComments] = useState(true);
  //   const [commentData, setCommentData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null); // Track the type of modal (delete/restore)
  const [selectedComment, setSelectedComment] = useState(null); // Store the selected comment for the modal

  const setModalShowButton = (type, comment) => {
    setModalShow(true);
    setModalType(type);
    setSelectedComment(comment);
  };

  //   const getBlogsAndVideosComments = () => {
  //     axios
  //       .get(
  //         // `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosComments?docId=8_b&userId=4194`
  //         // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/blogsAndVideosComments?docId=${id}_b&userId=4194`
  //         `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosComments?docId=${props?.state?.id}_b&userId=4194`
  //       )
  //       .then((res) => {
  //         console.log("commentData", res.data);
  //         setCommentData(res.data);
  //         setLoading(false);
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   };

  //   useEffect(() => {
  //     getBlogsAndVideosComments();
  //   }, [props?.state?.id]);

  const updateBlogsAndVideosComments = (
    parentDocId,
    docId,
    deleteValue,
    markInappropriate
  ) => {
    console.log("data", parentDocId, docId, deleteValue, markInappropriate);
    axios
      .put(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/update/blogsAndVideosComments?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/update/blogsAndVideosComments?parentDocId=${parentDocId}&docId=${docId}&isDelete=${deleteValue}&markInappropriate=${markInappropriate}`
      )
      .then((res) => {
        console.log("result", res);
        setModalShow(false);
        props.getBlogsAndVideosComments();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  //   if (Loading && props.commentData.length > 0) {
  //     return (
  //       <div className="fp-Container">
  //         <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
  //       </div>
  //     );
  //   }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      //   backdrop
      //   style={{
      //     backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.activeType === "quickRead"
            ? "Quick Read Details"
            : "Video Details"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="quickReadsPopupContainer">
          <div className="QrImageSlider">
            <Swiper
              navigation={{
                nextEl: ".nextIcon",
                prevEl: ".prevIcon",
                disabledClass: ".swiper-button-disabled",
              }}
              modules={[Navigation]}
              className="mySwiper"
              slidesPerView={1}
            >
              {slideImgs.map((item, ind) => (
                <SwiperSlide key={ind}>
                  <img className="blogTitleImages" src={item} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <div className="QrDetails">
            <div className="QRDetailsContainer">
              <div className="QrDetailsTitleDate">
                <h3>
                  {props.activeType === "quickRead"
                    ? props?.state?.title
                    : props?.state?.name}
                </h3>
                {/* <span>April 5, 2021</span> */}
              </div>

              <div className="QrDescriptionContainer">
                <div className="QrDescription">
                  {showTrimedText == true && props?.state?.desc ? (
                    <>
                      {parse((props?.state?.desc).substring(0, 700))}
                      <span onClick={() => setShowTrimedText(false)}>
                        see more...
                      </span>
                    </>
                  ) : (
                    props?.state?.desc && parse(props?.state?.desc)
                  )}
                </div>
              </div>

              {/* =====================View Comments==================== */}

              <div
                className="commentsParentcontainer"
                style={{ marginBottom: "20px" }}
              >
                <div
                  className="commentSection"
                  onClick={() => setShowComments(!showComments)}
                  style={
                    showComments == true
                      ? {
                          borderBottomLeftRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }
                      : {
                          borderRadius: "5px",
                        }
                  }
                >
                  <h4>View All Comments ({props.commentData.length})</h4>
                  <img src="/assets/chevronUp.png" alt="" />
                </div>

                {showComments == true && (
                  <div className="commentSectionContainer">
                    {props.commentData.map((item) => (
                      <div className="comments">
                        <div className="userProfile">
                          <img
                            src="/img/user.png"
                            alt=""
                            style={{ width: "20px" }}
                          />
                        </div>
                        <div className="userComment">
                          <h4 className="username">{item.userName}</h4>
                          <h4>{item.message}</h4>
                          <p>{MillisecondsToIndianTime(item.timestamp)}</p>
                        </div>

                        <div style={{ textAlign: "right" }}>
                          {item.isDelete ? (
                            <>
                              <img
                                style={{ cursor: "pointer" }}
                                src="/img/restore.png"
                                title="Restore Comment"
                                alt="image"
                                width={"20px"}
                                onClick={() => {
                                  setModalShowButton("restore", item);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <img
                                style={{ cursor: "pointer" }}
                                src="/img/delete.png"
                                alt="image"
                                title="Delete Comment"
                                width={"15px"}
                                onClick={() => {
                                  setModalShowButton("delete", item);
                                }}
                              />
                            </>
                          )}
                          {modalType === "delete" && selectedComment && (
                            <DeleteComment
                              actionType="delete"
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              comment={selectedComment}
                              updateFunction={updateBlogsAndVideosComments}
                              updateParams={[
                                selectedComment.parentDocId,
                                selectedComment.docId,
                                true,
                                null,
                              ]}
                            />
                          )}

                          {modalType === "restore" && selectedComment && (
                            <DeleteComment
                              actionType="restore"
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              comment={selectedComment}
                              updateFunction={updateBlogsAndVideosComments}
                              updateParams={[
                                selectedComment.parentDocId,
                                selectedComment.docId,
                                false,
                                null,
                              ]}
                            />
                          )}
                        </div>

                        <div style={{ textAlign: "right" }}>
                          {item?.markInappropriate ? (
                            <img
                              style={{ cursor: "pointer" }}
                              src="/img/checked.png"
                              // title="Mark Appropriate QNA"
                              alt="image"
                              width={"20px"}
                              onClick={() => {
                                setModalShowButton("appropriate", item);
                              }}
                            />
                          ) : (
                            <img
                              style={{ cursor: "pointer" }}
                              src="/img/report.png"
                              // title="Mark Inappropriate QNA"
                              alt="image"
                              width={"20px"}
                              onClick={() => {
                                setModalShowButton("inAppropriate", item);
                              }}
                            />
                          )}
                          {modalType === "appropriate" && selectedComment && (
                            <DeleteComment
                              actionType="appropriate"
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              comment={selectedComment}
                              updateFunction={updateBlogsAndVideosComments}
                              updateParams={[
                                selectedComment.parentDocId,
                                selectedComment.docId,
                                null,
                                false,
                              ]}
                            />
                          )}
                          {modalType === "inAppropriate" && selectedComment && (
                            <DeleteComment
                              actionType="inAppropriate"
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              comment={selectedComment}
                              updateFunction={updateBlogsAndVideosComments}
                              updateParams={[
                                selectedComment.parentDocId,
                                selectedComment.docId,
                                null,
                                true,
                              ]}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={() => props.updatefunction(...props.updateParams)}>
          Ok
        </Button> */}
        {/* <Button>Ok</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default QuickReadAndVideoDetails;
