import React, { useState, useEffect } from "react";
import "./teachersDisplay.css";
import axios from "axios";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "react-loader-spinner";
import TeacherPopup from "../popup/teacherPopup/TeacherPopup";

const TeachersDisplay = () => {
  const [Loading, setLoading] = useState(true);
  const [teacherList, setTeacherList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [teacher, setTeacher] = useState(null);

  const setModalShowButton = (type, teacherData) => {
    setModalShow(true);
    setModalType(type);
    setTeacher(teacherData);
  };

  const onChangeChecked = (teacher) => {
    setChecked(!checked);
    updateTeacher(teacher);
  };

  const updateTeacher = async (teacher) => {
    // e.preventDefault();
    console.log("teacherteacher", teacher);
    const obj = {
      docId: teacher.docId,
      name: teacher.name,
      image: teacher.image,
      isActive: checked,
    };
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/createUpdate/teachersList",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/createUpdate/teachersList",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result2121", result);
        getTeachersList();
        // alert("bannerImage added Successfully");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const updateTeacherNew = async (docId, name, image, isActive) => {
    // e.preventDefault();
    const obj = {
      docId,
      name,
      image,
      isActive,
    };
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/createUpdate/teachersList",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/createUpdate/teachersList",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result2121", result);
        getTeachersList();
        // alert("bannerImage added Successfully");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getTeachersList = () => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/teachersList"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/teachersList"
      )
      .then((res) => {
        console.log(res.data);
        setTeacherList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    getTeachersList();
  }, []);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }
  return (
    <div className="ay__programBatchesDisplay ">
      <div className="ay__programBatchesDisplay-header">
        <span>Teachers</span>

        <button
          onClick={() => {
            setModalShowButton("create");
          }}
          className="addQuickRead"
        >
          Create Teachers
        </button>
      </div>

      <div className="table-container">
        <table className="table">
          <thead className="table-header">
            <tr>
              {/* <th className="table-cell">Index</th> */}
              <th className="table-cell">Teacher Image</th>
              <th className="table-cell">Name</th>
              <th className="table-cell">Action</th>
              <th className="table-cell">Edit</th>
            </tr>
          </thead>
          <tbody>
            {teacherList.map((teacher, index) => (
              <tr
                className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
                key={index}
                style={{ height: "50px" }}
              >
                {/* <td className="table-cell">{index + 1}</td> */}
                <td className="table-cell">
                  <img src={teacher?.image} alt="teachers" />
                </td>
                <td className="table-cell">{teacher?.name}</td>

                <td className="table-cell">
                  <div className="quickReadsSwitch batchesSwitchs">
                    <label class="switch">
                      <div
                        className="checkbox_container"
                        onChange={() => {
                          onChangeChecked(teacher);
                        }}
                      >
                        <input type="checkbox" />
                        <span
                          class="slider round"
                          style={
                            teacher?.isActive === true
                              ? { backgroundColor: "green" }
                              : { backgroundColor: "red" }
                          }
                        ></span>
                        <div
                          className="round_circle"
                          style={
                            teacher?.isActive === true
                              ? { right: "4px", transition: "2s ease-in" }
                              : { left: "4px", transition: "2s ease-in" }
                          }
                        ></div>
                      </div>
                    </label>
                    <span style={{ color: "black" }}>
                      {true ? "is Active" : "not Active"}
                    </span>
                  </div>
                </td>

                <td>
                  <EditIcon
                    onClick={() => {
                      setModalShowButton("update", teacher);
                    }}
                    style={{ cursor: "pointer", color: "black" }}
                  />
                </td>
                {/* <td className="table-cell">{index + 1}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
        {modalType === "update" && teacher && (
          <TeacherPopup
            actionType="update"
            show={modalShow}
            onHide={() => setModalShow(false)}
            // comment={selectedComment}
            updateFunction={updateTeacherNew}
            updateParams={[teacher, teacher.docId]}
          />
        )}

        {modalType === "create" && (
          <TeacherPopup
            actionType="create"
            show={modalShow}
            onHide={() => setModalShow(false)}
            // comment={selectedComment}
            // updateFunction={updateTeacherNew}
            // updateParams={[teacher, teacher.docId]}
          />
        )}
      </div>
    </div>
  );
};

export default TeachersDisplay;
