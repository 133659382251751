import "./ayProgramBatches.css";
import React, { useState, useEffect } from "react";
import axios from "axios";

import { useNavigate } from "react-router-dom";

const AyProgramBatches = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [disableLoginButton, setDisableLoginButton] = useState(false);

  const [program, setProgram] = useState("");
  const [mode, setMode] = useState("");
  const [numberOfSlots, setNumberOfSlots] = useState(null);
  const [numberOccupied, setNumberOccupied] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [availableAt, setAvailableAt] = useState(null);

  const handleNotificationError = () => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const dateObj = new Date(inputDate);
    const day = dateObj.getDate();
    const month = months[dateObj.getMonth()];
    const year = dateObj.getFullYear();

    return `${day} ${month} ${year}`;
  }

  const handleUpload = async (e) => {
    e.preventDefault();

    if (!program || !mode || !startDate) {
      setErrorMessage("Enter all details");
      return;
    }

    const obj = {
      program,
      packageId: 513,
      mode,
      numberOfSlots,
      numberOccupied,
      startDate: formatDate(startDate),
      availableAt,
    };
    setDisableLoginButton(true);
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/createUpdate/programBatches",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/createUpdate/programBatches",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status == "success") {
          console.log("result", result);

          alert("programBatch added successfully");
          setDisableLoginButton(false);
          navigate("/programBatchesDisplay");
        }
      })
      .catch((error) => {
        setErrorMessage(error);
        console.log("error", error);
      });
  };

  return (
    <div className="Apps mb-3">
      <div className="breadcrumbcontainer">
        <div className="breadcrumb-nav">
          <img src="/assets/rightchevron.png" alt="" />
          <span className="submitBtn">Program Batches </span>
        </div>
      </div>

      <form className="mb-3">
        <div className="mb-3">
          <label htmlFor="Program" className="form-label">
            Program
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            value={program}
            onChange={(e) => {
              handleNotificationError();
              setProgram(e.target.value);
            }}
          >
            <option selected value="" disabled>
              Select Program
            </option>
            <option value="Rapid">Rapid</option>
            <option value="SuperFast">Super Fast</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="mode" className="form-label">
            Mode
          </label>
          <select
            class="form-select"
            aria-label="Default select example"
            value={mode}
            onChange={(e) => {
              handleNotificationError();
              setMode(e.target.value);
            }}
          >
            <option selected value="" disabled>
              Select Mode
            </option>
            <option value="Studio">Studio</option>
            <option value="Online">Online</option>
          </select>
        </div>

        {mode == "Studio" && (
          <div className="mb-3">
            <label htmlFor="availableAt" className="form-label">
              Available At
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              value={availableAt}
              onChange={(e) => {
                handleNotificationError();
                setAvailableAt(e.target.value);
              }}
            >
              <option selected value="" disabled>
                Select Studio
              </option>
              <option value="bur dubai">Bur Dubai</option>
              <option value="motor city">Motor City</option>
              <option value="al qusais">Al Qusais</option>
              <option value="emirates">Emirates Golf Club</option>
            </select>
          </div>
        )}

        <div className="mb-3">
          <label htmlFor="NumberOfSlots" className="form-label">
            Number of Slots
          </label>
          <input
            type="number"
            className="form-control"
            id="NumberOfSlots"
            aria-describedby="NumberOfSlots"
            value={numberOfSlots}
            onChange={(e) => {
              handleNotificationError();
              setNumberOfSlots(e.target.value);
            }}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="numberOccupied" className="form-label">
            Number Occupied
          </label>
          <input
            type="number"
            className="form-control"
            id="numberOccupied"
            aria-describedby="numberOccupied"
            value={numberOccupied}
            onChange={(e) => {
              handleNotificationError();
              setNumberOccupied(e.target.value);
            }}
            required
          />
        </div>

        <div className="mb-3">
          <label htmlFor="startDate" className="form-label">
            Start Date
          </label>
          <input
            type="date"
            className="form-control"
            id="startDate"
            aria-describedby="startDate"
            value={startDate}
            onChange={(e) => {
              handleNotificationError();
              setStartDate(e.target.value);
            }}
            required
          />
        </div>

        <p className="Login_error">{errorMessage}</p>
        <button
          disabled={disableLoginButton}
          type="submit"
          className="btn btn-primary"
          onClick={handleUpload}
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default AyProgramBatches;
