export const MillisecondsToIndianTime = (milliseconds) => {
  if (!milliseconds) {
    return "Invalid Timestamp";
  }

  // Convert the timestamp to a number (in case it's a string)
  const timestamp = Number(milliseconds);

  if (isNaN(timestamp)) {
    return "Invalid Timestamp";
  }

  // Create a new Date object
  const date = new Date(timestamp);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Options for formatting the Indian time
  const options = {
    timeZone: "Asia/Kolkata", // Indian Standard Time (IST)
    //   weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    //   second: "numeric",
  };

  // Format the date to Indian time
  const indianTime = date.toLocaleString("en-IN", options);
  return indianTime;
};

export const MillisecondsToGMT = (milliseconds) => {
  if (!milliseconds) {
    return "Invalid Timestamp";
  }

  // Convert the timestamp to a number (in case it's a string)
  const timestamp = Number(milliseconds);

  if (isNaN(timestamp)) {
    return "Invalid Timestamp";
  }

  // Create a new Date object
  const date = new Date(timestamp);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Options for formatting the time in GMT
  const options = {
    timeZone: "UTC", // Coordinated Universal Time (UTC)
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  // Format the date to GMT
  const gmtTime = date.toLocaleString("en-US", options);
  return gmtTime;
};
