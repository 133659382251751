import React, { useState, useEffect } from "react";
import "./quickReadPreview.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// import { Navigation } from "swiper";
import { Navigation } from "swiper/modules";

const QuickReadPreview = (props) => {
  // console.log("images", props.images);

  const carouselImages = [];

  // Loop through the imageAssets object
  // for (const slideName in props.images) {
  //   console.log("slideName", slideName);
  //   if (props.images.hasOwnProperty(slideName)) {
  //     const slideImages = props.images[slideName];
  //     if (Array.isArray(slideImages) && slideImages.length > 0) {
  //       // Push the first image URL from each slide to the carouselImages array
  //       carouselImages.push(slideImages[0]);
  //     }
  //   }
  // }
  {
    props.images != null &&
      props.images?.forEach((imageObject) => {
        // Iterate over the keys in each object
        for (const slideName in imageObject) {
          if (imageObject.hasOwnProperty(slideName)) {
            const slideImages = imageObject[slideName];
            // Check if slideImages is an array and has at least one URL
            if (Array.isArray(slideImages) && slideImages.length > 0) {
              // Push the first image URL from each slide to carouselImages
              carouselImages.push(slideImages[3]);
            }
          }
        }
      });
  }
  // console.log("carouselImages", carouselImages);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        margin: "auto",
        // width: "375px",
        // color: "white",
      }}
      // onHide={clearData}
    >
      <Modal.Header
        style={{
          border: "none",
        }}
        closeButton
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          Quick Read Preview
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="quickReadPreview__body">
        <div
        // style={{
        //   width: "375px",
        //   margin: "0 auto",
        // }}
        >
          <div className="QrImageSliders">
            <Swiper
              navigation={{
                nextEl: ".nextIcon",
                prevEl: ".prevIcon",
                disabledClass: ".swiper-button-disabled",
              }}
              modules={[Navigation]}
              className="mySwiper"
              slidesPerView={1}
            >
              {carouselImages.map((item, ind) => (
                <SwiperSlide key={ind}>
                  <img
                    style={{
                      width: "100%",
                      // height: "375px",
                      // objectFit: "contain",
                    }}
                    className="blogTitleImage"
                    src={item}
                    alt="img"
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <h3 className="quickReadPreview__title">{props.title}</h3>
          <div
            className="quickReadPreview__content"
            dangerouslySetInnerHTML={{ __html: props.desc }}
          ></div>
        </div>
      </Modal.Body>

      {/* <Modal.Footer>
          
        </Modal.Footer> */}
    </Modal>
  );
};

export default QuickReadPreview;
