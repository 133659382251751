import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const TeacherPopup = (props) => {
  console.log("props", props);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      //   backdrop
      //   style={{
      //     backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.actionType === "update" ? "Update Teacher" : "Create Teacher"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label htmlFor="subtitle" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="subtitle"
              aria-describedby="subtitle"
              value={props.updatePackageId}
              //   onChange={(e) => {
              //     props.setUpdatePackageId(e.target.value);
              //   }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="file" className="form-label">
              Teacher image
            </label>
            <input
              type="file"
              className="form-control"
              id="file"
              aria-describedby="file"
              // onChange={handleChange}
              required
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
        //   onClick={() => props.updateProgramFunction(props.updatePackageId)}
        >
          {props.actionType === "update" ? "Update" : "Create"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TeacherPopup;
