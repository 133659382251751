import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const DeleteComment = (props) => {
  console.log("props", props);

  let modalTitle = "";
  let modalMessage = "";

  if (props.actionType === "delete") {
    modalTitle = "Delete comment";
    modalMessage = "Are you sure you want to delete this comment?";
  } else if (props.actionType === "restore") {
    modalTitle = "Restore comment";
    modalMessage = "Are you sure you want to restore this comment?";
  } else if (props.actionType === "appropriate") {
    modalTitle = "Appropriate comment";
    modalMessage = "Are you sure you want to appropriate this comment?";
  } else if (props.actionType === "inAppropriate") {
    modalTitle = "InAppropriate comment";
    modalMessage = "Are you sure you want to InAppropriate this comment?";
  } else if (props.actionType === "restoreQNA") {
    modalTitle = "Restore Question";
    modalMessage = "Are you sure you want to restore this Question?";
  } else if (props.actionType === "deleteQNA") {
    modalTitle = "Delete Question";
    modalMessage = "Are you sure you want to delete this Question?";
  } else if (props.actionType === "appropriateQNA") {
    modalTitle = "appropriate Question";
    modalMessage = "Are you sure you want to appropriate this Question?";
  } else if (props.actionType === "inAppropriateQNA") {
    modalTitle = "inAppropriate Question";
    modalMessage = "Are you sure you want to inAppropriate this Question?";
  } else {
    modalTitle = "Unknown action type";
    modalMessage = "Unknown action type";
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      // backdrop="static"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{modalMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.updateFunction(...props.updateParams)}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
    // <Modal
    //   {...props}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       {props.actionType === "restore" ? (
    //         <div>Restore comment UI</div>
    //       ) : props.actionType === "delete" ? (
    //         <div>Delete comment UI</div>
    //       ) : (
    //         <div>Unknown action type</div>
    //       )}
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     <p>
    //       {props.actionType === "delete"
    //         ? "Are you sure you want to delete this comment?"
    //         : props.actionType === "restore"
    //         ? "Are you sure you want to restore this comment?"
    //         : "Unknown action type"}
    //     </p>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button>Yes</Button>
    //   </Modal.Footer>
    // </Modal>

    // <Modal
    //   {...props}
    //   size="lg"
    //   aria-labelledby="contained-modal-title-vcenter"
    //   centered
    //   //   backdrop
    //   //   style={{
    //   //     backgroundColor: "rgba(0, 0, 0, 0.5)",
    //   //   }}
    // >
    //   <Modal.Header closeButton>
    //     <Modal.Title id="contained-modal-title-vcenter">
    //       {props.actionType === "delete" ? (
    //         <div>Delete comment UI</div>
    //       ) : props.actionType === "restore" ? (
    //         <div>Restore comment UI</div>
    //       ) : (
    //         <div>Unknown action type</div>
    //       )}
    //     </Modal.Title>
    //   </Modal.Header>
    //   <Modal.Body>
    //     {/* <h4>Centered Modal</h4> */}
    //     <p>Are you sure you want to delete this comment?</p>
    //   </Modal.Body>
    //   <Modal.Footer>
    //     <Button
    //     //   onClick={() => props.updateProgramFunction(props.updatePackageId)}
    //     >
    //       Yes
    //     </Button>
    //   </Modal.Footer>
    // </Modal>
  );
};

export default DeleteComment;
