import React, { useState } from "react";
import Subsciption from "../subscription/Subsciption";
import Product from "../product/Product";

const InAppPurchase = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div>
      <div className="tabs-container">
        <button
          className={activeTab === 1 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(1)}
        >
          Subscription
        </button>
        <button
          className={activeTab === 2 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(2)}
        >
          {/* Product */}
          product
        </button>
      </div>

      {/* <div className="program-price-container2 tabs-container">
        <div
          className={
            activeTab === 1
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(1)}
        >
          <p style={{ margin: "1rem" }}>Daily Class</p>
        </div>

        <div
          className={
            activeTab === 2
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(2)}
        >
          <p style={{ margin: "1rem" }}>Popular Classes</p>
        </div>

        <div
          className={
            activeTab === 3
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(3)}
        >
          <p style={{ margin: "1rem" }}>In-App Purchase</p>
        </div>
      </div> */}

      {activeTab === 1 ? <Subsciption /> : activeTab === 2 ? <Product /> : ""}
    </div>
  );
};

export default InAppPurchase;
