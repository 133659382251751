import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Radium from "radium";
import EditIcon from "@mui/icons-material/Edit";
import Table from "react-bootstrap/Table";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const AyBannerImageDisplay = () => {
  const navigate = useNavigate();
  const [ayBannerImages, setAyBannerImages] = useState([]);
  const [checked, setChecked] = useState(false);
  const [Loading, setLoading] = useState(true);
  // const handleChange = ({ id }) => {
  //   setChecked(!checked);
  // };

  const onChangeChecked = (item) => {
    setChecked(!checked);
    updateAyBannerImage(item);
  };
  //  };

  const moreDetails = (id, item) => {
    navigate(id, {
      state: item,
    });
  };

  const updateCoverImageId = async (id, rank) => {
    const obj = {
      docId: id,
      coverImageId: rank,
    };
    await fetch(
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/Update/bannerCoverImagesId",
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/Update/bannerCoverImagesId",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        getbannerData();
      })
      .catch((error) => {
        console.log("error", error);
      });
    // setModalShow(false);
  };

  const updateAyBannerImage = async (item) => {
    // e.preventDefault();
    console.log("item", item);
    const obj = {
      coverImageId: item?.bannerCoverImages?.coverImageId,
      coverImageText: item?.bannerCoverImages?.coverImageText,
      imageUrl: item?.bannerCoverImages?.imageUrl,
      isActive: checked,
      isGlobal: true,
      type: item?.bannerCoverImages?.type,
    };
    // await fetch("http://3.110.120.103:9007/magicFood/createQuickReads/", {
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/createUpdate/bannerCoverImages",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/createUpdate/bannerCoverImages",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        // alert("bannerImage added Successfully");
        getbannerData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getbannerData = () => {
    axios
      .get(
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/bannerCoverImagesForAdmin"
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/bannerCoverImagesForAdmin"
      )
      .then((res) => {
        // console.log(res);
        setAyBannerImages(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getbannerData();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index - 1;
    const destinationIndex = result.destination.index - 1;

    if (sourceIndex === destinationIndex) return;

    const reorderedAyBannerImages = [...ayBannerImages];

    const tempItem = reorderedAyBannerImages[sourceIndex];
    reorderedAyBannerImages[sourceIndex] =
      reorderedAyBannerImages[destinationIndex];
    reorderedAyBannerImages[destinationIndex] = tempItem;

    // console.log("reorderedAyBannerImages", reorderedAyBannerImages);

    reorderedAyBannerImages.forEach((item, index) => {
      console.log(item);
      updateCoverImageId(item.docId, index + 1);
    });

    setAyBannerImages(reorderedAyBannerImages);
  };

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }
  return (
    <>
      <div className="QuickReads">
        <div className="quickReadsParentContainer">
          {/* ==================BreadCrumb Nav=================== */}
          <div className="breadcrumbcontainer mb-5">
            <div className="breadcrumb-nav1">
              {/* <img src="/assets/rightchevron.png" alt="" /> */}
              <span>AyBannerImage</span>
              <Link to={"/ayBannerImage"}>
                <button className="addQuickRead">Post Banner image</button>
              </Link>
            </div>
          </div>

          <Table striped hover>
            <thead>
              <tr>
                <th>id</th>
                <th>Image</th>
                <th>Name</th>
                <th>Actions</th>
                <th>Edit</th>
              </tr>
            </thead>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="bannerImage">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {ayBannerImages.map((item, index) => (
                      <Draggable
                        key={item?.bannerCoverImages?.coverImageId?.toString()}
                        draggableId={item?.bannerCoverImages?.coverImageId?.toString()}
                        index={index + 1}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>{index + 1}</td>
                            <td>
                              <img
                                width="100px"
                                src={item?.bannerCoverImages?.imageUrl}
                                alt=""
                              />
                            </td>
                            <td>
                              {item?.bannerCoverImages?.coverImageText
                                ? item?.bannerCoverImages?.coverImageText
                                : "Null"}
                            </td>
                            <td>
                              <div className="quickReadsSwitch">
                                <label class="switch">
                                  <div
                                    className="checkbox_container"
                                    onChange={() => {
                                      onChangeChecked(item);
                                    }}
                                  >
                                    <input
                                      type="checkbox"
                                      // onChange={() => {
                                      //   onChangeChecked(item);
                                      // }}
                                    />
                                    <span
                                      class="slider round"
                                      style={
                                        item?.bannerCoverImages?.isActive ==
                                        true
                                          ? { backgroundColor: "green" }
                                          : { backgroundColor: "red" }
                                      }
                                    ></span>
                                    <div
                                      className="round_circle"
                                      style={
                                        item?.bannerCoverImages?.isActive ==
                                        true
                                          ? {
                                              right: "4px",
                                              transition: "2s ease-in",
                                            }
                                          : {
                                              left: "4px",
                                              transition: "2s ease-in",
                                            }
                                      }
                                    ></div>
                                  </div>
                                </label>
                                <span style={{ color: "black" }}>
                                  {item?.bannerCoverImages?.isActive == true
                                    ? "is Active"
                                    : "not Active"}
                                </span>
                              </div>
                            </td>
                            <td>
                              <EditIcon
                                onClick={() =>
                                  moreDetails(
                                    `/updateAyBannerImage/${item?.bannerCoverImages?.docId}`,
                                    item?.bannerCoverImages
                                  )
                                }
                                style={{ cursor: "pointer", color: "black" }}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Radium(AyBannerImageDisplay);
