import React, { useState } from "react";
import "./userDetail.css"; // Import CSS file for styling

function UserDetail() {
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");
  const [disableLoginButton, setDisableLoginButton] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setDisableLoginButton(true);
      const response = await fetch(
        "https://artisticyogaikriya.com/offers/offers/user/detail",
        {
          method: "POST", // specify the HTTP method
          headers: {
            "Content-Type": "application/json", // specify the content type
          },
          body: JSON.stringify({
            mobile: phoneNumber,
            countryCode: countryCode,
          }), // stringify the body object
        }
      );
      const data = await response.json();
      if (data?.data == null) {
        setError(data?.message);
        setDisableLoginButton(false);
      } else {
        console.log("data", data);
        setUserData(data);
        setError("");
        setDisableLoginButton(false);
      }
    } catch (error) {
      setError("Error fetching user details. Please try again.");
    }
  };

  return (
    <div className="userDetail">
      <h2>User Details Form</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Country Code:</label>
          <input
            type="text"
            value={countryCode}
            onChange={(e) => {
              setCountryCode(e.target.value);
              setError("");
              setUserData(null);
            }}
            required
          />
        </div>
        <div>
          <label>Phone Number:</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setError("");
              setUserData(null);
            }}
            required
          />
        </div>
        <button
          disabled={disableLoginButton}
          className="submitBtnUser"
          type="submit"
        >
          Submit
        </button>

        {/* <input
          //   disabled={disableLoginButton}
          className="submitBtn"
          type="submit"
          value="Submit"
        ></input> */}
      </form>

      {userData?.data && (
        <div className="user-details">
          <h2>User Details</h2>
          <p>Id: {userData?.data?.id}</p>
          <p>Name: {userData?.data?.firstName}</p>
          <p>
            Number: {`${userData.data?.countryCode}-${userData?.data?.mobile}`}
          </p>
          <p>Email: {userData.data?.email}</p>
          {/* Add more details as needed */}
        </div>
      )}

      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default UserDetail;
