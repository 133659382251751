import React, { useState, useEffect } from "react";
import "./login.css";
import firebase from "../../Firebase/FirebaseConfig";
import { useNavigate } from "react-router-dom";
const auth = firebase.auth();

const Login = ({ setLocalStorage }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [disableLoginButton, setDisableLoginButton] = useState(false);
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState("eye-slash-fill");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   if (!username || !password) {
  //     setErrorMessage("Enter login details");
  //     return;
  //   }

  //   try {
  //     setDisableLoginButton(true);

  //     // Sign in the user
  //     await auth.signInWithEmailAndPassword(username, password);

  //     const user = auth.currentUser;
  //     const userDetails = {
  //       username: user.email,
  //       loginTime: new Date(),
  //     };

  //     const db = firebase.firestore();
  //     let userData = await db
  //       .collection("adminDetails")
  //       .where("email", "==", username)
  //       .get();

  //     if (!userData.empty) {
  //       const userDoc = userData.docs[0].data();

  //       window.localStorage.setItem(
  //         "myData",
  //         JSON.stringify({ username, password, role: userDoc.role })
  //       );

  //       // Retrieve and set the user data from localStorage
  //       const value = JSON.parse(window.localStorage.getItem("myData"));
  //       setLocalStorage(value);
  //     }

  //     setUsername("");
  //     setPassword("");
  //     navigate("/home");
  //   } catch (error) {
  //     setErrorMessage(
  //       "The password is invalid or the user does not have a password."
  //     );
  //   } finally {
  //     setDisableLoginButton(false);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username || !password) {
      setErrorMessage("Enter login details");
      return;
    }

    try {
      setDisableLoginButton(true);

      // Check if the email exists in Firestore with the exact case
      const db = firebase.firestore();
      let userData = await db
        .collection("adminDetails")
        .where("email", "==", username)
        .get();

      if (!userData.empty) {
        const userDoc = userData.docs[0].data();

        // Sign in the user only if the email case matches exactly
        if (userDoc.email === username) {
          await auth.signInWithEmailAndPassword(username, password);

          const user = auth.currentUser;
          const userDetails = {
            username: user.email,
            loginTime: new Date(),
          };

          window.localStorage.setItem(
            "myData",
            JSON.stringify({ username, password, role: userDoc.role })
          );

          // Retrieve and set the user data from localStorage
          const value = JSON.parse(window.localStorage.getItem("myData"));
          setLocalStorage(value);

          setUsername("");
          setPassword("");
          navigate("/home");
        } else {
          setErrorMessage("Invalid email or password");
        }
      } else {
        setErrorMessage("Invalid email or password");
      }
    } catch (error) {
      setErrorMessage(
        "The password is invalid or the user does not have a password."
      );
    } finally {
      setDisableLoginButton(false);
    }
  };

  const handleNotificationError = () => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const togglePasswordVisibility = () => {
    if (type === "password") {
      setIcon("eye-fill");
      setType("text");
    } else {
      setIcon("eye-slash-fill");
      setType("password");
    }
  };

  useEffect(() => {
    window.localStorage.clear();
    setLocalStorage(null);
  }, []);
  // const handleLogOut = () => {
  //   FirebaseAuthService.logoutUser();
  // };

  // const handleLoginWithGoogle = async () => {
  //   try {
  //     await FirebaseAuthService.loginWithGoogle();
  //   } catch (error) {
  //     alert(error.message);
  //   }
  // };

  return (
    // <div className="login-form-container">
    //   <form onSubmit={handleSubmit} className="login-form">
    //     <label className="input-label login-label">
    //       Username (email)
    //       <input
    //         type="email"
    //         required
    //         value={username}
    //         onChange={(e) => {
    //           setUsername(e.target.value);
    //         }}
    //         className="input-text"
    //       />
    //     </label>

    //     <label className="input-label login-label">
    //       Password
    //       <input
    //         type="password"
    //         required
    //         value={password}
    //         onChange={(e) => {
    //           setPassword(e.target.value);
    //         }}
    //         className="input-text"
    //       />
    //     </label>
    //     <div className="button-box">
    //       <button type="submit" className="primary-button">
    //         Login
    //       </button>
    //     </div>
    //   </form>
    // </div>

    <div className="center">
      <h1>AY Admin</h1>
      <form onSubmit={handleSubmit}>
        <div className="txt-field">
          <input
            placeholder="Email"
            type="email"
            // required
            name="email"
            value={username}
            onChange={(e) => {
              handleNotificationError();
              setUsername(e.target.value);
            }}
          ></input>
        </div>

        <div className="txt-field">
          <input
            placeholder="password"
            type={type}
            name="password"
            value={password}
            onChange={(e) => {
              handleNotificationError();
              setPassword(e.target.value);
            }}
          ></input>
          <img
            onClick={togglePasswordVisibility}
            // src={`/img/${icon}.svg`}
            src={`/img/${icon}.svg`}
            alt="rsa"
            style={{
              position: "absolute",
              top: "10px",
              right: "8px",
              cursor: "pointer",
            }}
          />
        </div>
        <p className="Login_error">{errorMessage}</p>

        <input
          disabled={disableLoginButton}
          className="submitBtn"
          type="submit"
          value="Submit"
        ></input>
      </form>
    </div>
  );
};

export default Login;
