import React, { useState, useEffect } from "react";
// import "./qnaDisplay.css";
import { Link, useNavigate } from "react-router-dom";

import axios from "axios";
import Table from "react-bootstrap/Table";
// import EditIcon from "@mui/icons-material/Edit";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const Faq = () => {
  const [faq, setFaq] = useState([]);
  const [Loading, setLoading] = useState(true);

  //   const navigate = useNavigate();

  //   const moreDetails = (id, item) => {
  //     navigate(id, {
  //       state: item,
  //     });
  //   };

  const getFAQ = () => {
    // setLoading(true);
    axios
      .get(
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/faq"
      )
      .then((res) => {
        console.log("faq", res.data);
        setFaq(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateCoverImageId = async (id, rank) => {
    const obj = {
      docId: id,
      coverImageId: rank,
    };
    await fetch(
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/Update/bannerCoverImagesId",
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/Update/bannerCoverImagesId",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        // getbannerData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getFAQ();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index - 1;
    const destinationIndex = result.destination.index - 1;

    if (sourceIndex === destinationIndex) return;

    const reorderedAyBannerImages = [...faq];

    const tempItem = reorderedAyBannerImages[sourceIndex];
    reorderedAyBannerImages[sourceIndex] =
      reorderedAyBannerImages[destinationIndex];
    reorderedAyBannerImages[destinationIndex] = tempItem;

    reorderedAyBannerImages.forEach((item, index) => {
      console.log(item);
      //   updateCoverImageId(item.docId, index + 1);
    });
    setFaq(reorderedAyBannerImages);
  };

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div className="QNA">
      <div className="qnaParentContainer">
        <div className="qna_header breadcrumbcontainer mb-5">
          <div className="breadcrumb-nav1">
            <span> FAQ</span>
          </div>
          <Link to={"/faq"}>
            <button className="addQuickRead">Post Faq</button>
          </Link>
        </div>

        <div className="table-container">
          <table className="table">
            <thead className="table-header">
              <tr>
                <th className="table-cell">Image</th>
                <th className="table-cell">Question</th>
                <th className="table-cell">Answer</th>
                <th className="table-cell">isActive</th>
              </tr>
            </thead>

            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="bannerImage">
                {(provided) => (
                  <tbody {...provided.droppableProps} ref={provided.innerRef}>
                    {faq.map((item, index) => (
                      <Draggable
                        key={item?.displayRank?.toString()}
                        draggableId={item?.displayRank?.toString()}
                        index={index + 1}
                      >
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`table-row ${
                              index % 2 === 0 ? "even" : "odd"
                            }`}
                            style={{ height: "50px" }}
                          >
                            <td className="table-cell">
                              {item?.imageAsset ? (
                                <img
                                  alt="image"
                                  style={{
                                    width: "120px",
                                    height: "120px",
                                    objectFit: "contain",
                                  }}
                                  src={item?.imageAsset}
                                />
                              ) : (
                                "Null"
                              )}
                            </td>
                            <td className="table-cell">{item?.question}</td>
                            <td className="table-cell">{item?.answer}</td>
                            <td className="table-cell">
                              {item?.isActive ? "True" : "False"}
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Faq;
