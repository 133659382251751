import React, { useState } from "react";
import DailyClass from "./dailyClass/DailyClass";
import PopularClassesDisplay from "./popularClassesDisplay/PopularClassesDisplay";
import InAppPurchase from "./inAppPurchase/InAppPurchase";
import Faq from "./faq/Faq";

const AppContent = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div>
      {/* <div className="tabs-container">
        <button
          className={activeTab === 1 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(1)}
        >
          Daily Class
        </button>
        <button
          className={activeTab === 2 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(2)}
        >
          Popular Classes
        </button>
        <button
          className={activeTab === 3 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(3)}
        >
          Revenucat
        </button>
      </div> */}

      <div className="program-price-container2 tabs-container">
        <div
          className={
            activeTab === 1
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(1)}
        >
          <p style={{ margin: "1rem" }}>Daily Class</p>
        </div>

        <div
          className={
            activeTab === 2
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(2)}
        >
          <p style={{ margin: "1rem" }}>Popular Classes</p>
        </div>

        <div
          className={
            activeTab === 3
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(3)}
        >
          <p style={{ margin: "1rem" }}>In-App Purchase</p>
        </div>

        <div
          className={
            activeTab === 4
              ? "activeProgramTab program-tab-container"
              : "program-tab-container"
          }
          onClick={() => setActiveTab(4)}
        >
          <p style={{ margin: "1rem" }}>FAQ</p>
        </div>
      </div>

      {activeTab === 1 ? (
        <DailyClass />
      ) : activeTab === 2 ? (
        <PopularClassesDisplay />
      ) : activeTab === 3 ? (
        <InAppPurchase />
      ) : activeTab === 4 ? (
        <Faq />
      ) : (
        ""
      )}
    </div>
  );
};

export default AppContent;
