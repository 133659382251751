import React, { useState, useEffect } from "react";
import axios from "axios";
import "./blogCommentsDisplay.css";

const BlogCommentsDisplay = () => {
  const [blogComments, setBlogComments] = useState([]);

  const blogCommentsData = () => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/blogsAndVideosCommentsAdmin"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/blogsAndVideosCommentsAdmin"
      )
      .then((res) => {
        console.log("res", res.data);
        setBlogComments(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  //   useEffect(() => {
  //     // axios.get("https://qrs.btartisticyoga.com/magicFood/getAllActiveQuickReads/").
  //     axios
  //       .get(
  //         `https://qrs.btartisticyoga.com/magicFood/getQuickReadDetails?Id=${quickReadData}`
  //       )
  //       .then((res) => {
  //         console.log(res);
  //         setQuickReads(res.data.data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }, []);

  useEffect(() => {
    blogCommentsData();
  }, []);
  console.log("blogComments", blogComments);

  return (
    <div className="BlogCommentsDisplay">
      <div className="breadcrumbcontainer mb-5">
        <div className="breadcrumb-nav1">
          <span>Blogs</span>
        </div>

        <div className="blog_container-img">
          {blogComments.map((comment, index) => {
            return (
              <img
                key={index}
                src="/img/AyCalm.png"
                alt={`Comment ${index}`}
                className="blog_image"
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default BlogCommentsDisplay;
