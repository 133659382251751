import React, { useState, useEffect } from "react";
import "./programBatchesDisplay.css";
import axios from "axios";
import { Link } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "react-loader-spinner";

const ProgramBatchesDisplay = () => {
  const [Loading, setLoading] = useState(true);
  const [programBatches, setProgramBatches] = useState([]);
  const [status, setStatus] = useState("");
  const [checked, setChecked] = useState(true);

  const onChangeChecked = (batch) => {
    if (checked === true) {
      console.log("true", checked);
      setChecked(false);
    } else {
      console.log("false", checked);
      setChecked(true);
    }

    updateProgramBatch(batch);
  };

  const updateProgramBatch = async (batch) => {
    // e.preventDefault();
    console.log("item", batch);
    console.log("checked", checked);
    const obj = {
      docId: batch?.docId,
      program: batch?.program,
      mode: batch?.mode,
      numberOfSlots: batch?.numberOfSlots,
      numberOccupied: batch?.numberOccupied,
      packageId: batch?.packageId,
      startDate: batch?.startDate,
      isActive: checked,
      studioLocation: batch?.studioLocation,
      availability: batch?.availability,
      availableAt: batch?.availableAt,
      createdAt: batch?.createdAt,
    };
    await fetch(
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/createUpdate/programBatches",
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/createUpdate/programBatches",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        getProgramBatches();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getProgramBatches = () => {
    axios
      .get(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/programBatchesForAdmin`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/programBatchesForAdmin`
      )
      .then((res) => {
        console.log("batches", res.data.response);
        setStatus(res.data.status);
        setProgramBatches(res.data.response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getProgramBatches();
  }, []);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div className="ay__programBatchesDisplay ">
      <div className="ay__programBatchesDisplay-header">
        <span>Program Batches</span>
        <Link to={"/programBatches"}>
          <button className="addQuickRead">Create Batches</button>
        </Link>
      </div>

      <div className="table-container">
        <table className="table">
          <thead className="table-header">
            <tr>
              <th className="table-cell">Program Name</th>
              <th className="table-cell">Mode</th>
              <th className="table-cell">Start Date</th>
              {/* <th className="table-cell">End Date</th> */}
              <th className="table-cell">Available At</th>
              <th className="table-cell">Package Id</th>
              {/* <th className="table-cell">Number of Slots</th>
              <th className="table-cell">Number Occupied</th> */}
              <th className="table-cell">Action</th>
              <th className="table-cell">Edit</th>
            </tr>
          </thead>
          <tbody>
            {programBatches.map((batch, index) => (
              <tr
                className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
                key={index}
                style={{ height: "50px" }}
              >
                <td className="table-cell">{batch?.program}</td>
                <td className="table-cell">{batch?.mode}</td>
                <td className="table-cell">{batch?.startDate}</td>
                {/* <td className="table-cell">{batch?.startDate}</td> */}
                <td className="table-cell">
                  {batch?.availableAt ? batch?.availableAt : "-"}
                </td>
                <td className="table-cell">{batch?.packageId}</td>
                {/* <td className="table-cell">
                  {batch?.numberOfSlots ? batch?.numberOfSlots : "-"}
                </td>
                <td className="table-cell">
                  {batch?.numberOccupied ? batch?.numberOccupied : "-"}
                </td> */}
                <td className="table-cell">
                  <div className="quickReadsSwitch batchesSwitchs">
                    <label class="switch">
                      <div
                        className="checkbox_container"
                        onChange={() => {
                          onChangeChecked(batch);
                        }}
                      >
                        <input type="checkbox" />
                        <span
                          class="slider round"
                          style={
                            batch?.isActive === true
                              ? { backgroundColor: "green" }
                              : { backgroundColor: "red" }
                          }
                        ></span>
                        <div
                          className="round_circle"
                          style={
                            batch?.isActive === true
                              ? { right: "4px", transition: "2s ease-in" }
                              : { left: "4px", transition: "2s ease-in" }
                          }
                        ></div>
                      </div>
                    </label>
                    <span style={{ color: "black" }}>
                      {batch?.isActive ? "is Active" : "not Active"}
                    </span>
                  </div>
                </td>

                <td>
                  <Link to={`/updateQuickRead/${batch.id}`}>
                    <EditIcon style={{ cursor: "pointer", color: "black" }} />
                  </Link>
                </td>
                {/* <td className="table-cell">{index + 1}</td> */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* <div className="ay__programBatchesDisplay-containerCard">
        {status === "success" &&
          programBatches.map((batch) => {
            return (
              <div className="ay__programBatchesCard">
                <div className="ay__programBatchesCard-img">
                  <img
                    alt="img"
                    src={
                      batch.program === "Rapid"
                        ? "/img/rapidProgram.png"
                        : "/img/superFastProgram.png"
                    }
                  />
                </div>

                <div className="ay__programBatchesCard-content">
                  <div>
                    <h3>{batch?.program}</h3>
                  </div>
                  <div>
                    <h6>mode: {batch?.mode}</h6>
                  </div>
                  <div>
                    <h6>Available At: {batch?.availableAt}</h6>
                  </div>
                  <div>
                    <h6>Start Date: {batch?.startDate}</h6>
                  </div>
                </div>

                <div className="quickReadsSwitch batchesSwitch">
                  <label class="switch">
                    <div
                      className="checkbox_container"
                      onChange={() => {
                        onChangeChecked(batch);
                      }}
                    >
                      <input type="checkbox" />
                      <span
                        class="slider round"
                        style={
                          batch?.isActive === true
                            ? { backgroundColor: "green" }
                            : { backgroundColor: "red" }
                        }
                      ></span>
                      <div
                        className="round_circle"
                        style={
                          batch?.isActive === true
                            ? { right: "4px", transition: "2s ease-in" }
                            : { left: "4px", transition: "2s ease-in" }
                        }
                      ></div>
                    </div>
                  </label>
                  <span style={{ color: "black" }}>
                    {true ? "is Active" : "not Active"}
                  </span>
                </div>
              </div>
            );
          })}
      </div> */}
    </div>
  );
};

export default ProgramBatchesDisplay;
