import React, { useState, useEffect } from "react";
import "./dailyClass.css";
import axios from "axios";

const DailyClass = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          // Replace with your actual API endpoint
          "https://qrs.btartisticyoga.com/magicFood/get_image/"
        );

        if (!response.ok) {
          throw new Error("Error fetching the image");
        }

        const blob = await response.blob();
        const imageUrl = URL.createObjectURL(blob);
        setImageSrc(imageUrl);
      } catch (error) {
        console.error(error);
      }
    };

    fetchImage();
  }, []);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    setSelectedImage(file);
  };
  // console.log("selectedImage", selectedImage);

  const handleSubmit = async () => {
    var formData = new FormData();
    if (selectedImage !== undefined) {
      formData.append("images", selectedImage);
    }
    // console.log("selectedImage.length", selectedImage);
    // for (let i = 0; i < selectedImage.length; i++) {
    //   formData.append("images", selectedImage[i]);
    // }

    console.log("image");
    console.log("formData", formData);
    await axios
      .post(
        `https://qrs.btartisticyoga.com/magicFood/uploadDailyClassImageToAWS/`,
        // `http://127.0.0.1:8000/magicFood/uploadDailyClassImageToAWS/`,
        // `http://13.233.84.192:9007/magicFood/subscriptionNewletter/`,
        formData
      )
      .then((res) => {
        console.log("res", res);
        // setCoverImage(res.data.urls[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "60vh",
      }}
    >
      <div className="image-upload-container">
        <h4>Change Daily class Image</h4>
        {imageSrc && (
          <img src={imageSrc} className="uploaded-image" alt="AWS Image" />
        )}
        <label htmlFor="fileInput" className="file-label">
          Choose an image
        </label>
        <input
          type="file"
          accept="image/*"
          id="fileInput"
          className="file-input"
          onChange={handleImageChange}
        />

        {selectedImage && (
          <>
            <h4 className="dailyClass_h4">
              <span>New Image Preview</span>
            </h4>
            <img
              src={URL.createObjectURL(selectedImage)}
              alt="Uploaded"
              className="uploaded-image"
            />
          </>
        )}
        <button className="btn" onClick={handleSubmit} type="submit">
          Submit
        </button>
      </div>
    </div>
  );
};

export default DailyClass;
