import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

const CreateUpdatePackage = (props) => {
  const [packageId, setPackageId] = useState(null);
  // const [props.packageDetails, props.setPackageDetails] = useState(null);
  const [OldpackageDetails, setOldPackageDetails] = useState(null);

  const getPakageDetails = (id, type) => {
    axios
      .get(`https://artisticyogaikriya.com/rest/packages/app/${id}/detail.html`)
      .then((res) => {
        if (type === "create") {
          // console.log("res", res.data.data);
          props.setPackageDetails(res.data.data);
        } else {
          setOldPackageDetails(res.data.data);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  // props.oldPackageId
  useEffect(() => {
    if (props.activeType === "update") {
      getPakageDetails(props.oldPackageId, "update");
    }
  }, [props.oldPackageId]);

  const clearData = () => {
    setPackageId(null);
    props.setPackageDetails(null);
    setOldPackageDetails(null);
    props.onHide(false);
    props.setNewPackageId("");
  };

  console.log("packageDetailspackageDetails", props.packageDetails);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      }}
      onHide={clearData}
    >
      <Modal.Header
        style={{
          border: "none",
        }}
        closeButton
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          style={{
            textAlign: "center",
            width: "100%",
          }}
        >
          {props.activeType === "create"
            ? "Create Package"
            : props.activeType === "update"
            ? "Update Package"
            : "Delete Package"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.activeType === "delete" && (
          <>
            <div>
              Package Id: <span>{props.oldPackageId}</span>
            </div>
            <p>Are you sure you want to delete this package</p>
          </>
        )}

        {props.activeType === "create" && (
          <>
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ marginBottom: "8px" }}>
                Program Name: <span>{props.name}</span>
              </div>
              <div>
                Mode: <span>{props.activeProgramTab}</span>
              </div>
              {props.activeType === "update" && (
                <div>
                  Package Id: <span>{props.oldPackageId}</span>
                </div>
              )}
            </div>

            <div className="formContainer">
              <form>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label">
                    Package id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subtitle"
                    aria-describedby="subtitle"
                    value={props.newPackageId}
                    onChange={(e) => {
                      props.setNewPackageId(e.target.value);
                    }}
                  />
                </div>
                <Button
                  onClick={() => getPakageDetails(props.newPackageId, "create")}
                >
                  Fetch Details
                </Button>
              </form>
              {props.packageDetails && (
                <div className="paymentDetails">
                  <div className="programPaymentDetails">
                    {/* <h4>Package details</h4> */}

                    <div className="paymentDetailsContainer">
                      <div className="paymentAmount">
                        <h4>Name</h4>

                        <h4 className="name_h4">
                          {props.packageDetails?.name}
                        </h4>
                      </div>

                      <div
                        className="paymentAmount"
                        style={{ margin: "13px 0px" }}
                      >
                        <h4>Price</h4>

                        <h4>{props.packageDetails?.price}</h4>
                      </div>

                      <div className="paymentAmount">
                        <h4>validity</h4>
                        <h4>
                          {props.packageDetails?.validity}{" "}
                          {props.packageDetails?.validityUnit}
                        </h4>
                      </div>

                      {/* <div className="paymentDetailsDivider"></div> */}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        )}

        {props.activeType === "update" && (
          <>
            <div style={{ marginBottom: "1rem" }}>
              <div style={{ marginBottom: "8px" }}>
                Program Name: <span>{props.name}</span>
              </div>
              <div>
                Mode: <span>{props.activeProgramTab}</span>
              </div>
              {props.activeType === "update" && (
                <div>
                  Package Id: <span>{props.oldPackageId}</span>
                </div>
              )}
            </div>

            <div className="formContainer_update">
              <form>
                <div className="mb-3">
                  <label htmlFor="subtitle" className="form-label">
                    Package id
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="subtitle"
                    aria-describedby="subtitle"
                    value={props.newPackageId}
                    onChange={(e) => {
                      props.setNewPackageId(e.target.value);
                    }}
                  />
                </div>
                <Button
                  onClick={() => getPakageDetails(props.newPackageId, "create")}
                >
                  Fetch Details
                </Button>
              </form>

              <div className="packages_details">
                <div className="paymentDetails">
                  <div className="programPaymentDetails">
                    {/* <h4>Package details</h4> */}

                    <div className="paymentDetailsContainer">
                      <span
                        style={{ textAlign: "center", marginBottom: "2rem" }}
                      >
                        Current Package Details
                      </span>
                      <div className="paymentAmount">
                        <h4>Package Id</h4>

                        <h4 className="name_h4">{props.oldPackageId}</h4>
                      </div>

                      <div className="paymentAmount">
                        <h4>Name</h4>

                        <h4 className="name_h4">{OldpackageDetails?.name}</h4>
                      </div>

                      <div
                        className="paymentAmount"
                        style={{ margin: "13px 0px" }}
                      >
                        <h4>Price</h4>

                        <h4>{OldpackageDetails?.price}</h4>
                      </div>

                      <div className="paymentAmount">
                        <h4>validity</h4>
                        <h4>
                          {OldpackageDetails?.validity}{" "}
                          {OldpackageDetails?.validityUnit}
                        </h4>
                      </div>

                      {/* <div className="paymentDetailsDivider"></div> */}
                    </div>
                  </div>
                </div>
                {props.packageDetails && (
                  <div className="paymentDetails">
                    <div className="programPaymentDetails">
                      {/* <h4>Package details</h4> */}

                      <div className="paymentDetailsContainer">
                        <span
                          style={{ textAlign: "center", marginBottom: "2rem" }}
                        >
                          New Package Details
                        </span>

                        <div className="paymentAmount">
                          <h4>Package Id</h4>

                          <h4 className="name_h4">{props.newPackageId}</h4>
                        </div>

                        <div className="paymentAmount">
                          <h4>Name</h4>

                          <h4 className="name_h4">
                            {props.packageDetails?.name}
                          </h4>
                        </div>

                        <div
                          className="paymentAmount"
                          style={{ margin: "13px 0px" }}
                        >
                          <h4>Price</h4>

                          <h4>{props.packageDetails?.price}</h4>
                        </div>

                        <div className="paymentAmount">
                          <h4>validity</h4>
                          <h4>
                            {props.packageDetails?.validity}{" "}
                            {props.packageDetails?.validityUnit}
                          </h4>
                        </div>

                        {/* <div className="paymentDetailsDivider"></div> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </Modal.Body>

      {props.packageDetails && props.activeType != "delete" && (
        <Modal.Footer>
          {props.activeType === "create" ? (
            <Button onClick={props.createUpdateFunction}>Submit</Button>
          ) : (
            <Button
              onClick={() => props.createUpdateFunction(props.packageDetails)}
            >
              Submit
            </Button>
          )}
        </Modal.Footer>
      )}

      {props.activeType === "delete" && (
        <Modal.Footer>
          <Button
            onClick={() => props.createUpdateFunction(props.existPackage, true)}
          >
            ok
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default CreateUpdatePackage;
