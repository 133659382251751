import React, { useState, useEffect } from "react";
import "./firebaseNotification.css";
import firebase from "../../Firebase/FirebaseConfig";
// const storage = firebase.storage();
import axios from "axios";

const storage = firebase.storage().ref();

const FirebaseNotification = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [disableNotificationButton, setDisableNotificationButton] =
    useState(false);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [url, setUrl] = useState("");

  const [location, setLocation] = useState("");
  const [userLocation, setUserLocation] = useState([]);

  const [mobileNo, setMobileNo] = useState("");
  const [usermobileNo, setUserMobileNo] = useState([]);

  const getUser = () => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/users"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/users"
      )
      .then((res) => {
        // setUserLocation(res.data);
        function getUniqueListBy(resData, key) {
          return [
            ...new Map(
              resData.map((item) => [item.users[key], item.users])
            ).values(),
          ];
        }

        const arr1 = getUniqueListBy(res.data, "city");
        const number = getUniqueListBy(res.data, "mobileNo");
        console.log("arr1,number", arr1, number);
        setUserLocation(arr1);
        setUserMobileNo(number);

        // const userArray = res.data.filter(
        //   (value, index, self) =>
        //     index ===
        //     self.findIndex(
        //       (t) => t.place === value.place && t.name === value.name
        //     )
        // );
        // setUserLocation(userArray);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getUser();
    console.log("location", location);
  }, [location]);

  const handleChange = (e) => {
    handleNotificationError();
    setDisableNotificationButton(true);
    // setForm(formData)
    if (e.target.files[0]) {
      console.log("e.target.files[0]", e.target.files[0]);

      // var fileRef = storage
      //   .ref()
      //   .child(`notification/${e.target.files[0].name}`);
      var fileRef = storage
        .child(`notification/${e.target.files[0].name}`)
        .put(e.target.files[0]);

      fileRef
        .then((snapshot) => {
          return snapshot.ref.getDownloadURL();
        })
        .then((url) => {
          console.log("Image URL:", url);
          setUrl(url);
          setDisableNotificationButton(false);
        })
        .catch((error) => {
          console.error("Error uploading image or getting URL: ", error);
        });
      // Get the download URL
      // fileRef
      //   .getDownloadURL()
      //   .then(function (url) {
      //     console.log("File URL:", url);
      //     setUrl(url);
      //     // Use the URL as needed (e.g., display the image in your app)
      //   })
      //   .catch(function (error) {
      //     console.error("Error getting URL:", error);
      //   });
    }
  };

  const sendNotificationToUser = async (e) => {
    console.log("File URL:location", url, location);
    e.preventDefault();

    if (!title || !body) {
      setErrorMessage(`Enter all details`);
      return;
    }
    setDisableNotificationButton(true);
    const response = await fetch(
      "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/sendNotificationToUser",
      // "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/sendNotificationToUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          title: title,
          body: body,
          image: url,
          location: location,
          mobileNo: mobileNo,
        }),
        redirect: "follow",
      }
    )
      // return await response.json();
      .then((response) => response.json())
      .then((result) => {
        console.log("apiData", result.data);
        setTitle("");
        setBody("");
        setMobileNo("");
        setLocation("");
        setUrl("");
        setDisableNotificationButton(false);
        alert("Notification send successfully");
        // return result.data;
      })
      .catch((error) => console.log("error", error));
  };

  const handleNotificationError = () => {
    if (errorMessage) {
      setErrorMessage("");
    }
  };
  return (
    <>
      <div className="center">
        {/* <h1>Form</h1> */}
        <form onSubmit={sendNotificationToUser}>
          <div className="txt-field">
            <input
              placeholder="title"
              type="text"
              name="title"
              value={title}
              onChange={(e) => {
                handleNotificationError();
                setTitle(e.target.value);
              }}
            ></input>
          </div>

          <div className="txt-field">
            <input
              placeholder="body"
              type="text"
              name="body"
              value={body}
              onChange={(e) => {
                handleNotificationError();
                setBody(e.target.value);
              }}
            ></input>
          </div>

          <div className="txt-field">
            <input
              type="file"
              className="form-control"
              id="file"
              aria-describedby="file"
              onChange={handleChange}
              // required
            />
          </div>

          <div className="mb-3">
            <label htmlFor="type" className="form-label">
              Number
            </label>
            <select
              class="form-select"
              aria-label="Default select example"
              value={mobileNo}
              onChange={(e) => {
                setMobileNo(e.target.value);
              }}
            >
              <option selected disabled value="">
                Select Mobile Number
              </option>
              {usermobileNo.map((item, index) => {
                return (
                  <>
                    {item.mobileNo != undefined ? (
                      <>
                        <option selected value={item.mobileNo}>
                          {`${item.mobileNo}`}
                        </option>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="type" className="form-label"></label>
            <select
              class="form-select"
              aria-label="Default select example"
              value={location}
              onChange={(e) => {
                setLocation(e.target.value);
              }}
            >
              <option selected disabled value="">
                Select location
              </option>
              {userLocation.map((item, index) => {
                return (
                  <>
                    {item.city != undefined ? (
                      <>
                        <option selected value={item.city}>
                          {`${item.city}, ${item.country}`}
                        </option>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                );
              })}
            </select>
          </div>
          <p className="Login_error">{errorMessage}</p>
          {/* 
                    <div className="txt-field">
                        <input placeholder="Address" type="text" name="addresses" value={values.addresses} onChange={handleChange}></input>
                        {errors.addresses && <span>{errors.addresses}</span>}

                    </div>

                    <div className="txt-field">
                        <input placeholder="YYYY/MM/DD" type="text" name="DOB" value={values.DOB} onChange={handleChange}></input>
                        {errors.DOB && <span>{errors.DOB}</span>}

                    </div>


                    <div>
                        <input type="radio" checked={values.gender === "Male"} value="Male" name="gender" onChange={handleChange} /> Male
                        <input type="radio" checked={values.gender === "Female"} value="Female" name="gender" onChange={handleChange} /> Female
                        <br></br>
                        {errors.gender && <span>{errors.gender}</span>}
                    </div> */}

          <input
            disabled={disableNotificationButton}
            className="submitBtn"
            type="submit"
            value="Send Notification"
          ></input>
        </form>
      </div>
    </>
  );
};

export default FirebaseNotification;
