import React, { useEffect, useState } from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import firebase from "../../Firebase/FirebaseConfig";
import axios from "axios";
// import { user } from "../../../public/img/user.png";
const auth = firebase.auth();

const Header = ({ localStorage, setLocalStorage }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [tabs, setTabs] = useState([]);

  const close = document.getElementById("close");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/adminPageTabs?tabName=headerTabs"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/adminPageTabs?tabName=headerTabs"
      )
      .then((res) => {
        setTabs(res.data[0]);
        console.log("res", res.data[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  useEffect(() => {
    document.addEventListener(
      "click",
      function (e) {
        if (close !== e.target) {
          setIsOpen(false);
        }
      },
      true
    );
  }, []);
  const navigate = useNavigate();
  const logout = () => {
    auth.signOut();
    window.localStorage.clear();
    setLocalStorage(null);

    navigate("/");
  };

  useEffect(() => {
    const value = JSON.parse(window.localStorage.getItem("myData"));
    setLocalStorage(value);
  }, []);
  return (
    <>
      {localStorage != null ? (
        <div className="gpt3__navbar">
          <div className="gpt3__navbar-logo">
            <Link to={"/home"}>
              <a className="main-nav-link">AY ADMIN</a>
            </Link>
          </div>
          <div className="gpt3-navbar_links">
            <ul className="main-nav-list">
              {localStorage && localStorage.role === "admin" && (
                <>
                  <li>
                    <Link to={"/userDetail"}>
                      <a className="main-nav-link">User Details</a>
                    </Link>
                  </li>

                  {/* <li>
                    <Link to={"/users"}>
                      <a className="main-nav-link">User Details</a>
                    </Link>
                  </li> */}

                  <li>
                    <Link to={"/notification"}>
                      <a className="main-nav-link">Send Notification</a>
                    </Link>
                  </li>
                </>
              )}

              <div class="dropdown loginProfile">
                <img
                  style={{ width: "40px", height: "auto" }}
                  src="/img/user.png"
                  alt=""
                  class={`dropdown-toggle ${isOpen ? "show" : ""}`}
                  type="button"
                  id="close"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={toggleDropdown}
                />
                <div
                  class={`dropdown-menu ${isOpen ? "show" : ""}`}
                  style={{ top: "50px", right: "10px" }}
                  aria-labelledby="dropdownMenuButton"
                >
                  {localStorage && localStorage.role === "admin" && (
                    <>
                      <Link to={"/teachers"}>
                        <a class="dropdown-item">Teachers</a>
                      </Link>
                      <Link to={"/Comments"}>
                        <a class="dropdown-item">Comments</a>
                      </Link>
                      <Link to={"/users"}>
                        <a class="dropdown-item">Users</a>
                      </Link>
                      <Link to={"/ourStudios"}>
                        <a class="dropdown-item">Our Studio</a>
                      </Link>
                    </>
                  )}
                  <a
                    class="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={logout}
                  >
                    Logout
                  </a>
                </div>
              </div>
            </ul>
          </div>

          {/* <div className="dropdown-container" onClick={toggleDropdown}>
            <button className="dropdown-button" onClick={toggleDropdown}>
              {isOpen ? "Close Dropdown" : "Open Dropdown"}
            </button>
            <img
              src="/public/img/user.png"
              alt="user"
              onClick={toggleDropdown}
            />
            <ul className={`dropdown-list ${isOpen ? "open" : ""}`}>
              <li>Option 1</li>
              <li>Option 2</li>
              <li>Option 3</li>
            </ul>
          </div> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Header;
