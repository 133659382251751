import React, { useEffect, useState } from "react";
import "../packageCard/PackageCard.css";
import axios from "axios";

const ProgramCard = ({ item }) => {
  const [expireTime, setExpireTime] = useState("");
  const [purchaseTime, setPurchaseTime] = useState("");
  const [programName, setProgramName] = useState([]);
  const [userData, setUserData] = useState("");

  const getUserDetails = () => {
    // setLoading(true);
    console.log(
      "item.packages.original_app_user_id",
      item?.packages?.original_app_user_id
    );
    axios
      .get(
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/userDetails?userId=${item?.packages?.original_app_user_id}`
      )
      .then((res) => {
        console.log("user", res?.data[0]?.users?.userName);
        setUserData(res?.data[0]?.users?.userName);
        // setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    setExpireTime(
      convertUTCToIST(
        item?.packages?.subscriptions?.artyoga_347_59_1m?.expires_date
      )
    );
    setPurchaseTime(
      convertUTCToIST(
        item?.packages?.subscriptions?.artyoga_347_59_1m?.purchase_date
      )
    );
    getUserDetails();
    setProgramName(
      item?.packages?.entitlements?.yoga_programs?.product_identifier.split("_")
    );
  }, []);

  console.log("programName", programName);
  function convertUTCToIST(utcDateString) {
    const utcDate = new Date(utcDateString);

    // Get UTC time values
    const utcYear = utcDate.getUTCFullYear();
    const utcMonth = utcDate.getUTCMonth();
    const utcDay = utcDate.getUTCDate();
    const utcHours = utcDate.getUTCHours();
    const utcMinutes = utcDate.getUTCMinutes();

    // Convert to IST
    const istDate = new Date(
      Date.UTC(utcYear, utcMonth, utcDay, utcHours, utcMinutes) +
        5.5 * 60 * 60 * 1000
    );

    // Get IST time values
    const istYear = istDate.getFullYear();
    const istMonth = istDate.getMonth() + 1;
    const istDay = istDate.getDate();
    const istHours = istDate.getHours();
    const istMinutes = istDate.getMinutes();

    // Format IST as a string (add leading zeros if needed)
    // const formattedIST = `${istYear}-${String(istMonth).padStart(
    //   2,
    //   "0"
    // )}-${String(istDay).padStart(2, "0")}T${String(istHours).padStart(
    //   2,
    //   "0"
    // )}:${String(istMinutes).padStart(2, "0")}`;
    const formattedIST = `${istYear}-${String(istMonth).padStart(
      2,
      "0"
    )}-${String(istDay)}`;

    return formattedIST;
  }
  console.log(
    "program",
    item?.packages?.entitlements?.yoga_programs?.product_identifier
  );
  return (
    <div className="ay__packageCard">
      <div className="ay__packageCard_leftSide">
        {/* <h5>Program : {programName[2]}</h5> */}
        <p>Name: {userData}</p>
        <p>{/* Program Id: {programName[1]} | Paid on {purchaseTime} */}</p>
        <p>
          Plan Validity: {purchaseTime} to {expireTime}
        </p>
        {/* <p>Receipt no: ON-104471 | Paid on 12 Apr 23</p>
        <p>Plan Validity: 12 Apr 23 to 19 Apr 23</p> */}
      </div>
      <div className="ay__packageCard_RightSide">
        {/* <p>{programName[3]} AED</p> */}
      </div>
    </div>
  );
};

export default ProgramCard;
