import React, { useEffect, useState } from "react";
import axios from "axios";
import "./eventSubscription.css";
import EventSubscriptionCard from "./EventSubscriptionCard";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import InfiniteScroll from "react-infinite-scroll-component";
// import Icon from "@mdi/react";

const EventSubscription = () => {
  const [subscriptionEvent, setSubscriptionEvent] = useState([]);
  const [Loading, setLoading] = useState(true);
  const [noMore, setnoMore] = useState(true);
  const [page, setPage] = useState(2);

  const [type, setType] = useState("INITIAL_PURCHASE");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const today = new Date().toISOString().split("T")[0];

  const getAllEventDetails = () => {
    console.log("startDate", startDate, endDate);
    setLoading(true);

    const startDateMs = startDate ? new Date(startDate).getTime() : null;
    const endDateMs = endDate ? new Date(endDate).getTime() : null;

    axios
      .get(
        // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/eventDataTry?type=${type}&page_number=1&page_size=9${
        //   startDateMs ? `&start_date=${startDateMs}` : ""
        // }${endDateMs ? `&end_date=${endDateMs}` : ""}`
        `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/eventDataTry?type=${type}&page_number=1&page_size=9${
          startDateMs ? `&start_date=${startDateMs}` : ""
        }${endDateMs ? `&end_date=${endDateMs}` : ""}`

        // {
        //   headers: {
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify({
        //     page_number: 0,
        //     page_size: 2,
        //   }),
        // }
      )
      .then((res) => {
        const filterEvent = res?.data.data.filter(
          (e) => e.environment == "PRODUCTION"
        );
        // console.log("a", a);
        // console.log("SubscriptionEvent", res?.data.data);
        setSubscriptionEvent(filterEvent);
        if (res?.data.data.length === 0) {
          setnoMore(false);
        }

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllEventDetails();
    console.log("noMore", noMore);
    console.log("page", page);
  }, [type, endDate]);

  const moreSubscriptionData = async () => {
    const startDateMs = startDate ? new Date(startDate).getTime() : null;
    const endDateMs = endDate ? new Date(endDate).getTime() : null;

    const res = await fetch(
      // `http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/eventDataTry?type=${type}&page_number=${page}&page_size=9${
      `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/eventDataTry?type=${type}&page_number=${page}&page_size=9${
        startDateMs ? `&start_date=${startDateMs}` : ""
      }${endDateMs ? `&end_date=${endDateMs}` : ""}`,
      // `https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/evenDataTry?type=${type}&page_number=${page}&page_size=9`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify({
        //   page_number: page,
        //   page_size: 2,
        // }),
        redirect: "follow",
      }
    );
    const response = await res.json();
    console.log("response?.data;", response?.data);
    const filterEvent = response?.data.filter(
      (e) => e.environment == "PRODUCTION"
    );
    // return response?.data;
    return filterEvent;
  };

  const fetchMoreData = async () => {
    const paginationData = await moreSubscriptionData();
    console.log("MoreSubscriptionEvent", paginationData);
    setSubscriptionEvent([...subscriptionEvent, ...paginationData]);

    if (paginationData.length === 0 || paginationData.length < 9) {
      setnoMore(false);
    }
    setPage(page + 1);
  };

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }
  return (
    <>
      <InfiniteScroll
        dataLength={subscriptionEvent.length}
        next={fetchMoreData}
        hasMore={noMore}
        loader={
          <Loader
            type="TailSpin"
            className="searchLoader"
            color="#FE5266"
            height={30}
            width={30}
            style={{ display: "flex", justifyContent: "center" }}
          />
        }
        endMessage={
          <p
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            style={{
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <b>Scroll to top</b>
            {/* <Icon path={mdiArrowUpBoldBox} size={1} color="grey" /> */}
          </p>
        }
      >
        <div className="eventSubscription">
          <div className="eventSubscription_header breadcrumbcontainer">
            <div className="breadcrumb-nav1">
              <span> Subscription History</span>
            </div>

            <div className="mb-3">
              <select
                class="form-select"
                aria-label="Default select example"
                value={type}
                onChange={(e) => {
                  //   handleNotificationError();
                  setType(e.target.value);
                  setnoMore(true);
                  setPage(2);
                }}
              >
                <option selected value="" disabled>
                  Open this select Category
                </option>
                <option value="CANCELLATION">Cancellation</option>
                <option value="EXPIRATION">Expiration</option>
                <option value="INITIAL_PURCHASE">Initial Purchase</option>
                <option value="PRODUCT_CHANGE">Product Change</option>
                <option value="RENEWAL">Renewal</option>
                <option value="TRANSFER">Transfer</option>
                <option value="NON_RENEWING_PURCHASE">
                  Non Renewing Purchase
                </option>
              </select>
            </div>
          </div>

          <div className="eventSubscription_dateRange">
            <div className="mb-3">
              <label htmlFor="startDate" className="form-label">
                Start Date
              </label>
              <input
                type="date"
                className="form-control"
                id="startDate"
                aria-describedby="startDate"
                value={startDate}
                onChange={(e) => {
                  // handleNotificationError();
                  setStartDate(e.target.value);
                }}
                required
              />
            </div>

            <div className="mb-3">
              <label htmlFor="endDate" className="form-label">
                End Date
              </label>
              <input
                type="date"
                className="form-control"
                id="endDate"
                aria-describedby="endDate"
                value={endDate}
                onChange={(e) => {
                  // handleNotificationError();
                  setEndDate(e.target.value);
                  // getAllEventDetails();
                }}
                required
                max={today}
              />
            </div>
          </div>

          {subscriptionEvent.map((event) => {
            return <EventSubscriptionCard event={event} />;
          })}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default EventSubscription;
