import React, { useState, useEffect, useRef, useMemo } from "react";
import axios from "axios";
// import "./quickRead.css";
// import JoditEditor from "jodit-react";
import { useParams, useNavigate } from "react-router-dom";
// import "font-awesome/css/font-awesome.min.css";
import QuickReadPreview from "../popup/quickReadPreview/QuickReadPreview";

import RichTextEditor from "react-rte";

const UpdateQuickRead = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const editor = useRef(null);
  const [name, setName] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [category, setCategory] = useState("");
  const [disableLoginButton, setDisableLoginButton] = useState(false);
  // const [desc, setDesc] = useState("");
  const [contentDesc, setContentDesc] = useState("");
  const [images, setImages] = useState(null);
  const [coverimage, setCoverImage] = useState(null);

  const [oldQuickRead, setOldQuickRead] = useState([]);
  // const [images1, setImages1] = useState(null);

  const [previewModel, setPreviewModel] = useState(false);

  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );

  const handleChange = (value) => {
    // console.log("value", value);
    setEditorValue(value);
  };

  const handlePreviewClick = (e) => {
    e.preventDefault();
    setPreviewModel(true);
  };

  const config = useMemo(
    () => ({
      readonly: false,
      buttons: [
        "bold",
        "ol",
        "ul",
        "italic",
        "underline",
        "link",
        "unlink",
        "source",
      ],
    }),
    []
  );

  useEffect(() => {
    axios
      .get(
        `https://qrs.btartisticyoga.com/magicFood/getQuickReadDetails?Id=${id}`
        // `http://127.0.0.1:8000/magicFood/getQuickReadDetails?Id=${id}`
      )
      .then((res) => {
        console.log("oldData", res.data.QuickReads);
        setOldQuickRead(res.data.QuickReads);
        setName(res.data.QuickReads.name);
        setSubtitle(res.data.QuickReads.subTitle);
        setCategory(res.data.QuickReads.category);
        // setCoverImage(res.data.QuickReads.thumbnail);
        // handleChange(res.data.QuickReads.desc.toString("html"));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // const uploadImage = async ({ target }) => {
  //   const file = target.files;
  //   console.log("file", file);

  //   var formData = new FormData();
  //   for (let i = 0; i < file.length; i++) {
  //     formData.append("images", file[i]);
  //   }
  //   // console.log("formData", formData);
  //   await axios
  //     .post(
  //       // `http://127.0.0.1:8000/magicFood/changeImageResolution/`,
  //       `https://qrs.btartisticyoga.com/magicFood/changeImageResolution/`,
  //       formData
  //     )
  //     .then((res) => {
  //       console.log("res", res);
  //       setImages(res.data.urlData);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // const uploadImage = async ({ target }) => {
  //   const files = target.files;
  //   console.log("files", files);

  //   var formData = new FormData();
  //   for (let i = 0; i < files.length; i++) {
  //     const originalName = files[i].name;
  //     const modifiedName = modifyFileName(originalName);

  //     // Append the modified file with the new name to the FormData
  //     formData.append("images", new File([files[i]], modifiedName, { type: files[i].type }));
  //   }
  //       await axios
  //     .post(
  //       // `http://127.0.0.1:8000/magicFood/changeImageResolution/`,
  //       `https://qrs.btartisticyoga.com/magicFood/changeImageResolution/`,
  //       formData
  //     )
  //     .then((res) => {
  //       console.log("res", res);
  //       setImages(res.data.urlData);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });

  //   // Rest of your code to handle the formData as needed
  // };

  //   const uploadImage = async ({ target }) => {
  //     const files = target.files;
  //     console.log("files", files);

  //     const formData = new FormData();

  //     for (let i = 0; i < files.length; i++) {
  //         const originalName = files[i].name;
  //         const modifiedName = `Slide ${originalName}`;
  //         formData.append("images", new File([files[i]], modifiedName));
  //     }

  //     try {
  //         const response = await axios.post(
  //             "https://qrs.btartisticyoga.com/magicFood/changeImageResolution/",
  //             formData
  //         );

  //         console.log("response", response);

  //         // Restructuring data in the desired format
  //         const imageAssets = Object.entries(response.data.images).map(([name, urls]) => {
  //             const asset = {};
  //             asset[name] = urls.map((url, index) => `${url.replace(/-[0-9]+-/g, `-e-${index * 299}-`)}`);
  //             return asset;
  //         });

  //         console.log("imageAssets", imageAssets);
  //         setImages(imageAssets);
  //     } catch (error) {
  //         console.error(error);
  //     }
  // };
  const uploadImage = async ({ target }) => {
    setDisableLoginButton(true);
    const files = target.files;
    console.log("files", files);
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      const originalName = files[i].name;
      const modifiedName = originalName.includes("Slide")
        ? originalName
        : `Slide ${originalName}`;
      formData.append("images", new File([files[i]], modifiedName));
    }

    try {
      const response = await axios.post(
        "https://qrs.btartisticyoga.com/magicFood/changeImageResolution/",
        formData
      );

      console.log("response", response);
      setImages(response.data.urlData);
      setDisableLoginButton(false);
    } catch (error) {
      console.error(error);
    }
  };

  function modifyFileName(originalName) {
    const fileExtension = originalName.split(".").pop();
    const fileNameWithoutExtension = originalName.replace(
      `.${fileExtension}`,
      ""
    );
    const modifiedFileName = `Slide ${fileNameWithoutExtension}.${fileExtension}`;
    return modifiedFileName;
  }

  const uploadCoverImage = async ({ target }) => {
    const file = target.files;
    // const file = target.files[0];
    console.log("fileData111", file);

    var formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append("images", file[i]);
    }
    await axios
      .post(
        // `http://127.0.0.1:8000/magicFood/uploadImageToAWS/`,
        `https://qrs.btartisticyoga.com/magicFood/changeImageResolution/`,
        formData
      )
      .then((res) => {
        console.log("res", res.data.urls[0]);
        setCoverImage(res.data.urls[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const UpdateNewMagicFood = async (e) => {
    e.preventDefault();
    console.log("images", images);

    const obj = {
      quickReadId: id,
      name: name !== "" ? name : oldQuickRead.name,
      desc:
        editorValue.toString("html") !== "<p><br></p>"
          ? editorValue.toString("html")
          : oldQuickRead.desc,
      subtitle: subtitle !== "" ? subtitle : oldQuickRead.subTitle,
      imageAssets: images !== null ? images : oldQuickRead.imageAssets,
      category: category !== "" ? category : oldQuickRead.category,
      thumbnail: coverimage !== null ? coverimage : oldQuickRead.thumbnail,
    };
    console.log("obj", obj);
    await fetch(
      // "http://127.0.0.1:8000/magicFood/updateQuickReads/",
      "https://qrs.btartisticyoga.com/magicFood/updateQuickReads/",
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result2121", result);
        alert("updated quickRead successfully");
        navigate("/home");
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div className="Apps">
        <div className="breadcrumbcontainer">
          <div className="breadcrumb-nav">
            <img src="/assets/rightchevron.png" alt="" />
            <span>Update Quick Read</span>
          </div>
        </div>

        <form className="quickread_from" onSubmit={UpdateNewMagicFood}>
          <div className="mb-3">
            <label htmlFor="Name" className="form-label">
              Name
            </label>
            <input
              type="text"
              className="form-control"
              id="name"
              aria-describedby="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="subtitle" className="form-label">
              Subtitle
            </label>
            <input
              type="text"
              className="form-control"
              id="subtitle"
              aria-describedby="subtitle"
              value={subtitle}
              onChange={(e) => {
                setSubtitle(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="category" className="form-label">
              Category
            </label>
            <input
              type="text"
              className="form-control"
              id="category"
              aria-describedby="category"
              value={category}
              onChange={(e) => {
                setCategory(e.target.value);
              }}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="coverImage" className="form-label">
              Thumbnail Image
            </label>
            <input
              type="file"
              className="form-control"
              id="coverImage"
              aria-describedby="name"
              onChange={uploadCoverImage}
            />
            {/* <img src={coverimage} /> */}
          </div>

          <div className="mb-3">
            <label htmlFor="files" className="form-label">
              Images
            </label>
            <input
              type="file"
              className="form-control"
              id="files"
              aria-describedby="name"
              onChange={uploadImage}
              multiple
            />
          </div>

          <div className="mb-3">
            <label htmlFor="desc" className="form-label">
              Description
            </label>

            {/* {editorValue.toString("html") !== "<p><br></p>" ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: editorValue.toString("html"),
                }}
              />
            ) : (
              <div dangerouslySetInnerHTML={{ __html: oldQuickRead.desc }} />
            )} */}
            <RichTextEditor
              value={
                editorValue.toString("html") !== "<p><br></p>"
                  ? editorValue
                  : RichTextEditor.createValueFromString(
                      oldQuickRead.desc,
                      "html"
                    )
              }
              onChange={handleChange}
            />
          </div>

          <button
            className="quickRead__previewButton"
            onClick={handlePreviewClick}
          >
            Preview
          </button>

          <input
            disabled={disableLoginButton}
            className="submitBtn"
            type="submit"
            value="Submit"
          ></input>
        </form>

        <QuickReadPreview
          show={previewModel}
          onHide={() => setPreviewModel(false)}
          desc={
            editorValue.toString("html") !== "<p><br></p>"
              ? editorValue.toString("html")
              : oldQuickRead.desc
          }
          images={images != null ? images : oldQuickRead.imageAssets}
          title={name != "" ? name : oldQuickRead.name}
          // name={program.name}
          // setPricingModal={setPricingModal}
        />
      </div>
    </>
  );
};

export default UpdateQuickRead;
