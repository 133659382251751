import React, { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "react-loader-spinner";
import UserConvert from "../popup/userConvert/UserConvert";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Users = () => {
  const [Loading, setLoading] = useState(true);
  const [type, setType] = useState("");
  const [updateUserId, setUpdateUserId] = useState("");
  const [updateName, setUpdateName] = useState("");
  const [qnaSearch, setQnaSearch] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [users, setUsers] = useState([]);
  const [teachers, setTeachers] = useState([]);

  const [noMore, setnoMore] = useState(true);
  const [page, setPage] = useState(2);

  const [openDropdownUserId, setOpenDropdownUserId] = useState(null);

  const toggleDropdownForRow = (userId) => {
    if (openDropdownUserId === userId) {
      setOpenDropdownUserId(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdownUserId(userId); // Open the clicked dropdown
    }
  };

  const setModalShowButton = (type, userId, name) => {
    setModalShow(true);
    setModalType(type);
    setUpdateUserId(userId);
    setUpdateName(name);
  };

  const updateTeachers = async (userId, name, type) => {
    const obj = {
      userId,
      name,
      type,
    };
    await fetch(
      // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/createUpdate/teachers",
      "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/createUpdate/teachers",

      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(obj),
        redirect: "follow",
      }
    )
      .then((response) => response.json())
      .then((result) => {
        console.log("result", result);
        setModalShow(false);
        usersList();
        getTeachers();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getTeachers = () => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/teachers"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/teachers"
      )
      .then((res) => {
        setTeachers(res.data[0].data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const usersList = () => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/users"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/users"
      )
      .then((res) => {
        setUsers(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  useEffect(() => {
    usersList();
    getTeachers();
  }, [type]);

  const filteredUsers = users.filter((item) => {
    console.log("item", item.users);
    if (qnaSearch === "") {
      return true; // Return true to keep all users if search query is empty
    } else {
      // Otherwise, apply the filtering logic
      return (
        (item?.users?.userName &&
          item?.users?.userName.toLowerCase().indexOf(qnaSearch.toLowerCase()) >
            -1) ||
        (item?.users?.city &&
          item?.users?.city.toLowerCase().indexOf(qnaSearch.toLowerCase()) >
            -1) ||
        (item?.users?.mobileNo &&
          item?.users?.mobileNo.toLowerCase().indexOf(qnaSearch.toLowerCase()) >
            -1)
      );
    }
  });

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div className="ay__programBatchesDisplay ">
      <div className="ay__programBatchesDisplay-header">
        <span>Users</span>
        {/* <Link to={"/programBatches"}>
          <button className="addQuickRead">Create Batches</button>
        </Link> */}
      </div>

      <div className="search-bar" style={{ marginBottom: "2rem" }}>
        <input
          type="text"
          placeholder="Search what you are looking for"
          className="search-input"
          value={qnaSearch}
          onChange={(e) => setQnaSearch(e.target.value)}
        />
        {/* <button className="search-button">
            <img
              src="/img/search.png"
              alt="Search Icon"
              className="search-icon"
            />
          </button> */}
      </div>

      <div className="table-container">
        {filteredUsers.length === 0 ? (
          <p style={{ textAlign: "center" }}>No users found.</p>
        ) : (
          <table className="table">
            <thead className="table-header">
              <tr>
                <th className="table-cell">User Name</th>
                <th className="table-cell">User Id</th>
                <th className="table-cell">City</th>
                <th className="table-cell">Country</th>
                <th className="table-cell">Phone Number</th>
                <th className="table-cell">Email</th>
                <th className="table-cell">App Version</th>
                <th className="table-cell">Type</th>
              </tr>
            </thead>
            <tbody>
              {/* .filter((item) => {
                  console.log("item", item.users);
                  if (qnaSearch === "") {
                    return item;
                  } else if (
                    (item?.users?.userName &&
                      item?.users?.userName
                        .toLowerCase()
                        .indexOf(qnaSearch.toLowerCase()) > -1) ||
                    (item?.users?.city &&
                      item?.users?.city
                        .toLowerCase()
                        .indexOf(qnaSearch.toLowerCase()) > -1) ||
                    (item?.users?.mobileNo &&
                      item?.users?.mobileNo
                        .toLowerCase()
                        .indexOf(qnaSearch.toLowerCase()) > -1)
                  ) {
                    return item;
                  }
                }) */}
              {filteredUsers.map((item, index) => (
                <tr
                  className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
                  key={index}
                  style={{ height: "50px" }}
                >
                  <td className="table-cell">{item?.users?.userName}</td>
                  <td className="table-cell">{item?.users?.userId}</td>
                  <td className="table-cell">
                    {item?.users?.city ? item?.users?.city : "-"}
                  </td>
                  <td className="table-cell">
                    {item?.users?.country ? item?.users?.country : "-"}
                  </td>
                  <td className="table-cell">
                    {item?.users?.mobileNo ? item?.users?.mobileNo : "-"}
                  </td>

                  <td className="table-cell">
                    {item?.users?.email ? item?.users?.email : "-"}
                  </td>

                  <td className="table-cell">
                    {item?.users?.appVersion ? item?.users?.appVersion : "-"}
                  </td>

                  <td
                    className="table-cell"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      border: "none",
                    }}
                  >
                    <div>
                      {teachers.some(
                        (teacher) =>
                          teacher.userId === item?.users?.userId &&
                          teacher.isActive === true
                      )
                        ? "Teacher"
                        : "User"}
                    </div>
                    <div class="dropdown loginProfile">
                      <img
                        style={{ width: "18px", height: "auto" }}
                        src="/img/pencil.png"
                        alt=""
                        class={`dropdown-toggle ${
                          openDropdownUserId === item?.users?.userId
                            ? "show"
                            : ""
                        }`}
                        type="button"
                        id={`close-${item?.users?.userId}`}
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        onClick={() =>
                          toggleDropdownForRow(item?.users?.userId)
                        }
                      />

                      <div
                        class={`dropdown-menu ${
                          openDropdownUserId === item?.users?.userId
                            ? "show"
                            : ""
                        }`}
                        style={{ top: "50px", right: "10px" }}
                        aria-labelledby={`dropdownMenuButton-${item?.users?.userId}`}
                      >
                        <button
                          class="dropdown-item"
                          onClick={() => {
                            setType("Teacher");
                            setModalShowButton(
                              "Teacher",
                              item?.users?.userId,
                              item?.users?.userName
                            );
                          }}
                        >
                          Teacher
                        </button>

                        <button
                          class="dropdown-item"
                          onClick={() => {
                            setType("User");
                            setModalShowButton(
                              "User",
                              item?.users?.userId,
                              item?.users?.userName
                            );
                          }}
                        >
                          User
                        </button>
                      </div>
                    </div>
                    {/* <select
                    style={{ width: "100%", border: "none" }}
                    value={
                      teachers.some(
                        (teacher) =>
                          teacher.userId === item?.users?.userId &&
                          teacher.isActive === true
                      )
                        ? "Teacher"
                        : "User"
                    }
                    onChange={(e) => {
                      setType(e.target.value);
                      setModalShowButton(
                        e.target.value,
                        item?.users?.userId,
                        item?.users?.userName
                      );
                    }}
                  >
                    <option value="Teacher">Teacher</option>
                    <option value="User">User</option>
                  </select> */}
                  </td>

                  {/* <td className="table-cell">
                  <div className="quickReadsSwitch batchesSwitchs">
                    <label class="switch">
                      <div
                        className="checkbox_container"
                        onChange={() => {
                          onChangeChecked(batch);
                        }}
                      >
                        <input type="checkbox" />
                        <span
                          class="slider round"
                          style={
                            user?.isActive === true
                              ? { backgroundColor: "green" }
                              : { backgroundColor: "red" }
                          }
                        ></span>
                        <div
                          className="round_circle"
                          style={
                            user?.isActive === true
                              ? { right: "4px", transition: "2s ease-in" }
                              : { left: "4px", transition: "2s ease-in" }
                          }
                        ></div>
                      </div>
                    </label>
                    <span style={{ color: "black" }}>
                      {user?.isActive ? "is Active" : "not Active"}
                    </span>
                  </div>
                </td> */}

                  {/* <td>
                  <Link to={`/updateQuickRead/${batch.id}`}>
                    <EditIcon style={{ cursor: "pointer", color: "black" }} />
                  </Link>
                </td> */}
                  {/* <td className="table-cell">{index + 1}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <UserConvert
        actionType={modalType}
        show={modalShow}
        onHide={() => setModalShow(false)}
        // comment={selectedComment}
        updateFunction={updateTeachers}
        updateParams={[updateUserId, updateName, type]}
      />
    </div>
  );
};

export default Users;
