import React, { useState, useEffect } from "react";
import QuickReadDisplay from "../quickReadDisplay/QuickReadDisplay";
import "./home.css";
import AyBannerImageDisplay from "../ayBannerImageDisplay/AyBannerImageDisplay";
import EventSubscription from "../eventSubscription/EventSubscription";
import QnaDisplay from "../qnaVideoBlogDisplay/qnaDisplay/QnaDisplay";
import BasicProgramDetails from "../updateProgram/basicProgramDetails/BasicProgramDetails";
import ProgramBatchesDisplay from "../programBatches/programBatchesDisplay/ProgramBatchesDisplay";
import AppContent from "../appContent/AppContent";
import axios from "axios";

const Home = ({ localStorage }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [tabs, setTabs] = useState([]);
  console.log("localStorageInHome", localStorage);
  // console.log("localStorageRole", localStorage);

  useEffect(() => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/adminPageTabs?tabName=mainPageTabs"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/adminPageTabs?tabName=mainPageTabs"
      )
      .then((res) => {
        setTabs(res.data[0]);
        console.log("res", res.data[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const filteredTabs = tabs.filter((tab) => {
    const userAccess = localStorage?.role;
    return tab.access.includes(userAccess);
  });

  return (
    <div>
      <div className="tabs-container">
        {filteredTabs.map((item, index) => {
          return (
            <button
              key={index}
              className={
                activeTab === index + 1 ? "activeBtn tabBtn" : "tabBtn"
              }
              onClick={() => setActiveTab(index + 1)}
            >
              {item.name}
            </button>
          );
        })}
        {/* <button
          className={activeTab === 1 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(1)}
        >
          Quick Reads
        </button>
        <button
          className={activeTab === 2 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(2)}
        >
          Banner Images
        </button>

        <button
          className={activeTab === 3 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(3)}
        >
          Subscription History
        </button>

        <button
          className={activeTab === 4 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(4)}
        >
          QNA
        </button>
        <button
          className={activeTab === 5 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(5)}
        >
          Program Batches
        </button>
        <button
          className={activeTab === 6 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(6)}
        >
          Programs
        </button>
        <button
          className={activeTab === 7 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(7)}
        >
          App Content
        </button> */}
      </div>

      {activeTab === 1 ? (
        <QuickReadDisplay />
      ) : activeTab === 2 ? (
        <AyBannerImageDisplay />
      ) : activeTab === 3 ? (
        <EventSubscription />
      ) : activeTab === 4 ? (
        <QnaDisplay />
      ) : activeTab === 5 ? (
        <ProgramBatchesDisplay />
      ) : activeTab === 6 ? (
        <BasicProgramDetails />
      ) : activeTab === 7 ? (
        <AppContent />
      ) : (
        ""
      )}
    </div>
  );
};

export default Home;
