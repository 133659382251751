import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const QuickReadEdit = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      //   backdrop
      //   style={{
      //     backgroundColor: "rgba(0, 0, 0, 0.5)",
      //   }}
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Display Rank
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="mb-3">
            <label htmlFor="subtitle" className="form-label">
              Display Rank No.
            </label>
            <input
              type="text"
              className="form-control"
              id="subtitle"
              aria-describedby="subtitle"
              //   value={props.updatePackageId}
              onChange={(e) => {
                props.setDisplayRank(e.target.value);
              }}
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => props.updatefunction(...props.updateParams)}>
          Ok
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default QuickReadEdit;
