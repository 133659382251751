const programData = [
  {
    id: 1,
    name: "Red",
    image_url: "/img/redProgram.png",
  },
  {
    id: 2,
    name: "Rapid",
    image_url: "/img/rapidProgram.png",
  },
  {
    id: 3,
    name: "SuperFast",
    image_url: "/img/superFastProgram.png",
  },
  {
    id: 4,
    name: "AyCalm",
    image_url: "/img/AyCalm.png",
  },
  {
    id: 5,
    name: "AyClassic",
    image_url: "/img/AyClassic.png",
  },
];

export default programData;
