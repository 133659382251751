import React, { useState, useEffect } from "react";
import axios from "axios";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "react-loader-spinner";

const PopularClassesDisplay = () => {
  const [popularClassesList, setPopularClassesList] = useState([]);
  const [Loading, setLoading] = useState(true);

  useEffect(() => {
    axios
      .get(
        // "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/programInAppMapping/videoCategories"
        "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/programInAppMapping/videoCategories"
      )
      .then((res) => {
        console.log(res);
        setPopularClassesList(res.data.classes);
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  if (Loading) {
    return (
      <div className="fp-Container">
        <Loader type="TailSpin" color="#FE5266" height={30} width={30} />
      </div>
    );
  }

  return (
    <div className="ay__programBatchesDisplay ">
      <div className="ay__programBatchesDisplay-header">
        <span>Popular Classes</span>

        {/* <button
      onClick={() => {
        setModalShowButton("create", teacher);
      }}
      className="addQuickRead"
    >
      Create 
    </button> */}
      </div>

      <div className="table-container">
        <table className="table">
          <thead className="table-header">
            <tr>
              <th className="table-cell">Categorie Id</th>
              <th className="table-cell">Name</th>
              <th className="table-cell">Package Id</th>
              <th className="table-cell">Action</th>
              <th className="table-cell">Edit</th>
            </tr>
          </thead>
          <tbody>
            {popularClassesList.map((classes, index) => (
              <tr
                className={`table-row ${index % 2 === 0 ? "even" : "odd"}`}
                key={index}
                style={{ height: "50px" }}
              >
                <td className="table-cell">{classes?.id}</td>
                {/* <td className="table-cell">
                  <img src={classes?.context} alt="teachers" />
                </td> */}
                <td className="table-cell">{classes?.context}</td>
                <td className="table-cell">{classes?.packageId}</td>

                <td className="table-cell">
                  <div className="quickReadsSwitch batchesSwitchs">
                    <label class="switch">
                      <div
                        className="checkbox_container"
                        // onChange={() => {
                        //   onChangeChecked(teacher);
                        // }}
                      >
                        <input type="checkbox" />
                        <span
                          class="slider round"
                          style={
                            classes?.isActive === true
                              ? { backgroundColor: "green" }
                              : { backgroundColor: "red" }
                          }
                        ></span>
                        <div
                          className="round_circle"
                          style={
                            classes?.isActive === true
                              ? { right: "4px", transition: "2s ease-in" }
                              : { left: "4px", transition: "2s ease-in" }
                          }
                        ></div>
                      </div>
                    </label>
                    <span style={{ color: "black" }}>
                      {true ? "is Active" : "not Active"}
                    </span>
                  </div>
                </td>

                <td>
                  <EditIcon
                    // onClick={() => {
                    //   setModalShowButton("update", teacher);
                    // }}
                    style={{ cursor: "pointer", color: "black" }}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* {modalType === "update" && teacher && (
      <TeacherPopup
        actionType="update"
        show={modalShow}
        onHide={() => setModalShow(false)}
        // comment={selectedComment}
        updateFunction={updateTeacherNew}
        updateParams={[teacher, teacher.docId]}
      />
    )}

    {modalType === "create" && teacher && (
      <TeacherPopup
        actionType="create"
        show={modalShow}
        onHide={() => setModalShow(false)}
        // comment={selectedComment}
        updateFunction={updateTeacherNew}
        updateParams={[teacher, teacher.docId]}
      />
    )} */}
      </div>
    </div>
  );
};

export default PopularClassesDisplay;
