import React, { useState } from "react";
import QnaDisplay from "./qnaDisplay/QnaDisplay";
import BlogCommentsDisplay from "./blogCommentsDisplay/BlogCommentsDisplay";

const QnaVideoBlogDisplay = () => {
  const [activeTab, setActiveTab] = useState(1);

  return (
    <div>
      <div className="tabs-container">
        <button
          className={activeTab === 1 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(1)}
        >
          QNA
        </button>
        <button
          className={activeTab === 2 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(2)}
        >
          Quick Read Comments
        </button>
        <button
          className={activeTab === 3 ? "activeBtn tabBtn" : "tabBtn"}
          onClick={() => setActiveTab(3)}
        >
          Videos Comments
        </button>
      </div>

      {activeTab === 1 ? (
        <QnaDisplay />
      ) : activeTab === 2 ? (
        <BlogCommentsDisplay />
      ) : (
        ""
      )}
    </div>
  );
};

export default QnaVideoBlogDisplay;
