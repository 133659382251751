import React, { useState, useEffect } from "react";
import programData from "../programData";
import ProgramDetailCard from "../programDetailCard/ProgramDetailCard";
import "./basicProgramDetails.css";
import axios from "axios";
import { Link } from "react-router-dom";

const BasicProgramDetails = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [appProgramList, setAppProgramList] = useState([]);

  useEffect(() => {
    axios
      .get(
        "http://127.0.0.1:5001/btay-project-1551779459124/us-central1/AY/get/programInAppMapping/programs"
        // "https://us-central1-btay-project-1551779459124.cloudfunctions.net/AY/get/programInAppMapping/programs"
      )
      .then((res) => {
        console.log("res", res.data.programs);
        setAppProgramList(res.data.programs);
      })
      .catch((err) => {
        console.log("err");
      });
  }, []);

  return (
    <>
      <div style={{ margin: "1rem 6rem" }}>
        <div className="program-price-container2 tabs-container noMargin">
          <div
            className={
              activeTab === 1
                ? "activeProgramTab program-tab-container"
                : "program-tab-container"
            }
            onClick={() => setActiveTab(1)}
          >
            <p style={{ margin: "1rem" }}>Web Program</p>
          </div>

          <div
            className={
              activeTab === 2
                ? "activeProgramTab program-tab-container"
                : "program-tab-container"
            }
            onClick={() => setActiveTab(2)}
          >
            <p style={{ margin: "1rem" }}>App Program</p>
          </div>
        </div>

        <div className="ay__BasicProgramDetails">
          <div className="breadcrumbcontainer mb-5">
            <div className="breadcrumb-nav1" style={{ marginTop: "3rem" }}>
              <span>Program</span>

              {activeTab === 2 && (
                <Link to={"/appProgram"}>
                  <button className="addQuickRead">Create App Program</button>
                </Link>
              )}
            </div>
          </div>

          <div className="ay__BasicProgramDetails-containerCard">
            {activeTab === 1 ? (
              <>
                {programData.map((program, index) => {
                  return (
                    <ProgramDetailCard
                      key={index}
                      program={program}
                      type="web"
                    />
                  );
                })}
              </>
            ) : (
              <>
                {appProgramList.map((appProgram, index) => {
                  return (
                    <ProgramDetailCard
                      key={index}
                      program={appProgram}
                      type="app"
                    />
                  );
                })}
              </>
            )}
          </div>
        </div>

        {/* {activeTab === 1 ? (
          <div className="ay__BasicProgramDetails">
            <div
              className="ay__BasicProgramDetails-header"
              style={{ marginTop: "2rem" }}
            >
              <h3>Programs</h3>
            </div>

            <div className="ay__BasicProgramDetails-containerCard">
              {programData.map((program, index) => {
                return (
                  <ProgramDetailCard key={index} program={program} type="web" />
                );
              })}
            </div>
          </div>
        ) : activeTab === 2 ? (
          <div className="ay__BasicProgramDetails">
            <div className="breadcrumbcontainer mb-5">
              <div className="breadcrumb-nav1">
                <span>Programs</span>
                <Link to={"/appProgram"}>
                  <button className="addQuickRead">Post App Program</button>
                </Link>
              </div>
            </div>

            <div className="ay__BasicProgramDetails-containerCard">
              {appProgramList.map((appProgram, index) => {
                return (
                  <ProgramDetailCard
                    key={index}
                    program={appProgram}
                    type="app"
                  />
                );
              })}
            </div>
          </div>
        ) : (
          ""
        )} */}
      </div>
    </>
  );
};

export default BasicProgramDetails;
